function replacePlaceholders(ids, data) {
    ids.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
            if (element.innerHTML) {
                element.innerHTML = element.innerHTML.replace(/{{js-(.*?)}}/g, (_, key) => data[key]);
            }
            if (element.hasAttribute('data-src')) {
                const dataSrc = element.getAttribute('data-src');
                const newSrc = dataSrc.replace(/{{js-(.*?)}}/g, (_, key) => data[key].toUpperCase());
                element.setAttribute('src', newSrc);
                element.removeAttribute('data-src')
            }
        }
    });
}

async function fetchData() {
    try {
        let response = await fetch(`${countryCode}/shop/${analyticsJson.metadata.pageLocale}/geo/country`);

        if (!response.ok) {
            throw new Error('Network answer was not okay.');
        }

        let data = await response.json();

        const banner = document.getElementById('geoLocationBanner');
        const overlay = document.getElementById('geoLocationBannerOverlay');

        if(data.hasOwnProperty('geoRequestMatch') && !data.geoRequestMatch){

            if (window.location.href.includes('iw_IL')) {
                const elements = document.querySelectorAll('.bel-glb_button-flag');

                elements.forEach((e) => {
                    e.style.margin = '0 0 0 1rem';
                });
            }

            const stayButton = document.getElementById('stayButton');
            const switchButton = document.getElementById('switchButton');
            const closeButton = document.getElementById('closeButton');

            replacePlaceholders(['glb_headline', 'glb_text', 'glb_img_current', 'glb_img_correct', 'glb_span_current', 'glb_span_correct'], data);

            banner.style.display = 'block';
            overlay.style.display = 'block';

            // Trigger the slide-down animation
            setTimeout(() => {
                banner.classList.add('show');
                overlay.classList.add('show');
            }, 100);

            function hideBannerAndOverlay() {
                banner.classList.remove('show');
                overlay.classList.remove('show');
                overlay.style.display = 'none';
                setTimeout(() => {
                    banner.style.display = 'none';
                }, 500);
            }

            stayButton.addEventListener('click', function () {
                sessionStorage.setItem('geoLocationState', 'stayed');
                hideBannerAndOverlay();
                console.log('User decided to stay!');
            });

            switchButton.addEventListener('click', function () {
                sessionStorage.setItem('geoLocationState', 'switched');
                hideBannerAndOverlay();
                console.log('User decided to switch!');
                window.location.href = `${window.location.origin}/${data.geoCountryCode.toLowerCase()}`;
            });

            closeButton.addEventListener('click', function () {
                console.log('User closed Banner!');
                hideBannerAndOverlay();
            });
        }
        else {
            Array.from(document.getElementsByClassName('bel-geoLocationBanner')).forEach((e) => e.innerHTML = '');
        }
    } catch (error) {
        Array.from(document.getElementsByClassName('bel-geoLocationBanner')).forEach((e) => e.innerHTML = '');
        console.error('There was an error handling the geoLocation fetch request:', error);

    }
}

document.addEventListener('DOMContentLoaded', function () {
    if (!sessionStorage.getItem('geoLocationState')) {
        fetchData();
    }
});
