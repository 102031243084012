export const createSearchParams = (searchParams) => {
  const newSearchParams = new URLSearchParams()
  if (searchParams) {
    for (let param in searchParams) {
      // //dont add pagination to the query
      // if (param === 'p' || param === 'ratingPath') {
      //   continue
      // }
      if (Array.isArray(searchParams[param])) {
        //dont send back empty arrays
        if (searchParams[param].length) {
          //for array params which have at least one entry
          newSearchParams.append(param, searchParams[param].join(','))
        }
      } else if (!!searchParams[param]) {
        //for normal params
        newSearchParams.append(param, searchParams[param])
      }
    }
  }
  return newSearchParams.toString()
}
