import {createGa4FilterRemovedEvent, pushTag} from "../../script/analytics";

(function () {
    let facetNavButtons = $('.facet__list.applied-filters li button');
    for (let facetNavButton of facetNavButtons) {

        facetNavButton.addEventListener("click", () => {
            let filterValue = $(facetNavButton).find('.facet-nav.facet-value').first().text();
            let filterName = $(facetNavButton).find('.facet-nav.facet-name').first().text();
            let navUrl = $(facetNavButton).attr('data-facet-nav-url');
            let isProduct = true;
            if (navUrl.includes('downloadcenter')) {
                isProduct = false
            }
            pushTag(createGa4FilterRemovedEvent('Filter Removed', filterName + '::' + filterValue, isProduct ? 'Product Filter' : 'Document Filter'));

            if (navUrl) {
                window.location.href = navUrl;
            }
        });
    }
})
();