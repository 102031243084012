$('.orders').each(function() {
  var orders = $(this);
    var list = orders.find('.order-list');
    var more = orders.find('.order-list-more a');

  more.on('click', function(ev){
    ev.preventDefault();

    $.ajax({
        method: 'GET',
        url: more.attr('href'),
        dataType: 'html',
        data: {
          endDate: more.data('end-date')
        },
        success: function (data) {
          // end of request
          var response = $(data);
          var items = response.find('.order-item');

          items.appendTo(list);

          more.data('end-date', response.data('end-date'));
        }
    });

  });
});
