
(function() {

  var $checkoutDiv = $('.checkout-with-prompt');

  if($checkoutDiv.length > 0) {
    init();
  }

  function isValidAccountNumber(accountNumber) {

    return accountNumber &&
      accountNumber.length &&
      accountNumber.length === 6 &&
      !isNaN(Number(accountNumber));
  }

  function init() {

    var $popupTriggers = $checkoutDiv.find('.fake-button');
    var $popupWrapper = $('#popup-id-anp-checkout');

    var state = {
      language: null,
      hasValidAccountNumber: false,
      showConfirmMessage: false,
      showInvalidAccountNumberInfo: false,

      init: function() {
        this.showInvalidAccountNumberInfo = false;
      }
    };

    var ui = {

      elems: {
        $openTrigger: null,
        $okButton: null,
        $cancelButton: null,
        $accountNumberInputLabel: null,
        $accountNumberInput: null,
        $radioYes: null,
        $radioNo: null,
        $radioButtons: null,
        $mainContent: null,
        $confirmContent: null,
        $submittedInputInvalid: null,
        $confirmOkButton: null,
        $form: null,
        $formAccountNumberField: null
      },

      closePopup: function() {
        $popupWrapper.trigger('popup-close');
      },
      openPopup: function() {
        $popupWrapper.trigger('popup-open', []);
      },
      repositionPopup: function() {
        $popupWrapper.trigger('popup-reposition', []);
      },

      initHandlers: function() {
        var _this = this;



        function submit() {
          _this.closePopup();
          _this.elems.$form.submit();
        }

        function accountNumberSubmit() {

          _this.elems.$formAccountNumberField.val(_this.elems.$accountNumberInput.val());
          submit();
        }

        function noAccountNumberSubmit() {
          submit();
        }

        this.elems.$okButton.on('click', function(e) {

          if(_this.elems.$radioYes.is(':checked')) {
            accountNumberSubmit();
          }
          else {
            noAccountNumberSubmit();
          }
        });

        this.elems.$cancelButton.on('click', function() {
          _this.closePopup();
        });
        this.elems.$accountNumberInput.on('input', function() {
          state.showInvalidAccountNumberInfo = false;
          state.hasValidAccountNumber = isValidAccountNumber(_this.elems.$accountNumberInput.val());
          _this.draw();
        });

        this.elems.$accountNumberInput.on('keyup', function(e) {
          state.showInvalidAccountNumberInfo = false;
          if(e.which == 13 && state.hasValidAccountNumber && _this.elems.$radioYes.is(':checked')) {
            accountNumberSubmit();
          }
        });

        this.elems.$radioButtons.on('radio-button-click', function(e, groupId, id) {
          if(groupId !== 'anp-id-mode-selection') return;
          state.showInvalidAccountNumberInfo = false;
          _this.draw();
        });

        this.elems.$confirmOkButton.on('click', function(e) {
          _this.closePopup();
        });

      },
      init: function($openTrigger) {

        this.elems.$mainContent = $popupWrapper.find('.anp-main-content');
        this.elems.$confirmContent = $popupWrapper.find('.anp-confirm-content');
        this.elems.$submittedInputInvalid = $popupWrapper.find('.anp-input-submitted-invalid');
        this.elems.$confirmOkButton = $popupWrapper.find('.anp-checkout-confirm-ok');

        this.elems.$openTrigger = $openTrigger;
        this.elems.$okButton = $popupWrapper.find('.anp-ok');
        this.elems.$cancelButton = $popupWrapper.find('.anp-cancel');
        this.elems.$accountNumberInputLabel = $popupWrapper.find('.anp-input-container label');
        this.elems.$accountNumberInput = $popupWrapper.find('.anp-input-container input[type="text"]');
        this.elems.$radioButtons = $popupWrapper.find('#anp-id-mode-selection');
        this.elems.$radioYes = $popupWrapper.find('input[data-rb-id="anp-yes"]');
        this.elems.$radioNo = $popupWrapper.find('input[data-rb-id="anp-no"]');

        this.elems.$cancelButton.prop('disabled', false);

        this.elems.$radioYes.prop('checked', false);
        this.elems.$radioNo.prop('checked', true);

        this.elems.$form = $checkoutDiv.find('form');
        this.elems.$formAccountNumberField = this.elems.$form.find('input[name="accountNumber"]');
      },

      draw: function() {

        state.hasValidAccountNumber = isValidAccountNumber(this.elems.$accountNumberInput.val());

        if(state.showConfirmMessage) {
          this.elems.$confirmContent.show();
          this.elems.$mainContent.hide();
        }
        else {
          this.elems.$confirmContent.hide();
          this.elems.$mainContent.show();
        }

        if(state.showInvalidAccountNumberInfo){
          this.elems.$submittedInputInvalid.css('visibility', 'visible');
        }
        else {
          this.elems.$submittedInputInvalid.css('visibility', 'hidden');
        }

        if(this.elems.$radioYes.is(':checked')) {
          this.elems.$accountNumberInputLabel.css('opacity', 1);
          this.elems.$accountNumberInput.css('opacity', 1);
          this.elems.$accountNumberInput.prop('disabled', false);
        }
        else {
          this.elems.$accountNumberInputLabel.css('opacity', 0.0);
          this.elems.$accountNumberInput.css('opacity', 0.0);
          this.elems.$accountNumberInput.prop('disabled', true);
        }
        this.elems.$okButton.prop('disabled',
          (!state.hasValidAccountNumber || state.showInvalidAccountNumberInfo) &&
          this.elems.$radioYes.is(':checked'));
      }
    };

    $popupTriggers.each(function() {

      $(this).on('click', function() {

        state.init();
        ui.init($(this));
        ui.initHandlers();
        ui.draw();
        ui.openPopup();
      });
    });
  }

})();
