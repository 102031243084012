
(function(){

    function State() {
        this.mobile = false;
        this.open = false;
        this.topLeft = null;
        this.translationPxYFn = null;
        this.ui = null;
    };
    State.prototype.init = function(ui) {
      this.ui = ui;
    };
    State.prototype.setMobile = function(mobile) {
        if(this.mobile == mobile) return;
        this.mobile = mobile;
        this.stateChanged();
    };
    State.prototype.setOpen = function(open) {
        if(this.open == open) return;
        this.open = open;
        this.stateChanged();
    };
    State.prototype.setTopLeft = function(topLeft, doUpdate) {
        if(this.topLeft == topLeft) return;
        this.topLeft = topLeft;
        if(doUpdate) {
            this.stateChanged();
        }
    };
    State.prototype.stateChanged = function() {
        if(!this.ui) return;
        this.ui.draw();
    };

    function Gui(id, widthDesktop, widthMobile, mobileQuery) {

        this.id = id;

        this.elems = {
            $wrapper: $('#' + id),
            $backdrop: $('#' + id + ' > ' + '.popup-id-backdrop'),
            $popup: $('#' + id + ' ' + '.popup-id-popup'),
            $popupContent: $('#' + id + ' ' + '.popup-id-content').children().first(),
            $closeAction: $('.' + id + '-action-close')
        };
        this.style = {
            widthDesktop: widthDesktop,
            widthMobile: widthMobile,
            mobileQuery: mobileQuery,
            widthBackdrop: '100%',

            heightBackdrop: function(){
                return $('body').height();
            }
        };

        if(!this.style.mobileQuery) this.style.mobileQuery = 'only screen and (max-width:425px)';

        this.state = null;
    }
    Gui.prototype.init = function(state) {
        this.state = state;
        this.state.mobile = window.matchMedia(this.style.mobileQuery).matches;
        this.initHandlers();
    };
    Gui.prototype.initHandlers = function() {
        var _this = this;

        _this.elems.$closeAction.click(function(){
            _this.state.setOpen(false);
        });

        _this.elems.$wrapper.on('popup-open', function(e, preOpenFn, translationPxYFn){
            if(preOpenFn) preOpenFn();
            if(translationPxYFn) _this.state.translationPxYFn = translationPxYFn;
            else _this.state.translationPxYFn = null;
            _this.state.setOpen(true);
            e.stopPropagation();
            return false;
        });
        _this.elems.$wrapper.on('popup-close', function(e, postCloseFn){
            _this.state.setOpen(false);
            if(postCloseFn) postCloseFn();
            e.stopPropagation();
            return false;
        });
        _this.elems.$wrapper.on('popup-set-title', function(e, title){
            $(this).find('.popup-title-bar div:first').text(title);
            e.stopPropagation();
            return false;
        });
        _this.elems.$wrapper.on('popup-reposition', function(e) {

            _this.state.translationPxYFn = null;
            _this.elems.$popupContent.trigger('popup-update');
            _this.state.setTopLeft(null, true);

            e.stopPropagation();
            return false;
        });

        $(window).on('resize', function () {
            var mobile = window.matchMedia(_this.style.mobileQuery).matches;
            var needsUpdate = mobile == _this.state.mobile;

            _this.elems.$popupContent.trigger('popup-update');
            _this.state.setTopLeft(null, needsUpdate);

            window.matchMedia(_this.style.mobileQuery).matches
                ? _this.state.setMobile(true)
                : _this.state.setMobile(false);
        });
    };
    Gui.prototype.calcTopLeft = function(translationPxYFn) {

        var scrollTop = $('html').scrollTop() || $('body').scrollTop();
        var marginTop = 10;
        var minY = scrollTop + marginTop;

        if(this.state.mobile) {
            return {x: 0, y: minY};
        }

        var translationPxY = 0;
        if(translationPxYFn) {
            translationPxY = translationPxYFn();
            if(isNaN(translationPxY)) translationPxY = 0;
        }

        var centerX = $(window).outerWidth(false) / 2.0;
        var centerY = scrollTop + $(window).height() / 2.0;

        var top = centerY - this.elems.$popup.height() / 2.0 + translationPxY;
        if(top < minY) top = minY;
        var left = centerX - this.elems.$popup.outerWidth(true) / 2.0;
        return {x: left, y: top};
    };
    Gui.prototype.popupOpen = function() {
        this.elems.$wrapper.css('width', this.style.widthBackdrop);
        this.elems.$backdrop.css('width', this.style.widthBackdrop);
        this.elems.$backdrop.css('height', this.style.heightBackdrop());

        if(this.state.mobile && this.style.widthMobile) {
            this.elems.$popup.css('width', this.style.widthMobile);
        }
        else if(this.style.widthDesktop){
            this.elems.$popup.css('width', this.style.widthDesktop);
        }

        if(!this.state.topLeft) {
            this.elems.$wrapper.fadeIn(400); // hack
            this.state.setTopLeft(this.calcTopLeft(this.state.translationPxYFn), false);
        }

        this.elems.$popup.css('left', this.state.topLeft.x + 'px');
        this.elems.$popup.css('top', this.state.topLeft.y + 'px');

        this.elems.$backdrop.css('pointer-events', 'none');
        this.elems.$wrapper.fadeIn(400);
    };
    Gui.prototype.popupClose = function() {
        this.state.setTopLeft(null, false);
        this.elems.$backdrop.css('pointer-events', 'auto');
        this.elems.$wrapper.fadeOut(400);
    };
    Gui.prototype.draw = function(){

        if(this.state.open) {
            this.popupOpen();
        }
        else {
            this.popupClose();
        }
    };

    function Popup(id, widthDesktop, widthMobile, mobileQuery) {

        this.id = id;
        this.ui = new Gui(id, widthDesktop, widthMobile, mobileQuery);
        this.state = new State(this.ui);

        this.state.init(this.ui);
        this.ui.init(this.state);
    };
    Popup.prototype.init = function() {

        this.state.init(this.ui);
        this.ui.init(this.state);
    };

    function createPopups() {
        var popups = [];
        $('.popup-id-wrapper').each(function(){
             popups.push(new Popup(
                 $(this).attr('data-popup-id'),
                 $(this).attr('data-popup-width')));
        });
    }

    var popups = createPopups();

})();
