$('.horizontal-scroll').each(function(){
	var scroll = $(this);
		var last = 0;

	scroll.find('.horizontal-scroll-item').swipe({
		allowPageScroll: $.fn.swipe.pageScroll.VERTICAL,
		threshold: 20,
		swipeStatus:function(event, phase, direction, distance, duration) {
			// event.preventDefault();
			// stop bubbling
			if(scroll.is($(event.target).closest('.horizontal-scroll'))) {
				// store swipe data for acceleration
				if(direction === $.fn.swipe.directions.LEFT || direction === $.fn.swipe.directions.RIGHT) {
					var lr = direction === $.fn.swipe.directions.LEFT ? 1 : -1;
					scroll.scrollLeft(scroll.scrollLeft() + (lr * (distance - last)));
					last = distance;
				}

				// discard stored data on end and cancel
				if(phase == $.fn.swipe.phases.PHASE_END || phase == $.fn.swipe.phases.PHASE_CANCEL) {
					last = 0;
				}
			}
		}
	});
});
