// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction();};

function scrollFunction() {
	var metaNavElement = document.getElementById('meta-header');
	var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

	if(metaNavElement != undefined) {
		if(scrollTop > metaNavElement.offsetHeight) {

			document.getElementsByTagName('header')[0].style.top = '-43px';
			document.getElementsByTagName('header')[0].style.boxShadow = '0px 3px 5px -3px rgba(0,0,0,0.25)';
			$('header .logo').addClass('small');
		} else {
			document.getElementsByTagName('header')[0].style.top = '0px';
			$('header .meta').show();
			$('header .logo').removeClass('small');
			document.getElementsByTagName('header')[0].style.boxShadow = 'none';
		}

		var topButton = document.getElementById('topButton');

		if (topButton != undefined && topButton != null) {

			var targetLink = topButton.getAttribute('data-target-link');

			if(targetLink && targetLink.length > 0) {
				// don't change anything in this case.
			}
			else if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {

				topButton.style.display = topButton.textContent && topButton.textContent.length > 0
					? 'flex'
					: 'block';
			} else {
				topButton.style.display = 'none';
			}
		}
	}
}

function offsetAnchor() {
  if (location.hash.length !== 0) {
    window.scrollTo(window.pageXOffset, window.pageYOffset - 100);
  }
}

$( 'a' ).each(function( index ) {
	if ($(this).prop('href').indexOf('#') > 0) {
		// Captures click events of all <a> elements with href starting with #
		$(this).click(function(event) {
		  // Click events are captured before hashchanges. Timeout
		  // causes offsetAnchor to be called after the page jump.
		  window.setTimeout(function() {
			  window.scrollTo(window.pageXOffset, window.pageYOffset - 100);
		  }, 20);
		});
	}
});



// Set the offset when entering page with hash present in the url
window.onload = function() {
	if ($(window).height() > 800) {
		Stickyfill.add(document.querySelectorAll('header'));
	}
	// Just for IE11
	window.setTimeout(function(){$('body').trigger('scroll');}, 1000);

	if(window.location.href.indexOf('/shop/') < 0) {
		window.setTimeout(function() {
		    offsetAnchor();
	    }, 20);
	}
};

window.onresize = function() {
	if ($(window).height() > 800) {
		Stickyfill.add(document.querySelectorAll('header'));
	} else {
		Stickyfill.removeOne(document.querySelectorAll('header'));
	}
};

// When the user clicks on the button, scroll to the top of the document
window.topFunction = function() {
    document.body.scrollTop = 0; // For Chrome, Safari and Opera
    document.documentElement.scrollTop = 0; // For IE and Firefox
};



window.hasCookiebotOnPage = function() {
	return window && window.Cookiebot !== undefined;
}

window.hasCookiebotConsent = function(consentType) {

	if(!consentType) return false;
	if(!window.hasCookiebotOnPage()) return false;

	if(consentType !== 'preferences' && consentType !== 'statistics' && consentType !== 'marketing') {
		return false;
	}
	return window.Cookiebot.consent && window.Cookiebot.consent[consentType] === true;
}

window.setCookie = function(name, value, consentType, maxAgeInSeconds, expiresGtmString) {

	if(consentType !== undefined && window.hasCookiebotOnPage()) {
		if(!hasCookiebotConsent(consentType)) return;
	}

	if(maxAgeInSeconds) {
		document.cookie = `${name}=${value}; max-age=${maxAgeInSeconds}; path=/; samesite=strict; secure`;
	}
	else if(expiresGtmString) {
		document.cookie = `${name}=${value}; expires=${expiresGtmString}; path=/; samesite=strict; secure`;
	}
	else {
		Cookies.set(name, value, {expires: 50, path: '/'});
	}
};
window.getCookieValue = function(name) {
	return document.cookie
		.split('; ')
		.find((row) => row.startsWith(`${name}=`))
		?.split('=')[1];
}
