(function() {
  document.addEventListener('DOMContentLoaded', function(event) {
    var $errorDialogContainer = $('.info-msgs-container');
    if ($errorDialogContainer.length > 0) {
      var $errorDialog = $errorDialogContainer.first();
      var dialogText = $('.expired-link-dialog').attr('data-expired-link-text');
      if (dialogText) {
        // The 'show-info-messages' event has to be triggered
        // twice here, because Firefox/Chrome load the relevant
        // scripts in a different order.

        $errorDialog.trigger('show-info-messages', [[dialogText], false]);
        $errorDialogContainer.on('info-messages-loaded', function () {
          $errorDialog.trigger('show-info-messages', [[dialogText], false]);
        });
      }
    }
  });
})();
