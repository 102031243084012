import { getCorrection } from 'components/helpers/paddingCorrections';

(function initCustomBannerWrapper() {

  const customBannerWrapperList = document.getElementsByClassName('custombanner-wrapper');
  for(let k = 0; k < customBannerWrapperList.length; ++k) {

    const customBannerWrapper = customBannerWrapperList[k];
    if(!customBannerWrapper) continue;

    const noWhitespace = customBannerWrapper.hasAttribute('data-no-whitespace')
      ? customBannerWrapper.getAttribute('data-no-whitespace')
      : 'false';

    const showSeparator = customBannerWrapper.hasAttribute('data-show-separator')
      ? customBannerWrapper.getAttribute('data-show-separator')
      : '';

    if(noWhitespace === 'true') {

      const corrections = getCorrection(customBannerWrapper);

      customBannerWrapper.style.marginLeft = `-${corrections.left}px`;
      customBannerWrapper.style.marginRight = `-${corrections.right}px`;
    }

    const prevSibling = customBannerWrapper.previousElementSibling;
    const prevSiblingHasBottomBorder = prevSibling && prevSibling.style && prevSibling.style.borderBottomWidth !== '';

    if(showSeparator === 'top' || (showSeparator === 'topbottom') && !prevSiblingHasBottomBorder) {
      customBannerWrapper.style.borderTop = '1px solid #bcbdc0';
    }
    if(showSeparator === 'bottom' || showSeparator === 'topbottom') {
      customBannerWrapper.style.borderBottom = '1px solid #bcbdc0';
    }

  }
  window.addEventListener('resize', initCustomBannerWrapper);
})();

