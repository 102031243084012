const language = document.documentElement.lang.replace('-', '_')

export const translatedField = (fieldObject) => {
    const closestLanguage = Object.keys(fieldObject).filter((lang) => {
        return lang.startsWith(language.slice(0, 3))
    }).find((lang) => !!fieldObject[lang])
    
    return fieldObject[language] ?? fieldObject[closestLanguage] ?? fieldObject['en_US'] ?? ''
}

