var currentKlass = [];

$(document).on('overlay-on', function(ev, klass, modal) {
	$('body').addClass('with-overlay');
	var oldKlass = [];

	if(klass) {
		var newKlass = klass.split(' ');
		currentKlass = $.grep(currentKlass, function(k, i) {
			if(newKlass.indexOf(k) < 0) {
				oldKlass.push(k);
				return false;
			}

			return true;
		});

		$.merge(currentKlass, newKlass);
	}

	if(oldKlass.length > 0) {
		$('body').removeClass(oldKlass.join(' '));
	}

	if(currentKlass.length > 0) {
		$('body').addClass(klass);
	}

	if(modal !== true) {
		$(document).one('click', '.overlay', function() {
			$(document).trigger('overlay-off');
		});
	}

	$('.with-overlay').on('click', (ev) => {
		const modalElem = document.querySelector('.modal');
		if(modalElem && !modalElem.contains(ev.target) && modalElem.querySelector('.add-to-cart-item, .downloadcenter-add')) {
			Belimo.Modal.close();
		}
	});

}).on('overlay-off', function(ev) {
	$('body').removeClass('with-overlay');
	$('body').removeClass('with-burger-overlay');
	if(currentKlass.length > 0) {
		$('body').removeClass(currentKlass.join(' '));
	}
});
