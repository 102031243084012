import { getCorrection } from 'components/helpers/paddingCorrections';

(function initAccordion() {

  // Set dynamic layout settings for the content of an accordion element.

  const mediaQuery = window.matchMedia('(min-width: 1020px)');
  const isMobile = !mediaQuery.matches;

  const accordionComponentsList = document.getElementsByClassName('bel-accordion-elem-components');


  for(let k = 0; k < accordionComponentsList.length; ++k) {

    const accordionComponents = accordionComponentsList[k];
    if(!accordionComponents) return;

    if(!accordionComponents.hasAttribute('data-count')) continue;
    const columnCount = accordionComponents.getAttribute('data-count');
    if(!columnCount || isNaN(parseInt(columnCount))) continue;

    if(isMobile) {
      columnCount = 1;
    }

    const accordionComponentList = accordionComponents.querySelectorAll('.bel-accordion-elem-component');
    if(accordionComponentList.length === 0) continue;
    let componentWidth = 100.0 / columnCount;
    if(columnCount > 1) {
      componentWidth = 99.0 / columnCount;
    }

    for(let i = 0; i < accordionComponentList.length; ++i) {
      const accordionComponent = accordionComponentList[i];
      if(!accordionComponent.hasAttribute('data-index')) break;
      const columnIndex = accordionComponent.getAttribute('data-index');
      if(columnIndex === undefined || isNaN(parseInt(columnIndex))) break;
      accordionComponent.classList.add('content');

      const style = `width: ${componentWidth}%;`;

      if(!accordionComponent.hasAttribute('data-separator')) break;
      const separator = accordionComponent.getAttribute('data-separator');


      if(separator === 'top' || separator === 'topbottom') {
        style += `border-top: 1px solid #bcbdc0;`;
      }
      if(separator === 'bottom' || separator === 'topbottom') {
        style += `border-bottom: 1px solid #bcbdc0;`;
      }

      accordionComponent.setAttribute('style', style);
    }
  }
  window.addEventListener('resize', initAccordion);
}());


(function() {

  // Accordion interactions and transitions.

  const mediaQuery = window.matchMedia('(min-width: 1020px)');

  function removeHashFromUrl() {
    var uri = window.location.toString();

    if (uri.indexOf('#') > 0) {
      const cleanUri = uri.substring(0, uri.indexOf('#'));
      window.history.replaceState({}, document.title, cleanUri);
    }
  }

  function isHandleBarOpen(handleBar) {
    const elemContent = handleBar.parentElement.querySelector('.bel-accordion-elem-content');
    if(!elemContent) false;

    return elemContent.classList.contains('visible');
  }

  function closeHandleBar(handleBar) {

    const handleBarIcon = handleBar.querySelector('.bel-accordion-elem-bar-handle');
    const elemContent = handleBar.parentElement.querySelector('.bel-accordion-elem-content');

    elemContent.classList.remove('visible');
    handleBarIcon.style.transform = 'rotate(0deg)';

    handleBar.classList.remove('open');
    elemContent.style.height = `${0}px`;

    const next = handleBar.closest('.bel-accordion-elem').nextElementSibling;
    if(next) {
      next.querySelector('.bel-accordion-elem-bar').classList.remove('open-before');
    }
  }

  function openHandleBar(handleBar, keepOpen) {

    const handleBarIcon = handleBar.querySelector('.bel-accordion-elem-bar-handle');
    if(keepOpen) {
      if(handleBarIcon) {
        handleBarIcon.style.display = 'none';
      }
      handleBar.style.cursor = 'auto';
    }

    const elemContent = handleBar.parentElement.querySelector('.bel-accordion-elem-content');

    const elemComponents = elemContent.querySelector('.bel-accordion-elem-components');

    elemContent.classList.add('visible');
    handleBarIcon.style.transform = 'rotate(180deg)';

    handleBar.classList.add('open');
    elemContent.style.height = `${elemComponents.clientHeight}px`;

    const next = handleBar.closest('.bel-accordion-elem').nextElementSibling;
    if(next) {
      next.querySelector('.bel-accordion-elem-bar').classList.add('open-before');
    }
  }

  function setAccordionWrapperBottomPadding(accordionWrapper, noBottomPadding, handleBars) {
    if(noBottomPadding) {
      for(let i = 0; i < handleBars.length; ++i) {
        if(isHandleBarOpen(handleBars[i])) {
          accordionWrapper.style.paddingBottom = '0';
        }
        else {
          accordionWrapper.style.removeProperty('padding-bottom');
        }
      }
    }
  }


  function closeAllHandleBars(handleBars) {

    for(let i = 0; i < handleBars.length; ++i) {
      const handleBar = handleBars[i];
      if(!handleBar) continue;
      closeHandleBar(handleBar);

    }
  }

  function setHandleBarState(state, handleBars, handleBar, allowMultiView, keepOpen) {

    const isCurrentlyVisible = isHandleBarOpen(handleBar);
    if(state === isCurrentlyVisible) return;
    if(!allowMultiView && !keepOpen) {
      closeAllHandleBars(handleBars);
    }
    if(isCurrentlyVisible && allowMultiView && !keepOpen) {
      closeHandleBar(handleBar);
    }
    else if(!isCurrentlyVisible){
      openHandleBar(handleBar);
    }
  }

  const accordionWrappers = document.getElementsByClassName('bel-accordion-wrapper');
  for(let k = 0; k < accordionWrappers.length; ++k) {

    const accordionWrapper = accordionWrappers[k];
    if(!accordionWrapper) continue;


    const allowMultiView = accordionWrapper.hasAttribute('data-disallow-multi-view')
      ? (accordionWrapper.getAttribute('data-disallow-multi-view') !== 'true')
      : true;
    const keepOpen = accordionWrapper.hasAttribute('data-keep-open')
      ? (accordionWrapper.getAttribute('data-keep-open') === 'true')
      : false;
    const isAuthorEditMode = accordionWrapper.hasAttribute('data-is-author-edit-mode')
      ? (accordionWrapper.getAttribute('data-is-author-edit-mode') === 'true')
      : false;
    const showSeparator = accordionWrapper.hasAttribute('data-show-separator')
      ? accordionWrapper.getAttribute('data-show-separator')
      : '';
    const isFullWidth = accordionWrapper.hasAttribute('data-full-width')
      ? (accordionWrapper.getAttribute('data-full-width') === 'true')
      : false;
    const noBottomPadding = accordionWrapper.hasAttribute('data-no-bottom-padding')
      ? (accordionWrapper.getAttribute('data-no-bottom-padding') === 'true')
      : false;

    let corrections = undefined;
    const isMobile = !mediaQuery.matches;
    if(showSeparator !== '') {
      if(showSeparator === 'top' || (showSeparator === 'topbottom')) {
        accordionWrapper.style.borderTop = '1px solid #bcbdc0';
      }
      if(showSeparator === 'bottom' || showSeparator === 'topbottom') {
        accordionWrapper.style.borderBottom = '1px solid #bcbdc0';
      }
    }

    corrections = getCorrection(accordionWrapper);
    if(!isFullWidth/* && !isMobile*/) {
      accordionWrapper.style.paddingLeft = `${corrections.left}px`;
      accordionWrapper.style.paddingRight = `${corrections.right}px`;
    }
    accordionWrapper.parentNode.style.marginLeft = `-${corrections.left}px`;
    accordionWrapper.parentNode.style.marginRight = `-${corrections.right}px`;
    
    if(isMobile) {
      const columns = accordionWrapper.querySelectorAll('.bel-accordion-elem-component .grid .column');
      for(let j = 0; j < columns.length; ++j) {
        columns[j].style.paddingLeft = '0px';
        columns[j].style.paddingRight = '0px';
      }
    }

    if(isAuthorEditMode) {

      // Within the page editor, we need to make sure that
      // the accordion elements are properly expanded because
      // Magnolia adds the green bars after the page load.

      const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          // let editorBarAdded = false;
          mutation.addedNodes.forEach(function(addedNode) {

            if(addedNode.parentElement.classList.contains('bel-accordion-wrapper')) {
              addedNode.addEventListener('click', () => {
                const handleBars = accordionWrapper.getElementsByClassName('bel-accordion-elem-bar');
                for(let i = 0; i < handleBars.length; ++i) {
                  handleBars[i].dispatchEvent(new CustomEvent('handleBarResize', { }));
                }
              });
            }

            if(addedNode.parentElement.classList.contains('bel-accordion-elem-components') ||
               addedNode.parentElement.parentElement.classList.contains('bel-accordion-elem-components')) {

              const accordionElem = addedNode.parentElement.closest('.bel-accordion-elem');
              if(accordionElem) {

                addedNode.addEventListener('click', () => {
                  const handleBars = accordionElem.getElementsByClassName('bel-accordion-elem-bar');
                  for(let i = 0; i < handleBars.length; ++i) {
                    handleBars[i].dispatchEvent(new CustomEvent('handleBarResize', { }));
                  }
                });
              }
            }
          });
        });
      });
      observer.observe(accordionWrapper, { subtree: true, childList: true });
    }


    (function(allowMultiView, keepOpen, isAuthorEditMode, isFullWidth, corrections, noBottomPadding, accordionWrapper) {

      const isMobile = !mediaQuery.matches;

      const components = accordionWrapper.getElementsByClassName('bel-accordion-elem-component');
      for(let i = 0; i < components.length; ++i) {
        const component = components[i];
        if(isFullWidth || isMobile) {
          component.style.paddingLeft = `${corrections.left}px`;
          component.style.paddingRight = `${corrections.right}px`;
        }
      }

      const handleBars = accordionWrapper.getElementsByClassName('bel-accordion-elem-bar');
      for(let i = 0; i < handleBars.length; ++i) {
        const handleBar = handleBars[i];
        if(!handleBar) continue;

        (function(handleBar, allowMultiView, keepOpen, corrections){

          if(keepOpen) {
            openHandleBar(handleBar, keepOpen);
          }
          else {
            closeHandleBar(handleBar);
          }

          handleBar.addEventListener('openIfContainsId', function(e) {
            const elem = handleBar.nextElementSibling.querySelector(`#${e.detail}`);
            if(!elem) return;
            setHandleBarState(true, handleBars, handleBar, allowMultiView, keepOpen);
          });

          handleBar.addEventListener('click', function() {

            removeHashFromUrl();
            if(!keepOpen) {
              const isCurrentlyVisible = isHandleBarOpen(handleBar);
              setHandleBarState(!isCurrentlyVisible, handleBars, handleBar, allowMultiView, keepOpen);
            }

            setAccordionWrapperBottomPadding(accordionWrapper, noBottomPadding, handleBars);

          });

          handleBar.addEventListener('handleBarResize', function(e) {
            const isCurrentlyVisible = isHandleBarOpen(handleBar);
            if(isCurrentlyVisible || isAuthorEditMode) {
              openHandleBar(handleBar);
            }
          });
        }(handleBar, allowMultiView, keepOpen, isFullWidth, corrections));

      }
    }(allowMultiView, keepOpen, isAuthorEditMode, isFullWidth, corrections, noBottomPadding, accordionWrapper));
  }

  (function() {

    // Open accordion element if an anchor link has been set whose target
    // is inside the accordion.

    function locationHashChanged(event) {

      if(window.location.hash != undefined) {
        const decodedHash = decodeURIComponent(window.location.hash);
        openHandleBarAndScroll(decodedHash.slice(1));
      }
    }

    function findWrappingAccordionHandleBar(decodedHash) {
      const element = document.getElementById(decodedHash);
      if(!element) return undefined;

      const wrapper = element.closest('.bel-accordion-elem');
      if(!wrapper) return undefined;
      return wrapper.querySelector('.bel-accordion-elem-bar');
    }

    function elemOffset(el) {
      const rect = el.getBoundingClientRect();
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    function getHeaderElemsHeight() {
      const headerElems = document.getElementsByTagName('header');
      let headerElemHeight = 0;
      if(headerElems.length) {
        const headerElemStyles = getComputedStyle(headerElems[0]);
        if(headerElemStyles.getPropertyValue('position') === 'sticky') {
          headerElemHeight = headerElems[0].clientHeight;
        }
      }
      return headerElemHeight;
    }

    function openHandleBarAndScroll(decodedHash) {
      const handleBar = findWrappingAccordionHandleBar(decodedHash);
      if(!handleBar) return;
      handleBar.dispatchEvent(new CustomEvent('openIfContainsId', { detail: decodedHash }));

      const element = document.getElementById(decodedHash);
      removeHashFromUrl();
      setTimeout(() => {
        window.scrollTo({
          left: 0, top:
          elemOffset(element).top - getHeaderElemsHeight(),
          behavior: 'smooth'
        });
      }, 100);
    }

    locationHashChanged();
    window.addEventListener('hashchange', locationHashChanged);
  }());

  window.addEventListener('resize', () => {

    const isMobile = !mediaQuery.matches;
    const accordionWrappers = document.getElementsByClassName('bel-accordion-wrapper');
    for(let k = 0; k < accordionWrappers.length; ++k) {

      const accordionWrapper = accordionWrappers[k];
      if(!accordionWrapper) continue;

      const isFullWidth = accordionWrapper.hasAttribute('data-full-width')
        ? (accordionWrapper.getAttribute('data-full-width') === 'true')
        : false;

      const noBottomPadding = accordionWrapper.hasAttribute('data-no-bottom-padding')
        ? (accordionWrapper.getAttribute('data-no-bottom-padding') === 'true')
        : false;

      const corrections = getCorrection(accordionWrapper);
      if(!isFullWidth) {
        accordionWrapper.style.paddingLeft = `${corrections.left}px`;
        accordionWrapper.style.paddingRight = `${corrections.right}px`;
      }
      else {
        accordionWrapper.style.paddingLeft = `0`;
        accordionWrapper.style.paddingRight = `0`;
      }
      accordionWrapper.parentNode.style.marginLeft = `-${corrections.left}px`;
      accordionWrapper.parentNode.style.marginRight = `-${corrections.right}px`;

      const components = accordionWrapper.getElementsByClassName('bel-accordion-elem-component');
      for(let i = 0; i < components.length; ++i) {
        const component = components[i];
        if(isFullWidth || isMobile) {
          component.style.paddingLeft = `${corrections.left}px`;
          component.style.paddingRight = `${corrections.right}px`;
        }
        else {
          component.style.paddingLeft = `0`;
          component.style.paddingRight = `0`;
        }

        const columns = component.querySelectorAll('.grid .column');
        for(let j = 0; j < columns.length; ++j) {
          if(isMobile && !isFullWidth) {
            columns[j].style.paddingLeft = '0px';
            columns[j].style.paddingRight = '0px';
          }
          else {
            columns[j].style.removeProperty('padding-left');
            columns[j].style.removeProperty('padding-right');
          }
        }
      }
      const handleBars = document.getElementsByClassName('bel-accordion-elem-bar');
      for(let i = 0; i < handleBars.length; ++i) {
        handleBars[i].dispatchEvent(new CustomEvent('handleBarResize', { }));
      }

      setAccordionWrapperBottomPadding(accordionWrapper, noBottomPadding, handleBars);
    }
  });
})();
Promise.all(Array.from(document.images)
  .filter(img => !img.complete)
  .map(img => new Promise(resolve => {img.onload = img.onerror = resolve; }))).then(() => {
    window.dispatchEvent(new Event('resize'));
});
