$(function() {
    if($('.checkout-wizard-step.done').length > 0) {
        window.setTimeout(function(){
            $(document).scrollTop($('.checkout-wizard-step.current').offset().top);
        }, 50);
    }
});

(function(){

    var $specialRequestForm = $('#belimoSpecialRequestsForm');
    var $emailRadio = $specialRequestForm.find('input[value="email"][type="radio"]');
    var $phoneRadio = $specialRequestForm.find('input[value="phone"][type="radio"]');

    $specialRequestForm.submit(function() {
        if($emailRadio.prop('checked')) {
            var $phoneText = $specialRequestForm.find('input[name="phone"][type="text"]');
            $phoneText.val('');
        }
        if($phoneRadio.prop('checked')) {
            var $emailText = $specialRequestForm.find('input[name="email"][type="text"]');
            $emailText.val('');
        }
        return true;
    });
})();
