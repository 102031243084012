
(function() {

    var $forms = $('button.batch-tagging').closest('form');
    if($forms.length === 0) return;

    function TagForm($form) {
        this.$form = $form;
        this.$applyButton = $form.find('button.batch-tagging');
        this.$srcInputField = $form.find('.batch-tagging-src');
        this.$dstInputFields = $form.find('input.batch-tagging');
        this.origTagText = this.$srcInputField.val();
        this.applyAlwaysAllowed = this.$applyButton.attr('data-always-enabled') === 'true';
    }
    TagForm.prototype.apply = function() {
        if(!this.isApplyAllowed()) return;
        var _this = this;
        this.$dstInputFields.each(function() {
            $(this).val(_this.$srcInputField.val());
        });
        this.$form.submit();
        return false;
    };
    TagForm.prototype.setApplyButtonState = function() {
        this.$applyButton.prop('disabled', !this.isApplyAllowed());
    };
    TagForm.prototype.isApplyAllowed = function() {

        return this.applyAlwaysAllowed ||
            (this.$srcInputField.val() && this.$srcInputField.val() !== this.origTagText);
    };
    TagForm.prototype.init = function() {
        this.setApplyButtonState();
    };
    TagForm.prototype.initHandlers = function() {

        if(!this.$applyButton || !this.$srcInputField || !this.$dstInputFields) {
            return;
        }

        var _this = this;
        this.$applyButton.on('click', function() {
            return _this.apply();
        });
        this.$srcInputField.on('keyup', function(e) {
            if(e.keyCode === 13) {
                return _this.apply();
            }
            return true;
        });
        this.$srcInputField.on('keydown', function(e) {
            if(e.keyCode === 13) {
                return false;
            }
        });

        this.$srcInputField.on('input', function() {
            _this.setApplyButtonState();
        });
    };

    var forms = [];
    $forms.each(function() {
       forms.push(new TagForm($(this)));
    });

    for(var i = 0; i < forms.length; i++) {
        forms[i].init();
        forms[i].initHandlers();
    }
})();





