$('.locations').each(function() {
    var locations = $(this);
        var form = locations.find('.form-location-contacts');
        var contacts = form.next('.contacts');

    if(contacts.length > 0) {
        window.setTimeout(function(){
            $(document).scrollTop(form.offset().top);
        }, 50);
    } else {
        if(form.length > 0) {
            window.setTimeout(function(){
                $(document).scrollTop(locations.offset().top);
            }, 50);
        }
    }
});
