function ProductModel (cartModel, data) {
	var model = this;

	model.code = data.code;
	model.name = data.name;
	model.url = data.url || '#product-url';
	model.price = data.price.formattedValue;
	model.quantity = data.quantity;
	model.thumbnail = '';
	// alt text is not passed to the frontend, using name of product instead.
	model.altText = data.name;

	if ( data.images)
	{
        for(var i = 0; i < data.images.length; i++) {
            if(data.images[i].format === 'thumbnail') {
                model.thumbnail = data.images[i].url;
            }
        }
	}
}

function CartModel(element) {
	var model = this;

	model.element = element;
	model.products = ko.observableArray([]);
	model.lineItems = ko.observable(0);
	model.isAnonymousUser = ko.observable(true);
	model.activeSaveCartName = ko.observable('');

	model.updateCart = function(products) {
		model.products.removeAll();

		for(var i = 0; i < products.length; i++) {
			model.products.push(new ProductModel(model, products[i]));
		}
	};
	model.setIsAnonymousUser = function(isAnonymousUser) {
		model.isAnonymousUser(isAnonymousUser);
	};
	model.setActiveSaveCartName = function(activeSaveCartName) {
		model.activeSaveCartName(activeSaveCartName);
	};
};

$(function(){
	$('.cart-widget').each(function() {
		var cart = $(this);
			// triggerLabel = cart.closest('.panel-content').prev('.panel-trigger').find('> span[data-bind]'),
			var model = new CartModel(cart);

		ko.applyBindings(model, cart.closest('.panel-cart').get(0));
		// ko.applyBindings(model, triggerLabel.get(0));

		$(document).on('cart-update', function(ev, products) {
			model.updateCart(products);
			model.lineItems(products.reduce(function(acc, add) {
				return acc + add.quantity;
			}, 0));
		});
		$(document).on('cart-rename', function(ev, name) {
			model.setActiveSaveCartName(name);
		});

		var $cartIconWithLink = $('div[data-body-class="with-navigation with-cart"]');
		$cartIconWithLink.css('pointer-events', 'none');
		$cartIconWithLink.css('opacity', 0.5);

		$.ajax({
			method: 'GET',
			url: cart.data('cart-url'),
			dataType: 'json',
			success: function(response) {
				model.updateCart(response.products || []);
                model.lineItems(response.miniCartCount);

                model.setActiveSaveCartName(response.activeSaveCartName ? response.activeSaveCartName : '');
				model.setIsAnonymousUser(!(response.anonymousUser && response.anonymousUser === 'false'));
				$cartIconWithLink.css('pointer-events', 'auto');
				$cartIconWithLink.css('opacity', 1.0);
			},
			error: function(response) {
			    if(response && response.responseText) {
                    console.error(cart.data('cart-url'), response.responseText);
                }
			}
		});

	});
});
