$('body').on('click', '.js-minus', function(ev){
	ev.preventDefault();
	var input = $(this).next('.input').find('input');
	var min = parseInt(input.attr('min'), 10);
	var max = parseInt(input.attr('max'), 10);

	if(isNaN(min)) {
		min = 1;
	}

	if (parseInt(input.val()) <= min) return;

	input.val(parseInt(input.val()) - 1);

	if($(this).hasClass('js-submit')) {
		$(this).closest('form').submit();
	}

});
$('body').on('click', '.js-plus', function(ev){
	ev.preventDefault();
	var input = $(this).prev('.input').find('input');
	var min = parseInt(input.attr('min'), 10);
	var max = parseInt(input.attr('max'), 10);

	if(isNaN(min)) {
		min = 1;
	}

	if(!isNaN(max) && parseInt(input.val()) >= max) return;
	input.val(parseInt(input.val()) + 1);

	if($(this).hasClass('js-submit')) {
		$(this).closest('form').submit();
	}
});

$('.js-update-entry-quantity-input').on('keypress', function(ev){
	var key = window.event ? ev.keyCode : ev.which;
	if (ev.keyCode === 8 || ev.keyCode === 46 || ev.keyCode === 13) {
        return true;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else {
    	return true;
    }
});

$('.js-update-entry-quantity-input').on('keyup', function(ev){
	var input = $(this);
	var value = parseInt(input.val());
	var min = parseInt(input.attr('min'), 10);
	var max = parseInt(input.attr('max'), 10);

	if(isNaN(min)) {
		min = 1;
	}

	if (value < min) {
		input.val(min);
	}
	if (value > max) {
		input.val(max);
	}
});
