$('.form.add-to-cart-form').each(function(){
	var form = $(this).find('form');
	var button = $(this).find('.add-to-cart');

	button.on('click', function(ev){
		form.submit();
	});

	form.on('submit', function(ev){
		ev.preventDefault();

		var url = form.attr('action');
		var data = form.serializeArray();
		Belimo.Cart.submit(url, data);

		return false;
	});
});
