export const renderPagination = (pages, handlePagination, handlePaginationControlButtons) => {
  /**
   * handlePagination, handlePaginationControlButtons are callback functions which change in the jobList.js file the searchParams object
   * and recall the job fetching
   */
  //TODO: currently not clear if max is the number of available pages (total items / 25), or if it is the max number of items per page -> 25
  const { max, current } = pages
  const paginationList = document.querySelector('.joblist-pagination__pages')
  const currentPage = parseInt(current)

  //creating the numbers based on the current page and number of pages
  //it creates an array eg [3,4,5,6,7] if current page is 5
  const paginationRange = calculatePaginationSlice(max, current)
  const templateArray = []

  //creating the pagination buttons (numbers)
  paginationRange.forEach((item) => {
    templateArray.push(itemTemplate(item, currentPage))
  })

  //populting the pagination list
  paginationList.innerHTML = templateArray.join('')

  const buttons = paginationList.querySelectorAll('.joblist-pagination__page-item-button')

  if (buttons.length) {
    buttons.forEach((button) => {
      //adding the click event listener to each button, which calls the callback function from the jobList.js file
      button.addEventListener('click', handlePagination)
    })
  }

  const controlButtonPrev = document.querySelector('.joblist-pagination__control-button--prev')
  const controlButtonNext = document.querySelector('.joblist-pagination__control-button--next')

  if (controlButtonPrev) {
    //using the bind function to pasd information from the event listener to the callback function - otherwise "event" would be used
    //beacause the event listener is registered in the browser, changing the button's data attribute would have no effect
    //as it is changed after the registration happened
    const newControlButtonPrev = controlButtonPrev.cloneNode(true)
    controlButtonPrev.parentNode.replaceChild(newControlButtonPrev, controlButtonPrev)
    controlButtonPrev.addEventListener(
      'click',
      handlePaginationControlButtons.bind(null, currentPage - 1 === 0 ? 1 : currentPage - 1)
    )
  }

  if (controlButtonNext) {
    const newControlButtonNext = controlButtonNext.cloneNode(true)
    controlButtonNext.parentNode.replaceChild(newControlButtonNext, controlButtonNext)
    newControlButtonNext.addEventListener(
      'click',
      handlePaginationControlButtons.bind(null, currentPage + 1 >= max ? max : currentPage + 1)
    )
  }

  const pagination = document.querySelector('.joblist-pagination')

  if (max <= 1 && pagination) {
    pagination.style.display = 'none'
  }
}

const itemTemplate = (num, currentPage) =>
  `<li class="joblist-pagination__page-item ${
    num === currentPage ? 'joblist-pagination__page-item--active' : ''
  }"><button class="joblist-pagination__page-item-button" data-page="${num}">${num}</button></li>`

export const calculatePaginationSlice = (max, currentRaw) => {
  //sometimes the current page number comes in as string
  const current = typeof currentRaw === 'string' ? parseInt(currentRaw) : currentRaw
  const items = []
  //based on the total number of pages, we create an array and add each number to it
  for (let i = 1; i < max + 1; i++) {
    items.push(i)
  }

  //finding the index of the current page number in the available pages array
  const index = items.findIndex((item) => current === item) + 1
  let selection = []

  //if the current page is in the first 2 items in the array, we can not show negative numbers
  if (index < 3) {
    //if we are eg on number 2 and there are more than 5 items in the array, we take the numbers 1-5 since we want to display also the number 1
    if (max > 5) {
      selection = items.slice(0, 5)
    } else if (max <= 5) {
      //if there are less than 5 numbers in the array, we just show whatever is in there
      selection = items.slice(0, max)
    }
  } else if (index >= 3 && index <= max - 2) {
    //in this case we dont show nr 1 as starting page eg [3,4,5,6,7] if there are more than 7 items
    const start = items.slice(index - 3, index)
    const end = items.slice(index, index + 2)

    selection = [...start, ...end]
  } else if (index >= 3 && index > max - 2) {
    //we count from the back of the array if the current page is at the end of the array eg [3,4,5,6,7] current page is let s say 6
    selection = items.slice(-5)
  }

  return selection
}
