
(function() {

  var $adminPage = $('.page-accountAdmin');

  if($adminPage.length > 0) {
    init();
  }

  function init() {

    var $forms = $adminPage.find('form.user-remove');
    var $popupWrapper = $adminPage.find('#popup-id-rup');

    var state = {
      language: null,
      initialized: false,
      $leftCompanyHiddenInput: null,
      init: function() {
        this.initialized = true;
      },
      setLeftCompany: function(yesOrNo) {
        if(this.$leftCompanyHiddenInput && this.$leftCompanyHiddenInput.length > 0) {
          yesOrNo
            ? this.$leftCompanyHiddenInput.val('true')
            : this.$leftCompanyHiddenInput.val('false');
        }
        console.log(this.$leftCompanyHiddenInput.val());
      }
    };

    var ui = {

      elems: {
        $openTrigger: null,
        $yesButton: null,
        $noButton: null,
        $form: null,
      },

      initHandlers: function() {
        var _this = this;

        function closePopup() {
          $popupWrapper.trigger('popup-close');
        }
        function submitForm(yesOrNo) {
          state.setLeftCompany(yesOrNo);
          closePopup();
          _this.elems.$form.submit();
        }
        this.elems.$yesButton.on('click', function(e) {
          submitForm(true);
          e.preventDefault();
          return false;
        });
        this.elems.$noButton.on('click', function(e) {
          submitForm(false);
          e.preventDefault();
          return false;
        });
      },
      setForm: function($form) {
        this.elems.$form = $form;
      },
      init: function($openTrigger) {

        this.elems.$openTrigger = $openTrigger;
        this.elems.$yesButton = $popupWrapper.find('.rup-yes');
        this.elems.$noButton = $popupWrapper.find('.rup-no');
      },
      openPopup: function() {
        $popupWrapper.trigger('popup-open', []);
      },
      draw: function() {
      }
    };

    $forms.each(function() {
      var $form = $(this);
      var $button = $form.find('button');
      if($button.length > 0) {

        $button.on('click', function () {
          if(!state.initialized) {
            state.init();
            ui.init($(this));
            ui.initHandlers();
          }

          state.$leftCompanyHiddenInput = $form.find('input[name="leftCompany"]');
          ui.setForm($form);
          ui.draw();
          ui.openPopup();
        });
      }
    });
  }
})();
