(function initInfoPopup() {

  const infoPopupWrapper = document.querySelector('.info-popup-wrapper');
  if(!infoPopupWrapper) return;
  if(infoPopupWrapper.classList.contains('edit-mode') || infoPopupWrapper.classList.contains('info-popup-disabled')) {
    return;
  }

  const infoPopupMount = document.querySelector('.info-popup-mount');
  if(!infoPopupMount) return;


  const showOnce = infoPopupWrapper.getAttribute('data-showOnce') === 'true';
  const cookieName = `infoPopup_${infoPopupWrapper.getAttribute('data-cookieName')}_${infoPopupWrapper.getAttribute('data-staticUniqueId')}`;
  const cookieMaxAge = infoPopupWrapper.getAttribute('data-cookieMaxAge');
  const cookieConsentType = 'preferences';
  const cookieDefaultValue = 'true';

  const useCookie = showOnce && cookieName && window.getCookieValue !== undefined && window.setCookie !== undefined;

  if(useCookie && window.getCookieValue(cookieName) === 'true') {
    return;
  }

  $(infoPopupWrapper).hide();
  infoPopupMount.appendChild(infoPopupWrapper);
  $(infoPopupWrapper).hide();
  setTimeout(()=> {
    $(infoPopupWrapper).fadeIn(300);
    if(useCookie) {
      window.setCookie(cookieName, cookieDefaultValue, cookieConsentType, cookieMaxAge);
    }
  }, 500);

  const titleBarCloseButton = infoPopupWrapper.querySelector('.info-popup-titlebar > .close-button');
  const closeButton = infoPopupWrapper.querySelector('.info-popup-actionbar .info-popup-close-button');

  if(!titleBarCloseButton && !closeButton) {
    infoPopupWrapper.remove();
    return;
  }
  if(titleBarCloseButton) {
    titleBarCloseButton.addEventListener('click', () => {
      $(infoPopupWrapper).fadeOut(300);
    });
  }
  if(closeButton) {
    closeButton.addEventListener('click', () => {
      $(infoPopupWrapper).fadeOut(300);
    });
  }
})();
