$('.social-links-trigger').each(function(){
    var trigger = $(this);
        var link = trigger.find('a');
        var social = $('.social-links');
        var position = {
            top: ''
        };
        var align = trigger.data('align') || 'left';

    position[align] = '';

    link.on('click', function(ev){
        ev.preventDefault();

        var isOn = social.hasClass('toggle-on');

        if(isOn) {
            social.removeClass('toggle-on');
            position.top = '';
            position[align] = '';
            social.css(position);
        } else {
            social.find('a[data-target]').each(function() {
                var that = $(this);
                    var target = that.data('target');
                    var url = trigger.data('url-' + target);

                that.attr('href', url);
            });

            var offset = link.offset();
                var width = link.width();

            position.top = offset.top;
            position[align] = offset.left;

            if(align === 'right') {
            	if (window.innerWidth < 1019) {
            		position.right = '1';
            	} else {
            		position.right = 'calc(100% - ' + offset.left + 'px - ' + link.width() + 'px)';
            	}
            }

            console.log(position);

            social.css(position);

            social.addClass('toggle-on');
        }
    });
});
