
(function(){

    // Hint: IE11 Polyfill for String.prototype.startsWith()
    if(!String.prototype.startsWith) {
        // eslint-disable-next-line no-extend-native
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }

    var interfaceObj = null;

    function isAuthorized() {
        return $('.sizing-and-selection').data('anonymous') === false;
    }

    function productsValid(products) {
        return products && products.length && products.length > 0;
    }

    function addProductsInit($sourceElem, smartwaretechData) {
        if(!ppl) {
            console.error('Project list popup not available.');
            return;
        }
        if(!$sourceElem || !$sourceElem.length || $sourceElem.length === 0) {
            console.error('addProductsInit: Invalid source element!');
        }

        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }

        interfaceObj.resetState();
        interfaceObj.setSourceElem($sourceElem);

        var products = interfaceObj.convertSmartwaretechData(smartwaretechData);
        if(!productsValid(products)) {
            console.error('Failed to retrieve valid product data.');
        }
        return products;
    }

    function addProductsToCart($sourceElem, smartwaretechData) {

        var products = addProductsInit($sourceElem, smartwaretechData);
        if(!productsValid(products)) return;
        interfaceObj.setMode(interfaceObj.getModeEnum().ADD_TO_CART);
        interfaceObj.addProductsToCart(products);
    }
    function addProductsToProjects($sourceElem, smartwaretechData) {

        if(!isAuthorized()) {
            // Anonymous user can not add products to project-lists.
            addProductsToCart($sourceElem, smartwaretechData);
            return;
        }

        var products = addProductsInit($sourceElem, smartwaretechData);
        if(!productsValid(products)) return;
        interfaceObj.setMode(interfaceObj.getModeEnum().ADD_TO_PL);
        ppl.getInterfaceObj().openAddProducts($sourceElem, products);
    }

    function continueShopping() {
        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }
        window.location.href = interfaceObj.getContinueShoppingUrl();
    }

    function setIframeSize(width, height) {
        if(!width || !height) return;
        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }
        interfaceObj.setIframeSize(width, height);
    }

    function setScrollPosition(posY) {
        if(!posY) return;
        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }
        interfaceObj.setScrollPosition(posY);
    }

    function getExpectedOriginUrl() {
        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }
        return interfaceObj.getExpectedOriginUrl();
    }

    function extractProtocolAndHostName(url) {
        var urlParser = document.createElement('a');
        urlParser.href = url;
        return urlParser.protocol + '//' + urlParser.hostname;
    }
    function getExpectedOriginUrlProtocolAndHostName() {
        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }
        return interfaceObj.getExpectedOriginUrlProtocolAndHostName();
    }

    function init(langIsocode) {

        var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
        const currentUri = window && window.location.pathname ? window.location.pathname : '';
        
        let pathFragmentContinueShopping = 'actuators';
        if(currentUri.includes('actuator-sizing-and-selection')) {
            pathFragmentContinueShopping = 'actuators';
        }
        else if(currentUri.includes('sensor-selection')) {
            pathFragmentContinueShopping = 'sensors';
        }
        else if(currentUri.includes('sizing-and-selection')) {
            pathFragmentContinueShopping = 'valves';
        }
        const continueShoppingUri = $('.sas-container-iframe').attr('data-continue-shopping-uri');

        var consts = {
            addProductsEventName: 'add-products',
            urlCart: requestUriPrefix + '/shop/' + langIsocode + '/cart',
            urlProjectLists: requestUriPrefix + '/shop/' + langIsocode + '/my-account/project-list',
            urlAddProducts: requestUriPrefix + '/shop/' + langIsocode + '/cart/add-multiple',
            expectedOriginUrl: $('.sas-container-iframe').attr('data-origin-url'),
            continueShoppingUrl: requestUriPrefix + '/' + langIsocode + (continueShoppingUri === '' ? ('/products/' + pathFragmentContinueShopping) : continueShoppingUri)
        };
        consts.expectedOriginUrlProtocolAndHostName = extractProtocolAndHostName(consts.expectedOriginUrl);

        var modeEnum = {
            ADD_TO_CART: 'ADD_TO_CART',
            ADD_TO_PL: 'ADD_TO_PL'
        };
        function ModeValue(){
            this.mode = modeEnum.ADD_TO_CART;
        };
        ModeValue.prototype.isValid = function(mode) {
            return mode === modeEnum.ADD_TO_CART || mode === modeEnum.ADD_TO_PL;
        };
        ModeValue.prototype.setMode = function(mode) {
            if(!this.isValid(mode)) return;
            this.mode = mode;
        };
        var state = {
            products: [],
            newProjectName: null,
            selectedProjectNames: null,
            $sourceElem: null,
            modeValue: new ModeValue(),
            addingProducts: false,
            setProducts: function(products) {
                this.products = products;
            },
            setMode: function(mode) {
                this.modeValue.setMode(mode);
            },
            getMode: function() {
                return this.modeValue.mode;
            },
            setNewProjectName: function(newProjectName) {
                this.newProjectName = newProjectName;
            },
            setSelectedProjectNames: function(selectedProjectNames) {
                this.selectedProjectNames = selectedProjectNames;
            },
            hasSelectedProjectNames: function() {
                return this.selectedProjectNames &&
                    this.selectedProjectNames.length &&
                    this.selectedProjectNames.length > 0;
            },
            getNewOrSelectedProjectNamesText: function() {
                if(this.newProjectName) return this.newProjectName;
                if(!this.hasSelectedProjectNames()) return '';
                return this.selectedProjectNames.join(', ');
            },
            setSourceElem: function($sourceElem) {
                if(this.$sourceElem) {
                    this.$sourceElem.off(consts.addProductsEventName);
                }
                this.$sourceElem = $sourceElem;
                if(!$sourceElem) return;

                this.$sourceElem.on(consts.addProductsEventName, function(event, params, resultObj) {
                    state.setProducts(params.products);
                    state.setNewProjectName(params.newProjectName);
                    state.setSelectedProjectNames(params.selectedProjectNames);

                    addProductsRun(params, resultObj, false);
                });
            },
            setAddingProducts: function(addingProducts) {
                this.addingProducts = addingProducts;
            },
            reset: function() {
                this.products = [];
                this.newProjectName = null;
                this.selectedProjectNames = null;
                this.setSourceElem(null);
            },
            getExpectedOriginUrl: function() {
                return consts.expectedOriginUrl;
            },
            getExpectedOriginUrlProtocolAndHostName: function() {
                return consts.expectedOriginUrlProtocolAndHostName;
            }
        };
        var ui = {
            consts: {
                dataAttrGenericAddProductsErrorMsg: 'data-text-error-generic',
                dataAttrCartSuccess: 'data-text-cart-success',
                dataAttrProjectListSuccess: 'data-text-project-list-success',
                dataAttrProductQuantity: 'data-text-product-quantity',
                dataAttrViewCart: 'data-text-view-cart',
                dataAttrViewProjectLists: 'data-text-view-project-lists'
            },
            elems: {
                $errorMsgContainer: $('.sas-cfrm-view'),
                $errorDialog: $('.info-msgs-container'),
                $cfrmView: $('.sas-cfrm-view'),
                $cfrmViewContent: $('.sas-cfrm-view .sas-cfrm-view-content'),
                $cfrmNavButton: $('.sas-cfrm-view .sas-cfrm-view-nav'),
                $cfrmContinueButton: $('.sas-cfrm-view .sas-cfrm-view-continue'),
                $textContainer: $('.sas-cfrm-view'),
                $containerIframe: $('.sas-container-iframe'),
                $iFrame: $('.sas-container-iframe iframe')
            },
            getText: function(attrName) {
              return this.elems.$textContainer.attr(attrName);
            },
            getErrorText: function(attrName) {
                return this.elems.$errorMsgContainer.attr(attrName);
            },
            getGenericErrorMessage: function() {
                return this.getErrorText(this.consts.dataAttrGenericAddProductsErrorMsg);
            },
            getCfrmNavText: function(state) {

                switch(state.getMode()) {
                    case modeEnum.ADD_TO_CART: return this.getText(this.consts.dataAttrViewCart);
                    case modeEnum.ADD_TO_PL: return this.getText(this.consts.dataAttrViewProjectLists);
                    default: return '';
                }
            },
            getNavUrl: function(state) {
                switch(state.getMode()) {
                    case modeEnum.ADD_TO_CART: return consts.urlCart;
                    case modeEnum.ADD_TO_PL: return consts.urlProjectLists;
                    default: return '';
                }
            },
            getContinueShoppingUrl: function() {
                return consts.continueShoppingUrl;
            },
            showCfrmModal: function(state) {

                var _this = this;
                this.elems.$cfrmNavButton.text(this.getCfrmNavText(state));

                var buttons = [];
                buttons.push({
                    html: this.elems.$cfrmNavButton.get(0).outerHTML,
                    callback: function () {
                        Belimo.Modal.close();
                        if(_this.getNavUrl(state)) window.location.href = _this.getNavUrl(state);
                    }
                });
                buttons.push({
                    html: this.elems.$cfrmContinueButton.get(0).outerHTML,
                    callback: function () {
                        Belimo.Modal.close();
                    }
                });

                var $leadCart = this.elems.$cfrmViewContent.find('.sas-cfrm-view-content-lead-cart');
                var $leadPl = this.elems.$cfrmViewContent.find('.sas-cfrm-view-content-lead-pl');
                var $products = this.elems.$cfrmViewContent.find('.sas-cfrm-view-content-products');
                var $productProto = $('.sas-cfrm-view-content-product');

                $products.empty();
                for(var i = 0; i < state.products.length; i++) {
                    var $product = $productProto.clone();
                    var $productName = $product.find('.sas-cfrm-view-content-product-name');
                    var $productQty = $product.find('.sas-cfrm-view-content-product-qty');
                    var $productQtyTitle = $productQty.find('span');
                    var $productQtyAmount = $productQty.find('strong');
                    $productName.text(state.products[i].productName);
                    $productQtyTitle.text(this.getText(this.consts.dataAttrProductQuantity) + ':');
                    $productQtyAmount.text(state.products[i].qty);
                    $products.append($product);
                }

                if(state.getMode() === modeEnum.ADD_TO_CART) {
                    $leadPl.css('display', 'none');
                    $leadCart.css('display', 'block');
                    $leadCart.text(this.getText(this.consts.dataAttrCartSuccess) + '.');
                }
                else if(state.getMode() === modeEnum.ADD_TO_PL){
                    $leadCart.css('display', 'none');
                    $leadPl.css('display', 'block');
                    $leadPl.find('span').first().text(this.getText(this.consts.dataAttrProjectListSuccess) + ':');
                    $leadPl.find('span').last().text('🠚 ' + state.getNewOrSelectedProjectNamesText());
                }

                Belimo.Modal.open(this.elems.$cfrmViewContent, buttons);
            },
            setIframeSize: function(width, height) {
                // this.elems.$containerIframe.css('width', width + 'px');
                this.elems.$containerIframe.css('height', height + 'px');
            },
            setScrollPosition: function(posY) {
                $('body,html').scrollTop(posY + this.elems.$containerIframe.position().top);
            },
            initHandlers: function() {
                this.elems.$errorDialog.on('info-msgs-close', function() {
                    // Nothing currently
                });
            }
        };

        function addProductsRun(params, resultObj, showErrorMessage) {

            if(state.addingProducts) {
                return;
            }

            if(!resultObj) resultObj = {};
            var data = {
                addToCartForms: []
            };
            for(var i = 0; i < state.products.length; i++) {
                data.addToCartForms.push(state.products[i]);
            }

            if(state.newProjectName) {
                data.newProjectList = state.newProjectName;
            }
            if(state.hasSelectedProjectNames()) {
                data.projectLists = state.selectedProjectNames;
            }

            state.setAddingProducts(true);
            params.startCb();
            $.ajax({
                url: consts.urlAddProducts,
                method: 'POST',
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify(data)
            }).done(function(resultData) {
                state.setAddingProducts(false);

                if(resultData && resultData.errors && resultData.errors.length > 0) {
                    resultObj.errorMsg = resultData.errors[0].error;
                    resultObj.errorMsgs = resultData.errors.map(function(error) {
                        return error.error;
                    });
                }
                else{
                    resultObj.success = true;
                }
                params.doneCb(resultObj);
                if(resultObj.success) {
                    if(params.activeCartSelected || state.getMode() === modeEnum.ADD_TO_CART) {
                        Belimo.Cart.updateFromRemote(langIsocode);
                    }

                    ui.showCfrmModal(state);
                }
                else if(showErrorMessage){
                    ui.elems.$errorDialog.trigger('show-info-messages', [resultObj.errorMsgs, true]);
                }

            }).fail(function(error) {
                state.setAddingProducts(false);
                if(error) console.error(error);
                params.doneCb(resultObj);
                if(showErrorMessage) {
                    ui.elems.$errorDialog.trigger('show-info-messages', [[ui.getGenericErrorMessage()], true]);
                }
            });
        }

        function addProductsToCart(products) {
            var doneFn = function(resultObj) {
                if(resultObj.errorMsgs) {
                    ui.elems.$errorDialog.trigger('show-info-messages', [resultObj.errorMsgs, true]);
                }
            };
            var params = {
                products: products,
                doneCb: doneFn,
                startCb: function () {}
            };
            state.setProducts(products);

            addProductsRun(params, null, true);
        }


        function DataMapper() {
        }
        DataMapper.prototype.convertSmartwaretechDataToHybris = function(smartwaretechData) {


            // Doing a small sanity check because the origin of the data might be from a postmessage event.
            var dataIsValid = false;
            if(smartwaretechData && smartwaretechData.length && smartwaretechData.length >= 0) {
                for(var i = 0; i < smartwaretechData.length; i++) {
                    if( !smartwaretechData[i] ||
                        !smartwaretechData[i].productCode ||
                        (!smartwaretechData[i].productName && smartwaretechData[i].productName !== '') ||
                        !smartwaretechData[i].qty) {

                        break;
                    }
                }
                dataIsValid = true;
            }
            if(!dataIsValid) {
                console.error('Failed to convert data from sizing and selection tool.');
                return null;
            }

            // Hint: Smartwaretech data is already an array of products, therefore there is nothing to convert.
            return smartwaretechData;
        };
        function convertSmartwaretechData(smartwaretechData) {
            var dataMapper = new DataMapper();
            return dataMapper.convertSmartwaretechDataToHybris(smartwaretechData);
        }

        ui.initHandlers();

        return {
            addProductsToCart: addProductsToCart,
            setSourceElem: state.setSourceElem.bind(state),
            setMode: state.setMode.bind(state),
            getModeEnum: function() { return modeEnum; },
            convertSmartwaretechData: convertSmartwaretechData,
            resetState: state.reset.bind(state),
            setIframeSize: ui.setIframeSize.bind(ui),
            setScrollPosition: ui.setScrollPosition.bind(ui),
            getExpectedOriginUrl: state.getExpectedOriginUrl.bind(state),
            getExpectedOriginUrlProtocolAndHostName: state.getExpectedOriginUrlProtocolAndHostName.bind(state),
            getContinueShoppingUrl: ui.getContinueShoppingUrl.bind(ui)
        };
    }

    function receiveMessage(event) {
        var msg = event.data;
        if(!msg) return;

        // Hint: Only process events from the expected origin.
        if(!event.origin || !getExpectedOriginUrl() || !event.origin.startsWith(getExpectedOriginUrlProtocolAndHostName())) {
            return;
        }

        if(msg.continueShopping && (msg.continueShopping === true || msg.continueShopping === 'true')) {
            continueShopping();
        }
        else if(msg.width && msg.height) {
            setIframeSize(msg.width, msg.height);
        }
        else if(msg.posY) {
            setScrollPosition(msg.posY);
        }
        else if(msg.target && msg.target === 'cart' && msg.products) {
            addProductsToCart($(event.target), msg.products);
        }
        else if(msg.target && msg.target === 'projectLists' && msg.products) {
            addProductsToProjects($(event.target), msg.products);
        }
        else {
            console.warn('received an unrecognized message');
        }
    }

    window.addEventListener('message', receiveMessage);
})();

