/**
 * Modal Window Handling Class
 *
 */
Belimo.Modal = new function() {

	/**
	 * Open Modal Window
	 *
	 */
	this.open = function(content, buttons) {
		$(document).trigger('modal-on', [content, buttons]);
	};

	/**
	 * Close Modal Window
	 *
	 */
	this.close = function() {
		$(document).trigger('modal-off');
	};

	/**
	 * Update Modal Window
	 *
	 */
	this.update = function(content, buttons) {
		$(document).trigger('modal-update', [content, buttons]);
	};

	/**
	 * jQuery find inside Modal content
	 *
	 */
	this.find = function(selector) {
		return $('.modal .modal-content').find(selector);
	};
};
