$(function () {

	if ($('.loggedin').length) {

		var lang = (window && window.document.documentElement.lang ? window.document.documentElement.lang : '').replace('-', '_');
		var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
		var requestBaseUrl = requestUriPrefix + '/shop' + (lang !== '' ? ('/' + lang) : '');
		var requestUrl = requestBaseUrl + '/users/current';

		$.get(requestUrl, function (data) {
			if(data.links && data.links.length > 0) {

				const parents = document.querySelectorAll('.my-account-menu');
				for(let parent of parents) {
					
					let isFirstElement = true;
					const elements = [];
					for(let linkInfo of data.links) {
						
						const liElement = document.createElement('li');
						const aElement = document.createElement('a');
						const spanElement = document.createElement('span');

						liElement.classList.add('level-2');
						aElement.setAttribute('href', requestBaseUrl + linkInfo.link);
						spanElement.textContent = linkInfo.linkName;
						if(isFirstElement) {
							liElement.appendChild(document.createElement('br'));
							isFirstElement = false;
						}
						liElement.appendChild(aElement);
						aElement.appendChild(spanElement);
						elements.push(liElement);
					}
					parent.prepend(...elements);
				}
			}

			if (data.uid != 'anonymous') {
				$('.loggedin.panel-trigger span, .loggedin .panel-trigger span').html(function () {
					return $(this).html().replace('{0}', data.firstName + ' ' + data.lastName);
				});
				$('.loggedin.tab-trigger span').html(function () {
					return $(this).html().replace('{0}', data.firstName + ' ' + data.lastName);
				});
				$('.loggedin').attr('style', 'display:block !important');
				$('.loggedout').attr('style', 'display:none !important');
			}
		});
	}

});

(function () {

	var $globe = $('.choose-location-image-before');
	var $globeSvgPath = $globe.find('svg path');
	var $panel = $('.choose-location > div > .panel > .js-trigger');
	var hoverColor = '#ff6600'; // $orange
	var panelFontColorOrig = null;
	var globeColorOrig = '#787878'; // $dark-grey

	if (!$globe || !$panel || !$globeSvgPath) return;

	$globe.click(function () {
		$panel.trigger('click');
	});

	$globe.mouseenter(function () {
		if (!panelFontColorOrig) {
			panelFontColorOrig = $panel.css('color');
		}
		$panel.css('color', hoverColor);
		$globeSvgPath.css('fill', hoverColor);
	});
	$globe.mouseleave(function () {
		if (panelFontColorOrig != null) {
			$panel.css('color', panelFontColorOrig);
		}
		if (globeColorOrig) {
			$globeSvgPath.css('fill', globeColorOrig);
		}
	});
	$panel.mouseenter(function () {
		$globeSvgPath.css('fill', hoverColor);
	});
	$panel.mouseleave(function () {
		$globeSvgPath.css('fill', globeColorOrig);
	});

})();
