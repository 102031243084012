Belimo.Search.SEARCH_VIEW = 'search';

Belimo.Search.addView(Belimo.Search.SEARCH_VIEW, function(rootView) {
	var view = this;

	view.root = rootView;
	view.element = view.root.element.find('.search-field');

	view.searchTerm = ko.observable(view.element.find('input[type=text]').val());

	view.clear = function() {
		view.searchTerm('');
	};

	view.element.find('input[type=text]').focus(function() {
		view.element.next().show();
		$('.downloadcenter .subcategories').hide();
	});


});
