
function setFormActionIfInsideTab($form, tabId) {
	if(!$form || $form.length == 0) {
		return;
	}
	if(tabId) {
		$form.attr('action', '#' + tabId);
	}
	else {
		var $tab = $form.closest('.tab-contents').find('div.toggle-on.js-trigger');
		if($tab.length > 0 && tab.get(0).id) {
			$form.attr('action', '#' + $tab.get(0).id);
		}
	}
}

$('.form').each(function(){
	var form = $(this);
		var autosubmit = form.find('.form-input.autosubmit input, .form-input.autosubmit textarea, .form-input.autosubmit select');
		var formToggle = form.find('.form-input.form-toggle input:checkbox, .form-input.form-toggle input:radio, .form-input.form-toggle select');

	var firstLevelCheckBoxes = form.find('input:checkbox.firstLevelCheckBox');

	var rootCollapsible; var innerCollapsibles; var panels;
	var checkboxes; var firstLevelCheckbox; var secondLevelCheckboxesWrapper;
	var loopIndex; var loopIndexInner; var temp; var itemUUID;

	// Try to display the collapsibles uncollapsed if they contain checkboxes which are checked.

	innerCollapsibles = form.find('.collapsable.list-filter-form');
	checkboxes = innerCollapsibles.find('.firstLevelCheckBox, .secondLevelCheckBox');
	// If there are no checkboxes in none of the collapsibles, do nothing.
	if(checkboxes.length > 0) {
		// Search the collapsible which contains the inner collapsibles.
		rootCollapsible = null;
		panels = $('div.column.list-filter > .panel');
		var panelIndex;
		// check if this panel is really the panel we are looking for.
		for(loopIndex = 0; loopIndex < panels.length; ++loopIndex) {
			temp = panels.eq(loopIndex).find('.firstLevelCheckBox, .secondLevelCheckBox');
			if(temp.length > 0) {
				// Found the root collapsible, no need to look any further!
				rootCollapsible = panels.eq(loopIndex);
				break;
			}
		}

		// Uncollapse all collapsibles which contain at least one checked checkbox.
		// If any of the collapsibles contain a checked checkbox, uncollapse the root collapsible.
		for(loopIndex = 0; loopIndex < innerCollapsibles.length; ++loopIndex) {

			checkboxes = innerCollapsibles.eq(loopIndex).find('input:checkbox');
			for(loopIndexInner = 0; loopIndexInner < checkboxes.length; ++loopIndexInner) {
				if(checkboxes[loopIndexInner].checked) {
					$('.list').addClass('with-filter');
					innerCollapsibles.eq(loopIndex).addClass('toggle-on');
					if(rootCollapsible.length == 1) {
						rootCollapsible.eq(0).addClass('toggle-on');
					}
					continue;
				}
			}
		}
	}

	autosubmit.on('change', function(ev){
		firstLevelCheckbox = $(this);

		if(firstLevelCheckbox.length === 1) {

				firstLevelCheckbox = firstLevelCheckbox.get(0);

				if(firstLevelCheckbox.classList.contains('firstLevelCheckBox')) {

					if(!firstLevelCheckbox.checked) {
						itemUUID = firstLevelCheckbox.value;

						// Remove any associated second level checkboxes from the dom.
						secondLevelCheckboxesWrapper = form.find('.' + itemUUID);

						if(secondLevelCheckboxesWrapper.length === 1) {

							var isGenericList = secondLevelCheckboxesWrapper.closest('.generic-list').length > 0;
							if(!isGenericList) {
								secondLevelCheckboxesWrapper.remove();
							}
						}
					}
				}
		}
		form.submit();
	});


	formToggle.on('change', function(ev){
		var toggle = $(this);

		if(toggle.prop('checked')) {
			form.find('[data-form-toggle="' + toggle.attr('name') + '"]').each(function() {
				var that = $(this);
					var values = that.data('form-toggle-value').toString().split('|');
					var disable = that.data('form-toggle-disable');
					var matches = values.indexOf(toggle.val().toString()) !== -1;


				if(disable) {
					that.find(disable.toString().split('|').map(function(name){
						return '[name="' + name + '"]';
					}).join(',')).prop('disabled', !matches);
				}

				if(matches) {
					that.addClass('toggle-on');
				} else {
					that.removeClass('toggle-on');
				}
			});
		} else {
			if(toggle.is(':checkbox')) {
				form.find('[data-form-toggle="' + toggle.attr('name') + '"]').each(function() {
					var that = $(this);
						var values = that.data('form-toggle-value').toString().split('|');

					if(values.indexOf(toggle.val().toString()) !== -1) {
						that.removeClass('toggle-on');
					}
				});
			}
		}

		$(document).trigger('layout-changed');
	}).change();

	setFormActionIfInsideTab(form.find('form.regular-form'), null);
});


$('.tab-trigger').on('click', function() {
	var tabId = $(this).attr('id');
	$('.form').each(function(){
		setFormActionIfInsideTab($(this).find('form.regular-form'), tabId);
	});
});

