
var dateFormat = 'YYYY-MM-DD';
const pageDateFormatElem = document.querySelector('[data-date-format]');
if(pageDateFormatElem) {
    dateFormat = pageDateFormatElem.getAttribute('data-date-format').toUpperCase();
}
var daterange_en_US = {
    "format": dateFormat,
    "separator": " - ",
    "applyLabel": "Apply",
    "cancelLabel": "Cancel",
    "fromLabel": "From",
    "toLabel": "To",
    "customRangeLabel": "Custom",
    "weekLabel": "W",
    "daysOfWeek": [
        "Su",
        "Mo",
        "Tu",
        "We",
        "Th",
        "Fr",
        "Sa"
    ],
    "monthNames": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    "firstDay": 1
}


function isPastDate(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
}
function isWeekend(date) {
    if(date.getDay() == 6 || date.getDay() == 0) {
        return true;
    }
}

function isBlockedDate(day, month, year, blockedDates_YYYY_MM_DD) {

    blockedDates_YYYY_MM_DD = blockedDates_YYYY_MM_DD.trim();
    const blockedDatesArray = blockedDates_YYYY_MM_DD.split(' ');
    for(const blockedDateString of blockedDatesArray) {

        const dateRegex = /^([0-9]{4})_([0-9]{2})_([0-9]{2})$/;
        const match = blockedDateString.match(dateRegex);
        if(match.length !== 4) continue;

        if(match[3] == day && match[2] == month && match[1] == year) {
            return true; 
        }
    }
    return false;
}

function isAllowedDate(momentDate, blockedDates_YYYY_MM_DD, blockWeekends) {
    
    const date = momentDate.toDate();

    if(isPastDate(date)) return false;
    if(blockWeekends === true && isWeekend(date)) return false;
    if(blockedDates_YYYY_MM_DD) {
        if(isBlockedDate(momentDate.date(), momentDate.month() + 1, momentDate.year(), blockedDates_YYYY_MM_DD)) return false;
    }
    return true;
}


$('.form-input-date input').each(function(){
	var datepicker = $(this);

    const blockedDates_YYYY_MM_DD = $('#data-blocked-dates').attr('data-blocked-dates');
    const blockedWeekends = $('#data-blocked-dates').attr('data-block-weekends')
    datepicker.daterangepicker({
        "locale": daterange_en_US,
        singleDatePicker: true,
        showDropdowns: true,
        minYear: 2001,
        autoUpdateInput: false,
        isInvalidDate: function(date) {
            if(datepicker.closest('.form-input-date').hasClass('request-delivery-date')) {
                return !isAllowedDate(date, blockedDates_YYYY_MM_DD, blockedWeekends === 'true');
            }
            return false;
        }
    });


    // Introducing a companion field which gets used, if this date field is actually part of a
    // date range. If the range is invalid (e.g. startDate > endDate or endDate < startDate)
    // the companion will be cleared.
    var $companionDateField = $();
    var $parentForm = $(this).closest('form');
    if($parentForm.length === 1) {

        var fieldId = datepicker.attr('id');

        if(fieldId === 'dateStart' && $companionDateField.length === 0) {
            $companionDateField = $parentForm.find('input[id="dateEnd"]');
        }
        else if (fieldId === 'dateEnd' && $companionDateField.length === 0) {
            $companionDateField = $parentForm.find('input[id="dateStart"]');
        }
    }

    datepicker.on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format(dateFormat));

        if($(this).attr('id') === 'dateStart' && $companionDateField.length === 1 && $companionDateField.val()) {
            var companionDatePicker = $companionDateField.data('daterangepicker');
            if(companionDatePicker && companionDatePicker.startDate._d.getTime() < datepicker.data('daterangepicker').startDate._d.getTime()) {
                $companionDateField.val('');
            }
        }
        else if($(this).attr('id') === 'dateEnd' && $companionDateField.length === 1 && $companionDateField.val()) {
            var companionDatePicker = $companionDateField.data('daterangepicker');
            if(companionDatePicker && companionDatePicker.startDate._d.getTime() > datepicker.data('daterangepicker').startDate._d.getTime()) {
                $companionDateField.val('');
            }
        }
    });

    datepicker.on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

});

$('.form-input-date-range .date-range-fake-field').each(function(){
	var datepicker = $(this);

	if ($(this).closest('form').find('[name=toDate]').val() != "") {
		datepicker.html($(this).closest('form').find('[name=fromDate]').val() + " - " + $(this).closest('form').find('[name=toDate]').val());
	} else {
		datepicker.html($(this).closest('form').find('[name=fromDate]').val());
	}
	

	datepicker.daterangepicker({
        autoUpdateInput: false,
        autoApply: true,
		"locale": daterange_en_US,
	    showDropdowns: true,
	    minYear: 2001
	})
	
	datepicker.on('apply.daterangepicker' ,function(ev, picker) {
	
	    $(this).closest('form').find('[name=fromDate]').val(picker.startDate.format(dateFormat));
	    $(this).closest('form').find('[name=toDate]').val(picker.endDate.format(dateFormat));
	    
	    $(this).closest('form').find('.date-range-fake-field').html(picker.startDate.format(dateFormat) + " - " + picker.endDate.format(dateFormat));
	    $(this).closest('form').find('.date-range-fake-field').blur()
	})
	
	datepicker.on('show.daterangepicker', function(ev, picker) {

	    var startDate = $(this).closest('form').find('[name=fromDate]').val();
	    var endDate = $(this).closest('form').find('[name=toDate]').val();
	    
	    $('[name=daterangepicker_start]').val(startDate).trigger('change').trigger('keyup');
	    $('[name=daterangepicker_end]').val(endDate).trigger('change').trigger('keyup');
	});

    datepicker.click(function(e){
        e.preventDefault();
        return false;
    });

});

// See paymentTypeForm.tag (Hybris).
(function() {
    var paymentForm = document.getElementById('selectPaymentTypeForm');
    var requestedArrivalDate = document.querySelector('#selectPaymentTypeForm #requestedArrivalDate');
    var dateFormatDiv = document.getElementById('belimo-payment-form-date-format');

    if(paymentForm && requestedArrivalDate && dateFormatDiv) {
        var dateFormat = dateFormatDiv.getAttribute('data-date-format');
        if(dateFormat) {
            paymentForm.addEventListener('submit', function () {
                if(requestedArrivalDate.value) {
                    var formattedDate = moment(requestedArrivalDate.value, dateFormat.toUpperCase()).format('YYYY-MM-DD');
                    requestedArrivalDate.value = formattedDate;
                }
            });
        }
    }
}());

// See accountOrderHistoryPage.jsp (Hybris).
(function() {

    var dateFormatDiv = document.getElementById('belimo-order-history-form-date-format');

    var orderHistoryForm = document.getElementById('belimoOrderHistoryForm');
    var dateStart = document.querySelector('#belimoOrderHistoryForm #dateStart');
    var dateEnd = document.querySelector('#belimoOrderHistoryForm #dateEnd');
    

    if(orderHistoryForm && dateStart && dateEnd && dateFormatDiv) {
        var dateFormat = dateFormatDiv.getAttribute('data-date-format');
        if(dateFormat) {
            orderHistoryForm.addEventListener('submit', function () {
                if(dateStart.value) {
                    var formattedDateStart = moment(dateStart.value, dateFormat.toUpperCase()).format('YYYY-MM-DD');
                    dateStart.value = formattedDateStart;
                }
                if(dateEnd.value) {
                    var formattedDateEnd = moment(dateEnd.value, dateFormat.toUpperCase()).format('YYYY-MM-DD');
                    dateEnd.value = formattedDateEnd;
                }
            });
        }
    }
}());

// See accountSavedCartsPage.jsp (Hybris).
(function() {

    var dateFormatDiv = document.getElementById('belimo-account-saved-carts-date-format');
    var searchCriteriaForm = document.querySelector('.page-saved-carts #searchCriteriaForm');
    if(!searchCriteriaForm) return;
    var dateStart = searchCriteriaForm.querySelector('#fromDate');
    var dateEnd = searchCriteriaForm.querySelector('#toDate');
    

    if(searchCriteriaForm && dateStart && dateEnd && dateFormatDiv) {
        var dateFormat = dateFormatDiv.getAttribute('data-date-format');
        if(dateFormat) {
            searchCriteriaForm.addEventListener('submit', function () {
                
                if(dateStart.value) {
                    var formattedDateStart = moment(dateStart.value, dateFormat.toUpperCase()).format('YYYY-MM-DD');
                    dateStart.value = formattedDateStart;
                }
                if(dateEnd.value) {
                    var formattedDateEnd = moment(dateEnd.value, dateFormat.toUpperCase()).format('YYYY-MM-DD');
                    dateEnd.value = formattedDateEnd;
                }
            });
        }
    }
}());

// See addressChangeRequestPage.jsp (Hybris).
(function() {

    var dateFormatDiv = document.getElementById('belimo-account-change-address-date-format');
    var addressForm = document.querySelector('.page-editSAPAddressPage #addressForm');
    if(!addressForm) return;
    var effectiveDate = addressForm.querySelector('#addressForm\\.effectiveDate');
    
    if(addressForm && effectiveDate && dateFormatDiv) {
        var dateFormat = dateFormatDiv.getAttribute('data-date-format');
        if(dateFormat) {
            addressForm.addEventListener('submit', function () {
                
                if(effectiveDate.value) {
                    var formattedEffectiveDate = moment(effectiveDate.value, dateFormat.toUpperCase()).format('YYYY-MM-DD');
                    effectiveDate.value = formattedEffectiveDate;
                }
            });
        }
    }
}());


