$('.collapsable .collapsable-trigger.js-trigger, .collapsable .collapsable-icon.js-trigger').on('click', function(ev){
	ev.preventDefault();

	var that = $(this);
		var collapsable = that.closest('.collapsable');
		var isOn = collapsable.hasClass('toggle-on');
		var autoClose = collapsable.data('auto-close');
        var hiddenInput = collapsable.find('[name^="collapsible"]');

	if(isOn) {
		collapsable.removeClass('toggle-on');
        hiddenInput.attr('value', 'closed');
	} else {
		collapsable.addClass('toggle-on');
        hiddenInput.attr('value', 'open');
	}

	$(document).trigger('layout-changed');

});


$('.navigation-list .collapsable-trigger a').click(function(ev) {
    ev.preventDefault();
    if (window.innerWidth > 1019 || $(this).parents('.collapsable').hasClass('no-children')) {
        location.href = $(this).attr('href');
    }
});

$('.navigation-list .collapsable-trigger').click(function(ev) {
    ev.preventDefault();
    if (window.innerWidth > 1019 || $(this).parents('.collapsable').hasClass('no-children')) {
    } else {
    	var that = $(this);
    	setTimeout(function(){
	    	$([document.documentElement, document.body]).animate({
	            scrollTop: that.offset().top - 100
	        }, 250);
    	}, 500);
    }
});

$('.navigation-list.hybris .collapsable-trigger').click(function(ev) {
    ev.preventDefault();
    if (window.innerWidth < 1019) {
        var that = $(this);
            var collapsable = that.closest('.collapsable');
            var isOn = collapsable.hasClass('toggle-on');
            var autoClose = collapsable.data('auto-close');
        if(isOn) {
            collapsable.removeClass('toggle-on');
        } else {
            collapsable.addClass('toggle-on');
        }

        $(document).trigger('layout-changed');
	}
});
