
(function() {

    $('.collapsible2-nav').click(function(){

        var $collapsible2 = $(this).closest('.collapsible2');
        if($collapsible2.length == 1) {
            if($collapsible2.hasClass('collapsible2-content-hidden')) {
                $collapsible2.removeClass('collapsible2-content-hidden');
                $collapsible2.addClass('collapsible2-content-visible');
            }
            else {
                $collapsible2.removeClass('collapsible2-content-visible');
                $collapsible2.addClass('collapsible2-content-hidden');
            }
        }
    });

})();
