
(function() {

    // Special handling only for configurable products.
    function hideAddButtonIf() {
        if($('.pageType-ProductConfigPage').find('#cartItemPK').prop('value')) {
            $('.ppl-id-action-open').css('display', 'none');
        }
    }
    hideAddButtonIf();


    var interfaceObj = null;

    $('.ppl-id-action-open').click(function() {

        if(!ppl) {
            console.error('Project list popup not available.');
        }
        if(!interfaceObj) {
            interfaceObj = init(ppl.getInterfaceObj().getLangIsoCode());
        }
        interfaceObj.setSourceElem($(this));
        var product = interfaceObj.createProduct($(this));
        if(!product || !product.isValid()) {
            console.error('Failed to retrieve valid product data. Can not open product list popup.');
        };
        ppl.getInterfaceObj().openAddProduct($(this), product);
    });

    function init(langIsocode) {

        function DesignData(code, text) {
            this.code = code;
            this.text = text;
        }

        function Product(id, name, desc, price, discountPrice, url, $img, quantity, designData, configurable, ignorePrice) {

            this.id = id;
            this.name = name;
            this.desc = desc;
            this.price = price;
            this.discountPrice = discountPrice;
            this.url = url;
            this.$img = $img.clone();
            this.quantity = Number(quantity);
            this.designData = designData;
            this.configurable = configurable;
            this.ignorePrice = ignorePrice;
        }

        Product.prototype.isValid = function() {

            return this.id && this.id !== ''
                && this.name && this.name !== ''
                && (this.ignorePrice || (this.price && this.price !== ''))
                && this.$img && this.$img.length > 0
                && !isNaN(this.quantity) && this.quantity > 0 && this.quantity < 999;
        };
        Product.create = function($addProductLink) {

            var quantity = Number($addProductLink.closest('form').find('input[name="qty"]').val());
            var configurable = false;

            // For PDP of configurable product!
            if($addProductLink.hasClass('ppl-product-configurable')) {
                if(isNaN(quantity)) {
                    quantity = $('#quantityInputValue').val();
                }
                configurable = true;
            }

            var $productInfo = $addProductLink.find('.ppl-id-product-info');

            var designDataCode = $productInfo.attr('data-design-data-code');
            var designDataContent = $productInfo.attr('data-design-data-content');
            var designData = (designDataCode && designDataContent)
                ? new DesignData(designDataCode, designDataContent)
                : null;
            var ignorePriceData = $productInfo.attr('data-product-ignore-price');

            var product = new Product(
                $productInfo.attr('data-product-code'),
                $productInfo.attr('data-product-name'),
                $productInfo.attr('data-product-description'),
                $productInfo.attr('data-product-price'),
                $productInfo.attr('data-product-discount-price'),
                $productInfo.attr('data-product-url'),
                $productInfo.find('img'),
                quantity,
                designData,
                configurable,
                ignorePriceData && ignorePriceData == 'true'
            );

            return product.isValid()
                ? product
                : null;
        };

        var requestUriPrefix = window && window.countryCode ? window.countryCode : '';

        var consts = {
            addProductEventName: 'add-product',
            urlProductAdd: requestUriPrefix + '/shop/' + langIsocode + '/project-lists/add',
            urlProjectLists: requestUriPrefix + '/shop/' + langIsocode + '/my-account/project-list',
            urlDuplicateConfigurable: requestUriPrefix + '/shop/' + langIsocode + '/copy',
            urlConfigProductAdd: requestUriPrefix + '/shop/' + langIsocode + '/add-config-project-list'
        };

        var state = {

            product: null,
            newProjectName: null,
            selectedProjectNames: null,
            $sourceElem: null,
            setProduct: function(product) {
                this.product = product;
            },
            setNewProjectName: function(newProjectName) {
                this.newProjectName = newProjectName;
            },
            setSelectedProjectNames: function(selectedProjectNames) {
                this.selectedProjectNames = selectedProjectNames;
            },
            reset: function() {
                this.product = null;
                this.newProjectName = null;
                this.selectedProjectNames = null;
                if(this.$sourceElem) {
                    this.$sourceElem.off(consts.addProductEventName);
                }
                this.$sourceElem = null;
            },
            setSourceElem: function($sourceElem) {

                if(this.$sourceElem) {
                    this.$sourceElem.off(consts.addProductEventName);
                }
                this.$sourceElem = $sourceElem;

                this.$sourceElem.on(consts.addProductEventName, function(event, params, resultObj){

                    // Hint: See action_addToProjects in projectListPopup.js for information about
                    // events, params and resultObj.

                    state.setProduct(params.product);
                    state.setNewProjectName(params.newProjectName);
                    state.setSelectedProjectNames(params.selectedProjectNames);

                    var data = {
                        productCode: state.product.id,
                        quantity: state.product.quantity,
                        newName: state.newProjectName ? params.newProjectName : null,
                        savedCartsList: state.selectedProjectNames.length > 0 ? state.selectedProjectNames : null,
                    };
                    if(state.product.designData) {
                        data.designData = state.product.designData.code;
                    }

                    var postRequest = null;
                    params.startCb();

					if(state.product.configurable) {
                        data.savedCartsList = state.selectedProjectNames;

                        var configFormData = {};
                        var $configFormElem = $('#configform');
                        if($configFormElem.length == 1) {
                            var elements = $configFormElem.get(0).elements;
                            for(var i = 0; i < elements.length; i++){
                                if(elements.item(i).value) {
                                    configFormData[elements.item(i).name] = elements.item(i).value;
                                }
                            }
                        }

                        data.configurationData = configFormData;

                        postRequest = $.ajax({
                            url: consts.urlConfigProductAdd,
                            dataType: 'json',
                            method: 'POST',
                            contentType: 'application/json; charset=utf-8',
                            data: JSON.stringify(data)
                        });
                    }
                    else {
                        postRequest = $.ajax({
                            url: consts.urlProductAdd,
                            dataType: 'json',
                            method: 'POST',
                            contentType: 'application/json; charset=utf-8',
							data: JSON.stringify(data)
                        });
                    }
                    postRequest
                        .done(function(resultData) {

                            if(resultData && resultData.errors && resultData.errors.length > 0) {
                                resultObj.errorMsg = resultData.errors[0].error;
                                resultObj.errorMsgs = resultData.errors.map(function(error) {
                                    return error.error;
                                });
                            }
                            else {
                                resultObj.success = true;
                            }
                            params.doneCb(resultObj);
                            if(resultObj.success) {
								if(params.activeCartSelected) {
									Belimo.Cart.updateFromRemote(langIsocode);
								}
                                ui.showCfrmModal(state.product.id, state.product.designData);
                            }
                        }).fail(function(error) {
                            console.error(error);
                            params.doneCb(resultObj);
                        });
                });
            }
        };

        var ui = {

            consts: {
                dataAttrDynamicText: 'data-ppl-id-text',
                classNameButtonProjectLists: 'ppl-id-action-view-project-list',
                classNameButtonContinue: 'ppl-id-action-continue-shopping',
                classNameButtonDuplicateConfigurable: 'ppl-id-action-duplicate-configurable'
            },
            elems: {
                $popupCfrm: $('.ppl-cfrm'),
                $popupCfrmText: $('.ppl-cfrm-lead-text'),
                $popupCfrmProduct: $('.ppl-cfrm-product'),
                $pplCfrmImg: $('.ppl-cfrm-product-img'),
                $pplCfrmProductInfo: $('.ppl-cfrm-product-info'),
                $pplCfrmProductTitle: $('.ppl-cfrm-product-title'),
                $pplCfrmProductDesc: $('.ppl-cfrm-product-desc'),
                $pplCfrmProductPrice: $('.ppl-cfrm-product-price'),
                $pplCfrmProductListPrice: $('.ppl-cfrm-product-list-price'),
                $pplCfrmProductYourPrice: $('.ppl-cfrm-product-your-price'),
                $pplCfrmProductQuantity: $('.ppl-cfrm-product-quantity'),
                $pplCfrmDesignDataContent: $('.ppl-cfrm-design-data-content'),
            },
            initHandlers: function() {

                this.elems.$pplCfrmImg.click(function () {
                    Belimo.Modal.close();
                    var url = state.product.url;
                    state.reset();
                    window.location.href = url;
                });

                this.elems.$pplCfrmProductTitle.click(function () {
                    Belimo.Modal.close();
                    var url = state.product.url;
                    state.reset();
                    window.location.href = url;
                });
            },

            showCfrmModal: function(productId, designData) {

                this.applyProductData();

                var $popupCfrmClone = $('.ppl-cfrm').clone(true);
                $popupCfrmClone.css('display', 'block');

                var buttons = [];
                buttons.push({
                    html: $popupCfrmClone.find('.' + this.consts.classNameButtonProjectLists).get(0).outerHTML,
                    callback: function () {
                        state.reset();
                        Belimo.Modal.close();
                        window.location.href = consts.urlProjectLists;
                    }
                });

                // For configurable products
                if(state.product.configurable) {

                    buttons.push({
                        html: $popupCfrmClone.find('.' + this.consts.classNameButtonDuplicateConfigurable).get(0).outerHTML,
                        callback: function () {
                            state.reset();
                            Belimo.Modal.close();
                            window.location.href = consts.urlDuplicateConfigurable
                                + '?code=' + encodeURIComponent(productId)
                                + '&url=/config?code=' + encodeURIComponent(productId)
                                + (designData ? ('&designData=' + designData) : '');
                        }
                    });
                }

                buttons.push({
                    html: $popupCfrmClone.find('.' + this.consts.classNameButtonContinue).get(0).outerHTML,
                    callback: function () {
                        state.reset();
                        Belimo.Modal.close();
                    }
                });

                Belimo.Modal.open($popupCfrmClone, buttons);
            },

            applyProductData: function() {

                var updatedProjectList = state.newProjectName
                    ? state.newProjectName
                    : state.selectedProjectNames.join(', ');

                this.elems.$popupCfrmText.text(
                    this.elems.$popupCfrmText.attr(this.consts.dataAttrDynamicText)
                    + ' ' + updatedProjectList);

                this.elems.$pplCfrmImg.find('img').remove();
                this.elems.$pplCfrmImg.append(state.product.$img);

                this.elems.$pplCfrmProductTitle.text(state.product.name);
                this.elems.$pplCfrmProductDesc.html(state.product.desc);

                if(state.product.ignorePriceData) {
                    this.elems.$pplCfrmProductListPrice.text(
                        this.elems.$pplCfrmProductListPrice.attr(this.consts.dataAttrDynamicText)
                        + ' ' + state.product.price);
                }

                this.elems.$pplCfrmProductYourPrice.text('');
                if (state.product.discountPrice) {
                    this.elems.$pplCfrmProductYourPrice.text(
                        this.elems.$pplCfrmProductYourPrice.attr(this.consts.dataAttrDynamicText)
                        + ' ' + state.product.discountPrice);
                }
                this.elems.$pplCfrmProductQuantity.text(
                    this.elems.$pplCfrmProductQuantity.attr(this.consts.dataAttrDynamicText)
                    + ' ' + state.product.quantity);

                if(state.product.designData) {
                    this.elems.$pplCfrmDesignDataContent.find('span').text(' ' + state.product.designData.text);
                    this.elems.$pplCfrmDesignDataContent.show();
                }
                else {
                    this.elems.$pplCfrmDesignDataContent.hide();
                }
            }
        };
        ui.initHandlers();

        return {
            createProduct: Product.create,
            setSourceElem: state.setSourceElem.bind(state)
        };
    }
})();
