
$('a.share-list-by-email').on('click', function(e){
	var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
	var language = $(this).data('language');
	var cartCode = $(this).data('cartcode');
	var requestUrl = requestUriPrefix + '/shop/'+language+"/my-account/project-list/"+cartCode+"/share-ajax";
	
    $.get(requestUrl, function(result) {

        window.location.href = 'mailto:?subject=' + result.emailSubject + '&body=' + result.emailBody;
    });
	return false;
});