$('.collapsable.navigation-list.mgnl-navigation.with-icon').each(function(){
	$(this).removeClass('toggle-on');
});

$('.navigation').each(function(){

	var nav = $(this);
		var collapsables = nav.find('.collapsable');

	nav.find('.collapsable-trigger.js-trigger').on('click', function() {
		collapsables.not($(this).closest('.collapsable')).removeClass('toggle-on');
	});

    nav.find('.navigation-column').on('mouseover', function() {
        if (window.innerWidth > 1019) {
            $(this).find('.collapsable').not($(this).closest('.collapsable')).addClass('toggle-on');
        }
    });
    nav.find('.navigation-column').on('mouseout', function() {
        if (window.innerWidth > 1019) {
            $(this).find('.collapsable').not($(this).closest('.collapsable')).removeClass('toggle-on');
        }
	});
});
