function AssetsModel (downloadModel, data) {
	var model = this;

	model.description = data.description;
	model.title = data.title;
	model.fileType = data.fileType;
	model.discontinuedProduct = data.discontinuedProduct;

}

function DownloadModel(element) {
	var model = this;

	model.element = element;
	model.entries = ko.observableArray([]);
	model.lineItems = ko.observable(0);
	model.downloadFolderHomePageUrl = ko.observable('');

	model.updateCart = function(entries) {
		model.entries.removeAll();

		for(var i = 0; i < entries.length; i++) {
			model.entries.push(new AssetsModel(model, entries[i]));
		}
	};
	model.setDownloadFolderHomePageUrl = function(downloadFolderHomePageUrl) {
		model.downloadFolderHomePageUrl(downloadFolderHomePageUrl);
	};
};

$(function(){
	$('.download-widget').each(function() {
		var download = $(this);
			// triggerLabel = cart.closest('.panel-content').prev('.panel-trigger').find('> span[data-bind]'),
			var model = new DownloadModel(download);

		ko.applyBindings(model, download.closest('.panel-download').get(0));
		// ko.applyBindings(model, triggerLabel.get(0));

		$(document).on('downloadfolder-update', function(ev, assets) {
			model.updateCart(assets);
            model.lineItems(assets.length);
		});

		var $downloadIconWithLink = $('div[data-body-class="with-navigation with-download"]');
		$downloadIconWithLink.css('pointer-events', 'none');
		$downloadIconWithLink.css('opacity', 0.5);

		$.ajax({
			method: 'GET',
			url: download.data('download-url'),
			dataType: 'json',
			success: function(response) {
				Belimo.Downloadfolder.update(response.entries || []);
				model.updateCart(response.entries || []);
                model.lineItems(response.miniDownloadFolderCount);
				if(response.downloadFolderHomePageUrl) {
					model.setDownloadFolderHomePageUrl(response.downloadFolderHomePageUrl);
				}
				$downloadIconWithLink.css('pointer-events', 'auto');
				$downloadIconWithLink.css('opacity', 1.0);
			},
			error: function(response) {
				if(response && response.responseText) {
					console.error(download.data('download-url'), response.responseText);
				}
			}
		});

	});
});
