'use strict';


/**
 * Fires request for select2
 *  - takes DATA-ARV-AJAX-URL, DATA-ARV-AJAX-PARAMTERNAME and DATA-ARV-AJAX-VALUE and queries with get
 *          DATA-ARV-AJAX-URL?DATA-ARV-AJAX-PARAMTERNAME=DATA-ARV-AJAX-VALUE
 *  - it expects are result-array from structure
            result-array = [ ..., { ..., DATA-ARV-ID-NAME : %id_for_select%, DATA-ARV-TEXT-NAME : %text_for_selct% , ... },...]
        the extracted infos are then asigend to select2
 */
function selectInit(selectedCountry) {
    $('select[data-arv-ajax-url]').each(function () {


        var $t = $(this);
        var url = $t.data('arv-ajax-url');
        var parameterName = $t.data('arv-parameter-name');
        var parameterValue = $t.data('arv-parameter-value');
        var idName = $t.data('arv-id-name');
        var textName = $t.data('arv-text-name');
        var selectedValue = $t.data('arv-selected-value');
        var selectOptions = [];


        var extractValues = function(data)
        {
            selectOptions = [];
            for(var i=0; i<data.length; i++)
            {
                if (data[i][idName] && data[i][textName])
                {
                    var id= data[i][idName];
                    var text = data[i][textName];
                    selectOptions.push({id:id, text:text});
                }
            }
        };

        var regionBaseURL = ACC.config.encodedContextPath+'/common/regions-for-countries/';
        $.getJSON(regionBaseURL, function( data ) {
            if (data[parameterValue] != undefined) {
                $('#stateRegionSelector').show();
                $t.prop('disabled', false);
            } else {
                $('#stateRegionSelector').hide();
                $t.prop('disabled', true);
                $t.select2().select('');
            }
        });

        if(parameterValue === undefined) parameterValue = selectedCountry;

        $.get(url, parameterName+'='+parameterValue, function(data)
        {

            extractValues(data);
            $t.select2({data:selectOptions});


            if ( selectedValue)
            {

                $t.select2().val(selectedValue).trigger('change');
            }
        });
        // ignore-suggestions-country button display
        const ignoreSuggestionsUrl = ACC.config.encodedContextPath + '/common/ignore-suggestions-country-ajax';
        if(parameterValue) {
            $.ajax({
                url: ignoreSuggestionsUrl,
                method: 'GET',
                data: {
                    countryIso: parameterValue
                }
            }).done(function(ignoreSuggestions) {
                if ( "enabled" === ignoreSuggestions ) {
                    // show ignore button
                    $('.address-edit-ignore').show();
                } else {
                    $('.address-edit-ignore').hide();
                }
            }).fail(function(xhr) {
                console.error(xhr.responseText);
            });
        }
    });
};
selectInit();
export { selectInit };



