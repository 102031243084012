
(function() {

    var $individualTaggingContentElems = $('.individual-tagging-content');

    if($individualTaggingContentElems.length > 0) {
        init();
    }

    function init() {

        function getArray(length) {
            return new Array(length).map(function() {
               return '';
            });
        }

        var $popupTriggers = $('.cart-tag-form input[name="tagging_type"][value="individual"]');
        var $popupWrapper = $('#popup-id-individual-tagging');
        var $container = $popupWrapper.find('.individual-tagging-container');

        var state = {

            entryNumber: null,
            language: null,
            tagsOrig: null,
            tagsOrigInvalid: false,
            tags: [],
            updateAreaLines: [],
            hasNonEmptyInvalidLines: false,
            updateAreaText: null,

            init: function($individualTaggingContent) {
                this.entryNumber = $individualTaggingContent.attr('data-entry-number');
                this.tags = getArray($individualTaggingContent.find('.tagging-table-row').length);
                var origTags = $individualTaggingContent.attr('data-current-tags');
                this.tagsOrig = origTags
                    ? origTags.split('$#,-,#$')
                    : getArray(this.tags.length);
                if(this.tags.length !== this.tagsOrig.length) {
                    this.tagsOrig.length = this.tags.length;
                    for(var i = 0; i < this.tags.length; i++) {
                        if(!this.tagsOrig[i]) this.tagsOrig[i] = '';
                        else this.tags[i] = this.tagsOrig[i];
                    }
                    this.tagsOrigInvalid = true;
                }
                else if(this.tags.length === this.tagsOrig.length) {
                    this.tags = this.tagsOrig.slice();
                }
                this.setUpdateAreaLines('');
                this.language = $individualTaggingContent.attr('data-language');
            },
            setUpdateAreaLines: function(areaText) {
                if(this.updateAreaText === areaText) return;
                this.updateAreaText = areaText;
                this.hasNonEmptyInvalidLines = false;
                if(!areaText) {
                    this.updateAreaLines = [];
                }
                else {
                    var lines = areaText.replace(/\r/ig, '').split('\n');
                    this.updateAreaLines = lines.filter(function(line) {
                       if(!line) return false;
                       return line.trim().length > 0;
                    });
                    for(var i = 0; i < this.updateAreaLines.length; i++) {
                        if(this.updateAreaLines[i].length > 20) {
                            this.hasNonEmptyInvalidLines = true;
                            break;
                        }
                    }
                }
            },
            setTag: function(tagIndex, tagText) {
                if(this.tags[tagIndex] === tagText) return;
                this.tags[tagIndex] = tagText;
            },

            setAllTags: function(tagText) {
                for(var i = 0; i < this.tags.length; i++) {
                    this.tags[i] = tagText;
                }
            },

            isComplete: function() {
                for(var i = 0; i < this.tags.length; ++i) {
                    if(!this.tags[i] || !this.tags[i].trim()) return false;
                }
                return true;
            },
            allEmpty: function() {
                for(var i = 0; i < this.tags.length; ++i) {
                    if(this.tags[i] && this.tags[i].length > 0) return false;
                }
                return true;
            },
            reset: function() {
                this.tags = getArray(this.tags.length);
            },
            hasChange: function() {
                if(this.tagsOrigInvalid) return true;
                for(var i = 0; i < this.tags.length; i++) {
                    if(this.tags[i] !== this.tagsOrig[i]) return true;
                }
                return false;
            },
            areaTextApplicable: function() {
                if(this.hasNonEmptyInvalidLines) return false;
                return this.updateAreaLines.length === 1 ||
                    this.updateAreaLines.length === this.tags.length;
            },
            areaTextToManyLines: function() {
              return this.updateAreaLines.length > this.tags.length;
            }
        };

        var ui = {

            elems: {
                $openTrigger: null,
                $individualTaggingContent: null,
                $tagListUpdateButton: null,
                $tagListImportArea: null,
                $tagInputs: [],
                $applyButton: null,
                $cancelButton: null,
                $resetLink: null,
                $form: null,
                $tagsLengthInvalid: null,
                $tagsAmountToMany: null,
                $tagsAmountToSmall: null,
            },

            initHandlers: function() {
                var _this = this;

                function closePopup() {
                    _this.elems.$openTrigger.prop('checked', false);
                    $popupWrapper.trigger('popup-close');
                }

                this.elems.$tagListImportArea.on('input', function() {
                    state.setUpdateAreaLines($(this).val());
                    _this.draw();
                });
                this.elems.$tagInputs.on('input', function() {
                    state.setTag($(this).attr('data-tag-nr'), $(this).val());
                    _this.draw();
                });
                this.elems.$applyButton.on('click', function(e) {

                    var locPrefix = window && window.countryCode ? window.countryCode : '';
                    var locUrl = locPrefix + '/shop/' + state.language + '/cart/remove-tags/?entryNumber=' + state.entryNumber;

                    e.preventDefault();
                    if(state.allEmpty()) {
                        window.location.href = locUrl;
                    }
                    else {
                        _this.elems.$form.submit();
                    }
                    return false;
                });
                this.elems.$cancelButton.on('click', function() {
                    closePopup();
                });
                this.elems.$resetLink.on('click', function() {
                    state.reset();
                    _this.draw();
                });

                $('.popup-id-individual-tagging-action-close').on('click', function() {
                    closePopup();
                });

                this.elems.$tagListUpdateButton.on('click', function() {
                    if(state.updateAreaLines.length === 1 && state.updateAreaLines.length > 0) {
                        state.setAllTags(state.updateAreaLines[0]);
                    }
                    else if(state.updateAreaLines.length === state.tags.length) {
                        for(var i = 0; i < state.tags.length; i++) {
                            state.setTag(i, state.updateAreaLines[i]);
                        }
                    }
                    _this.draw();
                });
            },
            init: function($individualTaggingContent, $openTrigger) {
                $individualTaggingContent = $individualTaggingContent.clone(true, true);
                $container.html($individualTaggingContent);

                this.elems.$openTrigger = $openTrigger;
                this.elems.$individualTaggingContent = $individualTaggingContent.clone(true, true);
                this.elems.$tagListUpdateButton = $individualTaggingContent.find('.tag-list-update button');
                this.elems.$tagListImportArea = $individualTaggingContent.find('.tag-list-import textarea');
                this.elems.$tagInputs = $individualTaggingContent.find('.tagging-table-row input');
                this.elems.$form = $individualTaggingContent.find('form');

                this.elems.$applyButton = $individualTaggingContent.find('.tagging-apply');
                this.elems.$cancelButton = $individualTaggingContent.find('.tagging-cancel');
                this.elems.$resetLink = $individualTaggingContent.find('.tagging-reset');

                this.elems.$tagsLengthInvalid = $individualTaggingContent.find('.tags-length-invalid');

                this.elems.$tagsAmountToMany = $individualTaggingContent.find('.tags-amount-invalid-to-many');
                this.elems.$tagsAmountToSmall = $individualTaggingContent.find('.tags-amount-invalid-to-small');

                this.elems.$tagListImportArea.val('');

                if(state.tags.length > 5) {
                    this.elems.$tagListImportArea.attr('rows', state.tags.length);
                }
            },
            openPopup: function() {
                $popupWrapper.trigger('popup-open', []);
            },
            draw: function() {

                if(state.areaTextApplicable()) {
                    ui.elems.$tagListUpdateButton.prop('disabled', false);

                    ui.elems.$tagsLengthInvalid.css('display', 'none');
                    ui.elems.$tagsAmountToMany.css('display', 'none');
                    ui.elems.$tagsAmountToSmall.css('display', 'none');
                }
                else {
                    ui.elems.$tagListUpdateButton.prop('disabled', true);

                    if(!state.updateAreaText) {
                        ui.elems.$tagsLengthInvalid.css('display', 'none');
                        ui.elems.$tagsAmountToMany.css('display', 'none');
                        ui.elems.$tagsAmountToSmall.css('display', 'none');
                    }
                    else if(state.hasNonEmptyInvalidLines) {
                        ui.elems.$tagsAmountToMany.css('display', 'none');
                        ui.elems.$tagsAmountToSmall.css('display', 'none');
                        ui.elems.$tagsLengthInvalid.css('display', 'inline');
                    }
                    else if(!state.areaTextToManyLines()) {
                        ui.elems.$tagsLengthInvalid.css('display', 'none');
                        ui.elems.$tagsAmountToMany.css('display', 'none');
                        ui.elems.$tagsAmountToSmall.css('display', 'inline');
                    }
                    else {
                        ui.elems.$tagsLengthInvalid.css('display', 'none');
                        ui.elems.$tagsAmountToMany.css('display', 'inline');
                        ui.elems.$tagsAmountToSmall.css('display', 'none');
                    }
                }

                (state.hasChange() && (state.isComplete() || state.allEmpty()))
                    ? ui.elems.$applyButton.prop('disabled', false)
                    : ui.elems.$applyButton.prop('disabled', true);

                state.allEmpty()
                    ? ui.elems.$resetLink.addClass('disabled')
                    : ui.elems.$resetLink.removeClass('disabled');

                for(var i = 0; i < state.tags.length; i++) {
                    ui.elems.$tagInputs.eq(i).val(state.tags[i]);
                }
            }
        };

        $popupTriggers.each(function() {

            $(this).on('click', function() {
                var $individualTaggingContent = $(this).closest('.cart-tagging').find('.individual-tagging-content');
                state.init($individualTaggingContent);
                ui.init($individualTaggingContent, $(this));
                ui.initHandlers();
                ui.draw();
                ui.openPopup();
            });
        });
    }

})();
