
/////////////////////////////////////////
// Snippet provided by MovingImage
const UserConsent = {
  Given: 'CONSENT_GIVEN',
  Declined: 'CONSENT_DECLINED'
};
const USER_CONSENT_ITEM = 'movingimage.player.user_consent';
function getConsentValue() {
  try {
    return localStorage.getItem(USER_CONSENT_ITEM);
  } catch (error) {
    return '';
  }
}
function userConsentNotSet() {
  var consentValue = getConsentValue();
  return (
    consentValue !== UserConsent.Given && consentValue !== UserConsent.Declined
  );
}
function userDeclinedConsent() {
  return getConsentValue() === UserConsent.Declined;
}
function userGaveConsent() {
  return getConsentValue() === UserConsent.Given;
}
function setHasConsent() {
  try {
    localStorage.setItem(USER_CONSENT_ITEM, UserConsent.Given);
  } catch (error) {
    console.warn('Local storage not available. User consent was not set.');
  }
}
function setDeclinedConsent() {
  try {
    localStorage.setItem(USER_CONSENT_ITEM, UserConsent.Declined);
  } catch (error) {
    console.warn('Local storage not available. User consent was not set.');
  }
}
// End snippet provided by MovingImage
/////////////////////////////////////////


const hasCookiebotConsent = cookieTypeConsent => {
  return window !== undefined &&
    window.Cookiebot !== undefined &&
    window.Cookiebot.consent !== undefined &&
    window.Cookiebot.consent[cookieTypeConsent] !== undefined &&
    window.Cookiebot.consent[cookieTypeConsent] === true;
}

const cookiebotConsentType = 'statistics';
function setMovingImageAnalytics() {
  hasCookiebotConsent(cookiebotConsentType)
    ? setHasConsent()
    : setDeclinedConsent();
}

window.addEventListener("CookiebotOnConsentReady", function() {
  setMovingImageAnalytics();
});
window.addEventListener("CookiebotOnAccept", function() {
  setMovingImageAnalytics();
});
window.addEventListener("CookiebotOnDecline", function() {
  setMovingImageAnalytics();
});
const firstVideoWrapper = document.querySelector('.video-moving-image-wrap');
if(firstVideoWrapper) {
  
  function addMovingImageScript() {
    const scriptUrl = 'https://e.video-cdn.net/v2/embed.js'
    const scriptTagExists = document.querySelector(`script[src="${scriptUrl}"]`) !== null;
    if(!scriptTagExists) {
      var scriptElement = document.createElement('script');
      scriptElement.setAttribute('type', 'application/javascript');
      scriptElement.setAttribute('src', scriptUrl);
      scriptElement.setAttribute('data-cookieconsent', 'ignore');
      scriptElement.setAttribute('async', 'true');
      document.body.appendChild(scriptElement);
    }   
  }
  addMovingImageScript();


  const videoWrappers = document.getElementsByClassName('video-moving-image-wrap');
  if(videoWrappers.length > 0) {
    
    function showCookiebotBanner() {
      if(window !== undefined && window.Cookiebot !== undefined && window.Cookiebot.renew !== undefined) {
        window.Cookiebot.renew();
      }
    }
    const showBannerListener = (event) => {
      event.stopPropagation();
      showCookiebotBanner();
    };
  
    window.addEventListener("CookiebotOnAccept", function() {
      Array.from(videoWrappers).forEach((videoWrapper) => {
        if(hasCookiebotConsent(cookiebotConsentType)) {
          videoWrapper.removeEventListener('click', showBannerListener, true);
        } 
      });
    });
  
    Array.from(videoWrappers).forEach((videoWrapper) => {
      if(!hasCookiebotConsent(cookiebotConsentType)) {
        videoWrapper.addEventListener('click', showBannerListener, true);
      } 
    });
    // Unfortunate hack for Hybris Page and video thumnail inside tabs.
    // The thumbnail from MI comes with a bad height initially.
    $('.page-productDetails .tab .tab-trigger.js-trigger').on('click', function (ev) {
      window.dispatchEvent(new Event('resize'))
    });
   
  }
}



