export const setSearchURLWithParams = (searchParams, windowLocation = window.location) => {
  const url = new URL(windowLocation)

  for (let param in searchParams) {
    if (!searchParams[param] || (Array.isArray(searchParams[param]) && !searchParams[param].length)) {
      url.searchParams.delete(param)
    } else {
      url.searchParams.set(param, searchParams[param])
    }
  }

  url.searchParams.delete('ratingPath')

  return url
}
