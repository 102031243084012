(function() {

    var $certificateContainers = $('.cart-calibration-certificate');
    if($certificateContainers.length > 0) {
        init();
    }

    function init() {

        var $errorDialog = $('.info-msgs-container').first();

        function extractErrorMessages(data, fallBackErrorMsg) {
            if(data && data.error === 'true') {
                if(data.errorMessages && data.errorMessages.length && data.errorMessages.length > 0) {
                    return data.errorMessages.map(function(item) {
                        return item.errorMessage;
                    });
                }
                return [fallBackErrorMsg];
            }
            return null;
        }

        function Certificate($certificateContainer) {
            this.entryId = $certificateContainer.attr('data-entry-number');
            this.errorMsg = $certificateContainer.attr('data-error-message');
            this.language = $certificateContainer.attr('data-language');
            this.$inputField = $certificateContainer.find('input');
            this.certificateIncluded = $certificateContainer.attr('data-entry-certificate-included') === 'true';

            this.$certificateRef = $certificateContainer.closest('.cart-entry')
                .find('.column-price .data-list .calibration-certificate-display');
        }

        Certificate.prototype.draw = function() {
            this.$inputField.prop('checked', this.certificateIncluded);
            this.$certificateRef.css('display', (this.certificateIncluded ? 'block' : 'none'));
        };

        Certificate.prototype.post = function() {

            var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
            var requestUrl = requestUriPrefix + '/shop/' + this.language + '/cart/update-calibration-certificate';

            var _this = this;
            $.post(
              requestUrl, {
                    entry: _this.entryId,
                    calibrationCerticate: this.$inputField.prop('checked')
                })
                .done(function(data) {
                    var errorMessages = extractErrorMessages(data, _this.errorMsg);
                    errorMessages
                        ? $errorDialog.trigger('show-info-messages', [errorMessages, true])
                        : _this.certificateIncluded = _this.$inputField.prop('checked');
                    _this.draw();
                })
                .fail(function(xhr) {
                    console.error(xhr.status, _this.errorMsg);
                    $errorDialog.trigger('show-info-messages', [[_this.errorMsg], true]);
                    _this.draw();
                });
        };

        Certificate.prototype.initHandlers = function() {
            var _this = this;
            this.$inputField.on('change', function() {
                _this.post();
            });
        };

        var certificates = [];
        $certificateContainers.each(function() {
            var certificate = new Certificate($(this));
            certificate.initHandlers();
            certificate.draw();
            certificates.push(certificate);
        });
    }
})();
