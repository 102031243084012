$('.order-list').on('click', '[data-uri]', function(ev) {
  ev.preventDefault();

  var that = $(this);
  $.ajax({
      method: 'GET',
      url: that.data('uri'),
      dataType: 'html',
      data: that.data,
      success: function (response) {
          var layer = $(response);
              var downloadAll = layer.find('.but-downloadAll');
              var cancel = layer.find('.but-cancel');
              var buttons = [];

          if(downloadAll.length > 0) {
            buttons.push({
                label: downloadAll.text(),
                type: 'button',
                callback: function () {
                    location.href = downloadAll.attr('href');
                }
            });
          }

          buttons.push({
              label: cancel.text(),
              type: 'reset',
              callback: Belimo.Modal.close
          });

          Belimo.Modal.open(layer.find('.docContent').html(), buttons);
      }
  });
});
