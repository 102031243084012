(function initMultiImageWrapper() {

  const mediaQuery = window.matchMedia('(min-width: 1020px)');
  const isMobile = !mediaQuery.matches;

  const multiImageWrapperList = document.getElementsByClassName('multi-image-wrapper');

  for(let k = 0; k < multiImageWrapperList.length; ++k) {
    const multiImageWrapper = multiImageWrapperList[k];
    if(!multiImageWrapper) continue;

    const isMultiColumn = !isMobile && multiImageWrapper.hasAttribute('data-multi-comlumn')
    ? (multiImageWrapper.getAttribute('data-multi-comlumn') === 'true')
    : false;

    const multiImageContainer = multiImageWrapper.querySelector('.multi-image-container');
    if(!multiImageContainer) continue;
    if(isMultiColumn) {
      multiImageContainer.style.flexDirection = 'row';
    }
    else {
      multiImageContainer.style.flexDirection = 'column';
    }

    const imageWrappers = multiImageWrapper.querySelectorAll('.multi-image-image-wrapper');
    for(let i = 0; i < imageWrappers.length; ++i) {

      const imageWrapper = imageWrappers[i];

      const scale = !isMobile && imageWrapper.hasAttribute('data-scale')
        ? imageWrapper.getAttribute('data-scale')
        : '100%';

      const translateX = !isMobile && imageWrapper.hasAttribute('data-translate-x')
        ? imageWrapper.getAttribute('data-translate-x')
        : '0%';

      const translateY = !isMobile && imageWrapper.hasAttribute('data-translate-y')
        ? imageWrapper.getAttribute('data-translate-y')
        : '0%';

      const img = imageWrapper.querySelector('img');
      if(!img) continue;
      img.style.width = scale;
      img.style.marginLeft = translateX;
      img.style.marginTop = translateY;
    }

  }
  window.addEventListener('resize', initMultiImageWrapper);
})();
