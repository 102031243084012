import { initCountrySelector } from 'script/belimo.hybris.address.countrystate';
import { selectInit } from 'script/belimo.hybris.selectInit';
require('webpack-jquery-ui/autocomplete');

(function () {

  const $addressAddEditPage = $('.address-add-edit');
  const $popupWrapper = $addressAddEditPage.find('#popup-id-address-edit');

  if ($addressAddEditPage.length > 0 && $popupWrapper.length > 0) {
    init();
  }

  function init() {

    const $popupWrapper = $addressAddEditPage.find('#popup-id-address-edit');
    const $addressEditOpenCtrls = $addressAddEditPage.find('.address-edit-open');
    const $addressEditWrapper = $popupWrapper.find('.address-edit-wrapper');
    const ignoreSuggestionsUrl = ACC.config.encodedContextPath + '/common/ignore-suggestions-country-ajax';

    $popupWrapper.get(0).addEventListener('click', function (e) {
      e.preventDefault();
      if (e.target.classList.contains('address-edit-cancel')) {
        ui.closePopup();
      } else if (e.target.classList.contains('address-edit-ignore')) {
        ui.ignoreSuggestions();
        return false;
      } else if (e.target.classList.contains('address-edit-submit')) {
        ui.makeCall(false);
        return false;
      }
    });

    const state = {
      addressId: null,
      displayUrl: null,
      proceedUrl: null,
      nextStepRequestUrl: null,
      init: function (addressId, displayUrl, proceedUrl, nextStepRequestUrl) {
        this.addressId = addressId;
        this.displayUrl = displayUrl;
        this.proceedUrl = proceedUrl;
        this.nextStepRequestUrl = nextStepRequestUrl;
      }
    };

    const ui = {
      elems: {
        $submitButton: null,
        $cancelButton: null
      },
      closePopup: function () {
        $popupWrapper.trigger('popup-close');
      },
      makeCall: function (isInitialLoad) {
        $.ajax({
          url: isInitialLoad ? state.displayUrl : state.proceedUrl,
          dataType: 'html',
          method: 'POST',
          data: isInitialLoad ? $addressAddEditPage.find('#belimoAddressForm-' + state.addressId).serialize() : $popupWrapper.find('form').serialize()
        }).done(function (html) {

          if (html.includes('edit-address-ajax')) {
            $addressEditWrapper.html(html);
			const $formDiv = $popupWrapper.find('.edit-address-ajax').addClass('form-pop').addClass("form-toggled");
			
            const $stateSelect = $popupWrapper.find('#address\\.country');
            if ($stateSelect.length > 0) $stateSelect.get(0).style.setProperty('height', '2.5em');

            const $regionSelect = $popupWrapper.find('#address\\.region');
            if ($regionSelect.length > 0) $regionSelect.get(0).style.setProperty('height', '2.5em');

            let selectedCountry = undefined;
            const $countrySelector = $popupWrapper.find('#countrySelector');
            if ($countrySelector.length > 0) {
              selectedCountry = $countrySelector.get(0).getAttribute('data-country-iso-code');
            }

            initCountrySelector();
            selectInit(selectedCountry);
			initLoquate($('.form-pop').find('#address\\.line1'));
            $popupWrapper.trigger('popup-open', []);
          } else {
            $popupWrapper.trigger('popup-close');
            window.location.href = state.nextStepRequestUrl;
          }
        }).fail(function (xhr) {
          console.error(xhr.responseText);
        });
      },
      ignoreSuggestions: function () {
        $.ajax({
          url: 'ignore-suggestions',
          dataType: 'html',
          method: 'POST',
          data: $popupWrapper.find('form').serialize()
        }).done(function (html) {

          if (html.includes('edit-address-ajax')) {
            $addressEditWrapper.html(html);
            const $stateSelect = $popupWrapper.find('#address\\.country');
            if ($stateSelect.length > 0) $stateSelect.get(0).style.setProperty('height', '2.5em');

            const $regionSelect = $popupWrapper.find('#address\\.region');
            if ($regionSelect.length > 0) $regionSelect.get(0).style.setProperty('height', '2.5em');

            let selectedCountry = undefined;
            const $countrySelector = $popupWrapper.find('#countrySelector');
            if ($countrySelector.length > 0) {
              selectedCountry = $countrySelector.get(0).getAttribute('data-country-iso-code');
            }

            initCountrySelector();
            selectInit(selectedCountry);

            // ignore-suggestions-country
            const countryIso = $popupWrapper.find('#address\\.country').find(":selected").val();
            $.ajax({
              url: ignoreSuggestionsUrl,
              method: 'GET',
              data: {
                countryIso: countryIso
              }
            }).done(function (ignoreSuggestions) {
              if ("enabled" === ignoreSuggestions) {
                // show ignore button
                $('.address-edit-ignore').show();
              } else {
                $('.address-edit-ignore').hide();
              }
            }).fail(function (xhr) {
              console.error(xhr.responseText);
            });

            $popupWrapper.trigger('popup-open', []);
          } else {
            $popupWrapper.trigger('popup-close');
            window.location.href = state.nextStepRequestUrl;
          }
        }).fail(function (xhr) {
          console.error(xhr.responseText);
        });
      }
    };

    $addressEditOpenCtrls.each(function () {
      // eslint-disable-next-line no-invalid-this
      const $addressEditOpenCtrl = $(this);
      $addressEditOpenCtrl.on('click', function () {
        state.init(
            $addressEditOpenCtrl.get(0).getAttribute('data-address-id'),
            $addressEditOpenCtrl.get(0).getAttribute('data-verify-display-url'),
            $addressEditOpenCtrl.get(0).getAttribute('data-verify-proceed-url'),
            $addressEditOpenCtrl.get(0).getAttribute('data-next-step-request-url'));
        ui.makeCall(true);
      });
    });
  }
})();

function initLoquate(addressLine1Ref){
	
	  const config = $('#addressVerificationConfig'),
	      verificationEnabled = config.data('address-verification-enabled'),
		  verificationCurrentStoreEnabled = config.data('address-verification-current-store-enabled'),
	      key = config.data('address-verification-key'),
	      findUrl = config.data('address-suggestion-find-url'),
	      retrieveUrl = config.data('address-suggestion-retrieve-url'),
		  addressLine1 = addressLine1Ref;
	  $('.address-edit-ignore').click(function (e) {
	    e.preventDefault();
	    $('#belimoAddressForm').attr('action', 'add-ignore-suggestions');
	    $('#belimoAddressForm').submit();
	  });

	  $('.account-section').find('.update-address').click(function (e) {
	    e.preventDefault();
	    $('#belimoAddressForm').attr('action', 'edit-address');
	    $('#belimoAddressForm').submit();
	  });

	  // ignore-suggestions-country button display for one-time drop-ship address form
	  const countryIso = $('.form-toggled').find('#address\\.country').find(":selected").val();
	  if (countryIso) {
	    const ignoreSuggestionsUrl = ACC.config.encodedContextPath + '/common/ignore-suggestions-country-ajax';
	    $.ajax({
	      url: ignoreSuggestionsUrl,
	      method: 'GET',
	      data: {
	        countryIso: countryIso
	      }
	    }).done(function (ignoreSuggestions) {
	      if ("enabled" === ignoreSuggestions) {
	        $('.address-edit-ignore').show();
	      } else {
	        $('.address-edit-ignore').hide();
	      }
	    }).fail(function (xhr) {
	      console.error(xhr.responseText);
	    });
	  } else {
	    // disable address line1 input if country is not selected
	    $('.form-toggled').find('#address\\.line1').prop('disabled', true);
	    $('.form-toggled').find('#address\\.country').on('change', function (e) {
	      $('.form-toggled').find('#address\\.line1').prop('disabled', false);
	    });
	  }

	  defaultAutocomplete();

	  function defaultAutocomplete() {
	    if (verificationEnabled && verificationCurrentStoreEnabled) {
	      addressLine1.autocomplete({
	        source: function (request, response) {
	          $.ajax({
	            url: findUrl,
	            method: 'POST',
	            data: {
	              Key: key,
	              Text: request.term,
	              IsMiddleware: false,
	              Countries: $('.form-toggled').find('#address\\.country').find(":selected").val(),
	            },
	            success: function (data) {
	              let suggestionList = [];
	              // Test for an error
	              if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
	                // Show the error message
	                console.warn(data.Items[0].Description);
	              } else {
	                // Check if there were any items found
	                if (data.Items.length == 0)
	                  console.info("No address suggestion for input: " + request.term);
	                else {
	                  $.each(data.Items, function (index, value) {
	                    suggestionList.push({
	                      id: this.Id,
	                      type: this.Type,
	                      label: this.Text + ', ' + this.Description,
	                      value: this.Text
	                    })
	                  });
	                }
	              }
	              response(suggestionList);
	            }
	          });
	        }
	        ,
	        minLength: 3,
	        select: function (event, ui) {
	          addressLine1.data('type', ui.item.type);
	          if (addressLine1.data('type') === 'Address') {
	            retrieve(ui.item.id);
	          } else {
	            drillDown(ui.item.id);
	          }
	        },
	        close: function (event, ui) {
	          if (addressLine1.data('type') !== 'Address') {
	            addressLine1.autocomplete('search', '');
	          }
	        },
	        classes: {
	          "ui-autocomplete": "address-suggestions"
	        }
	      });
	    }
	  }

	  function retrieve(id) {
	    $.ajax({
	      url: retrieveUrl,
	      method: 'POST',
	      data: {
	        Key: key,
	        Id: id,
	      },
	      success: function (data) {
	        let result = [];
	        // Test for an error
	        if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
	          // Show the error message
	          console.warn('Loqate Retrieve API error: ' + data.Items[0].Description);
	        } else {
	          // Check if there were any items found
	          if (data.Items.length == 0)
	            console.warn('Loqate Retrieve API error for id: ' + id);
	          else {
	            $.each(data.Items, function (index, value) {
	              let address = this.Line1 + ' ' + this.Line2;
	              $('.form-toggled').find('#address\\.line1').val(address.trim());
	              $('.form-toggled').find('#address\\.townCity').val(this.City);
	              $('.form-toggled').find('#address\\.postcode').val(this.PostalCode);

	              $('select[data-arv-ajax-url]').select2().val(this.CountryIso2 + '-' + this.ProvinceCode).trigger('change');
	              // remove any existing input field error suggestion labels, if any
	              $('.form-toggled').find('.form-input-text.required.error').each(function (){
	                $(this).removeClass('error');
	              });
	            });
	          }
	        }
	      }
	    });
	    defaultAutocomplete();
	  }

	  function drillDown(id) {
	    addressLine1.autocomplete({
	      source: function (request, response) {
	        $.ajax({
	          url: findUrl,
	          method: 'POST',
	          data: {
	            Key: key,
	            Container: id,
	            IsMiddleware: false,
	            Countries: $('.form-toggled').find('#address\\.country').find(":selected").val(),
	          },
	          success: function (data) {
	            let suggestionList = [];
	            // Test for an error
	            if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
	              // Show the error message
	              console.warn(data.Items[0].Description);
	            } else {
	              // Check if there were any items found
	              if (data.Items.length == 0)
	                console.warn("No address suggestion for container id: " + id);
	              else {
	                $.each(data.Items, function (index, value) {
	                  let textString = this.Text.toLowerCase();
	                  let searchTerm = request.term.toLowerCase();
	                  // adding local filtering logic as Loqate doesn't filter results for drill-down case
	                  if(textString.includes(searchTerm)) {
	                    suggestionList.push({
	                      id: this.Id,
	                      type: this.Type,
	                      label: this.Text + ', ' + this.Description,
	                      value: this.Text
	                    })
	                  }
	                });
	              }
	            }
	            response(suggestionList);
	          }
	        });
	      }
	      ,
	      minLength: 0,
	      select: function (event, ui) {
	        addressLine1.data('type', ui.item.type);
	        if (ui.item.type === 'Address') {
	          retrieve(ui.item.id);
			  
	        } else {
	          drillDown(ui.item.id);
	        }
	      },
	      close: function (event, ui) {
	        if (addressLine1.data('type') !== 'Address') {
	          addressLine1.autocomplete('search', '');
	        }
	        defaultAutocomplete();
	      },
	      classes: {
	        "ui-autocomplete": "address-suggestions"
	      }
	    });
	  }

	};


$(document).ready(initLoquate($('.form-toggled').find('#address\\.line1')) );
