
(function() {

  var $registerForm = $('.register-form-wrapper');

  if($registerForm.length > 0) {
    init();
  }

  function isValidAccountNumber(accountNumber) {

    return accountNumber &&
      accountNumber.length &&
      accountNumber.length === 6 &&
      !isNaN(Number(accountNumber));
  }

  function init() {

    var $form = $registerForm.find('form');
    var $popupTriggers = $registerForm.find('form button');
    var $popupWrapper = $registerForm.find('#popup-id-anp');
    var $formPurchasingCheckbox = $registerForm.find('input[name="purchasingAccount"]');
    var $formBelimoAccountNumberInput = $registerForm.find('input[name="accountNumber"]');

    function needsPrompt() {
      return $formPurchasingCheckbox.is(':checked') &&
        !isValidAccountNumber($formBelimoAccountNumberInput.val());
    }

    var state = {
      language: null,
      hasValidAccountNumber: false,

      init: function() {
      }
    };

    var ui = {

      elems: {
        $openTrigger: null,
        $okButton: null,
        $cancelButton: null,
        $accountNumberInputLabel: null,
        $accountNumberInput: null,
        $radioYes: null,
        $radioNo: null,
        $radioButtons: null
      },

      initHandlers: function() {
        var _this = this;

        function closePopup() {
          $popupWrapper.trigger('popup-close');
        }

        function submitForm() {
          if(_this.elems.$radioYes.is(':checked')) {
            $formBelimoAccountNumberInput.val(_this.elems.$accountNumberInput.val());
          }
          closePopup();
          $form.submit();
          e.preventDefault();
          return false;
        }

        this.elems.$okButton.on('click', function(e) {

          return submitForm();
        });

        this.elems.$cancelButton.on('click', function() {
          closePopup();
        });
        this.elems.$accountNumberInput.on('input', function() {
          state.hasValidAccountNumber = isValidAccountNumber(_this.elems.$accountNumberInput.val());
          _this.draw();
        });

        this.elems.$accountNumberInput.on('keyup', function(e) {
          if(e.which == 13 && state.hasValidAccountNumber && _this.elems.$radioYes.is(':checked')) {
            return submitForm();
          }
        });

        this.elems.$radioButtons.on('radio-button-click', function(e, groupId, id) {
          if(groupId !== 'anp-id-mode-selection') return;
          _this.draw();
        });

      },
      init: function($openTrigger) {

        this.elems.$openTrigger = $openTrigger;
        this.elems.$okButton = $popupWrapper.find('.anp-ok');
        this.elems.$cancelButton = $popupWrapper.find('.anp-cancel');
        this.elems.$accountNumberInputLabel = $popupWrapper.find('.anp-input-container label');
        this.elems.$accountNumberInput = $popupWrapper.find('.anp-input-container input[type="text"]');
        this.elems.$radioButtons = $popupWrapper.find('#anp-id-mode-selection');
        this.elems.$radioYes = $popupWrapper.find('input[data-rb-id="anp-yes"]');
        this.elems.$radioNo = $popupWrapper.find('input[data-rb-id="anp-no"]');

        this.elems.$cancelButton.prop('disabled', false);

        this.elems.$radioYes.prop('checked', false);
        this.elems.$radioNo.prop('checked', true);

      },
      openPopup: function() {
        $popupWrapper.trigger('popup-open', []);
      },
      draw: function() {

        state.hasValidAccountNumber = isValidAccountNumber(this.elems.$accountNumberInput.val());

        if(this.elems.$radioYes.is(':checked')) {
          this.elems.$accountNumberInputLabel.css('opacity', 1);
          this.elems.$accountNumberInput.css('opacity', 1);
          this.elems.$accountNumberInput.prop('disabled', false);
        }
        else {
          this.elems.$accountNumberInputLabel.css('opacity', 0.4);
          this.elems.$accountNumberInput.css('opacity', 0.4);
          this.elems.$accountNumberInput.prop('disabled', true);
        }
        this.elems.$okButton.prop('disabled', !state.hasValidAccountNumber && this.elems.$radioYes.is(':checked'));
      }
    };

    $popupTriggers.each(function() {

      $(this).on('click', function() {

        if(needsPrompt()) {

          state.init();
          ui.init($(this));
          ui.initHandlers();
          ui.draw();
          ui.openPopup();
        }
        else {
          $form.submit();
        }
      });
    });
  }

})();
