export  function pushTag(tag) {
    if (window.analyticsJson) {
       // console.log('analytics.js pushTag', tag);
        window.dataLayer.push(tag);
    }
}
export  function createGa4FilterRemovedEvent(eAction, eLabel, eCat) {
    return {
        'event_name': 'filter_removed',
        'event': 'ga4_event',
        'filter_removed': {
            'value': eLabel, // eg: 'Movement type'::'Rotative'
            'eventCategory': eCat ? eCat : 'Event',
            'eventAction': eAction,
            'eventLabel': eLabel // eg: 'Movement type'::'Rotative'
        }
    };
}
if (window.analyticsJson) {

    window.dataLayer = window.dataLayer || [];

    // traverse all properties of the given object recursively,
    // the function fn will be called with the arguments object and name of property.
    function traverse(obj, fn) {

        // eslint-disable-next-line guard-for-in
        for (var propName in obj) {

            fn.apply(this, [obj, propName]);

            // typeof null is 'object' :(
            if (obj[propName] !== null && typeof (obj[propName]) === 'object') {
                traverse(obj[propName], fn);
            }
        }
    }

    function setNullPropsToUndefined(obj) {
        traverse(obj, function (obj, propName) {
            if (obj[propName] === null || obj[propName] === 'undefined') {
                obj[propName] = void 0; // in case someone redefined undefined.
            }
        });
    }



    function addTag(tag, addUserDataIf) {

        if (addUserDataIf && !tag.userId) {
            tag.userId = analyticsJson.metadata.userId;
            tag.loggedIn = analyticsJson.metadata.loggedIn;
        }
        setNullPropsToUndefined(tag);
        pushTag(tag);
    }

    function assign(target, source) {
        if (!target || !source) return;
        for (var sourceKey in source) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(source, sourceKey)) {
                target[sourceKey] = source[sourceKey];
            }
        }
    }

    function objectValues(obj) {
        return Object.keys(obj).map(function (e) {
            return obj[e];
        });
    }

    function getStrWithoutPrefix(str, prefix) {
        if (str.indexOf(prefix) === 0) {
            str = str.replace(prefix, '');
        }
        return str;
    }

    function isString(obj) {
        return (Object.prototype.toString.call(obj) === '[object String]');
    }

    function strEqualsIgnoreCase(str1, str2) {
        if (str1 === str2) return true;
        if (!str1 || !str2) return false;
        if (!isString(str1) || !isString(str2)) return false;
        return str1.toLowerCase() === str2.toLowerCase();
    }

    window.analyticsNewsletterSubscribeSuccess = function () {
        addTag(createGaEvent('Newsletter', 'Subscribe', 'User Interaction'), true);
    };
    window.analyticsAddTag = function (tag) {
        addTag(tag, true);
    };
    window.analyticsCartFileImport = function (fileImportAnalyticsJson) {

        addTag(createGaEvent('Upload Project List', '', 'Downloads'), true);

        if (!fileImportAnalyticsJson) return;
        var fileImportAnalytics = JSON.parse(fileImportAnalyticsJson);

        addTag({
            'event': 'EEC-addToCart',
            'ecommerce': {
                'currencyCode': fileImportAnalytics.currencyCode,
                'add': {
                    'products': objectValues(fileImportAnalytics.products)
                }
            }
        });
    };

    function convertProducts(products) {
        return Object.keys(products).map(function (key) {

            var product = {};
            assign(product, products[key]);

            if (product.hasOwnProperty('list')) {
                delete product.list;
            }
            if (product.hasOwnProperty('position')) {
                delete product.position;
            }
            return product;
        });
    }

    function getProduct(products, code) {
        var keys = Object.keys(products);
        for (var i = 0; i < keys.length; i++) {
            if (products[keys[i]].id && products[keys[i]].id === code) {
                return products[keys[i]];
            }
        }
        return null;
    }


    // return < 0 on failure, the zero based index otherwise.
    function getProductIndexInsideCart($form) {

        // The index of the product is appended in the form id (e.g. updateCartForm12).
        var formId = $form.attr('id');
        if (!formId) return -1;
        var productIndex = Number(formId.replace('updateCartForm', ''));
        if (isNaN(productIndex)) return -1;
        return productIndex;
    }

    // extract the filename from the given path
    function extractFileName(path) {
        if (!path) return '';
        var index = path.lastIndexOf('/');
        if (index == -1 || index == path.length) return path;
        return path.substring(index + 1, path.length);
    }

    function createCheckoutStep(analyticsObj, stepNr) {

        var checkout = {
            'products': convertProducts(analyticsObj.products),
            'actionField': {}
        };

        if (analyticsObj.checkoutData && analyticsObj.checkoutData.hasOwnProperty('step')) {
            checkout.actionField.step = analyticsObj.checkoutData.step;
        } else if (stepNr || stepNr === 0) {
            checkout.actionField.step = stepNr;
        }

        return {
            'event': 'EEC-checkout',
            'ecommerce': {
                'currencyCode': analyticsObj.currencyCode,
                'checkout': checkout
            }
        };
    }

    function createPurchaseObject(analyticsObj) {

        var obj = {};
        if (!analyticsObj) return obj;

        obj.event = 'EECpurchase';
        obj.ecommerce = {};
        obj.ecommerce.currencyCode = analyticsObj.currencyCode;
        obj.ecommerce.purchase = {};
        obj.ecommerce.purchase.actionField = {};
        if (analyticsJson.checkoutData) {

            var revenue = Number(analyticsJson.checkoutData.revenue);
            var tax = Number(analyticsJson.checkoutData.tax);
            var shipping = Number(analyticsJson.checkoutData.shipping);

            obj.ecommerce.purchase.actionField.id = analyticsJson.checkoutData.transactionId;
            if (!isNaN(revenue)) obj.ecommerce.purchase.actionField.revenue = revenue;
            if (!isNaN(revenue)) obj.ecommerce.purchase.actionField.tax = tax;
            if (!isNaN(revenue)) obj.ecommerce.purchase.actionField.shipping = shipping;
        }
        obj.ecommerce.purchase.products = convertProducts(analyticsObj.products);
        return obj;
    }


    function createGaEvent(eAction, eLabel, eCat) {
        var e = {
            'event': 'gaEvent',
            'eventCategory': eCat ? eCat : 'Event',
            'eventAction': eAction
        };
        if (eLabel) {
            e.eventLabel = eLabel;
        }
        return e;
    }

    function createGa4FilterResetEvent() {
        return {
            'event_name': 'filter_reset',
            'event': 'ga4_event',
            'filter_selected': {
                'value': 'All filters removed',
                'eventCategory': 'Product Filter',
                'eventAction': 'Filter Reset',
                'eventLabel': 'All Filters Removed'
            }
        };
    }

    function createGa4FilterSelectedEvent(eAction, eLabel, eCat) {
        return {
            'event_name': 'filter_selected',
            'event': 'ga4_event',
            'filter_selected': {
                'value': eLabel, // eg: 'Movement type'::'Rotative'
                'eventCategory': eCat ? eCat : 'Event',
                'eventAction': eAction,
                'eventLabel': eLabel // eg: 'Movement type'::'Rotative'
            }
        };
    }



    var currentDomain = document.location.protocol + '//' + document.location.hostname;
    var belimoUrlRegEx = /https?:\/\/(www.)?[a-zA-z0-9]*\.?belimo./i;

    function isBelimoUrl(url) {
        if (!url) return false;

        function startsWith(str, subStr) {
            return str.indexOf(subStr) === 0;
        }

        if (startsWith(url, '/')) return true;
        if (currentDomain && startsWith(url, currentDomain)) return true;
        if (!startsWith(url, 'http') && !startsWith('https')) return true;

        return url.match(belimoUrlRegEx) != null;
    }

    function addImpressions() {
        addTag({
            'event': 'EEC-productImpression',
            'ecommerce': {
                'currencyCode': analyticsJson.currencyCode,
                'impressions': objectValues(analyticsJson.impressions)
            }
        });
    }

    function addImpressionIf(productName) {
        if (!productName) return;
        var impression = analyticsJson.impressions[productName];
        if (!impression) return;

        addTag({
            'event': 'EEC-productImpression',
            'ecommerce': {
                'currencyCode': analyticsJson.currencyCode,
                'impressions': [impression]
            }
        });
    }


    if (analyticsJson.metadata.pageType === 'CATEGORY') {
        addImpressions();
        document.addEventListener("DOMContentLoaded", function () {
            addFilterEventListeners();
        });
    }

    if (analyticsJson.metadata.pageType === 'PRODUCTSEARCH') {
        document.addEventListener("DOMContentLoaded", function () {
            addFilterEventListeners();
        });
    }

    if (analyticsJson.metadata.pageType === 'DOCUMENTSEARCH') {
        addImpressions();
        document.addEventListener("DOMContentLoaded", function () {
            addFilterEventListeners();
        });
    }

    function addFilterEventListeners() {
        let filters = $('.form-input.form-input-checkbox.autosubmit > label > input[type=checkbox]');

        for (let filter of filters) {
            filter.addEventListener("change", () => {
                let selectedFilterValue = $(filter).siblings('span.facet-nav-overflow-handling').first().text();
                let isProduct = true;
                if (selectedFilterValue === '') {
                    selectedFilterValue = $(filter).siblings('.pivot-overflow-handling').first().text();
                    isProduct = false;
                }
                let selectedFilterName = $(filter).parent().parent().parent().parent().parent().find('.hyphenopoly-container span:first-child').text().replace('\n', '').trimStart();
                let selectedFilterIsChecked = $(filter).is(':checked');
                if (selectedFilterIsChecked) {
                    pushTag(createGa4FilterSelectedEvent('Filter Selected', selectedFilterName + '::' + selectedFilterValue, isProduct ? 'Product Filter' : 'Document Filter'));
                } else {
                    pushTag(createGa4FilterRemovedEvent('Filter Removed', selectedFilterName + '::' + selectedFilterValue, isProduct ? 'Product Filter' : 'Document Filter'));
                }
            });
        }
        let resetFiltersLinks = $('div.form-control.form-button>a.fake-button.inverse');
        for (let resetFilterLink of resetFiltersLinks) {
            resetFilterLink.addEventListener("click", () => {
                pushTag(createGa4FilterResetEvent());
            });
        }

    }

    function isElementVisibleOnCurrentPage(element) {
        var rect = element.getBoundingClientRect();
        return rect.with !== 0 && rect.height !== 0;
    }

    if (analyticsJson.metadata.pageType === 'PRODUCT') {

        addTag({
            'event': 'EEC-productDetail',
            'ecommerce': {
                'currencyCode': analyticsJson.currencyCode,
                'detail': {
                    'actionField': {
                        'products': objectValues(analyticsJson.products)
                    }
                }
            }
        });

        if (analyticsJson.impressions) {
            function onBecomesVisible(element, value, callback) {
                var options = {
                    root: document.documentElement,
                    threshold: 0.01
                };
                try {
                    var observer = new IntersectionObserver(function (entries, observer) {
                        for (var j = 0; j < entries.length; ++j) {
                            callback(entries[j].intersectionRatio > 0, value);
                        }
                    }, options);
                    observer.observe(element);
                } catch (error) {
                    console.warn('Failed to set up Intersection oberserver.');
                }
            }

            var accessoryProducts = document.querySelectorAll('.accessories-collapsible-content .product__list--name');
            for (var i = 0; i < accessoryProducts.length; ++i) {
                var productName = accessoryProducts[i].getAttribute('data-code');
                if (analyticsJson.impressions[productName]) {

                    if (isElementVisibleOnCurrentPage(accessoryProducts[i])) {
                        addImpressionIf(productName);
                    } else {
                        onBecomesVisible(accessoryProducts[i], productName, function (isVisible, currentProductName) {
                            if (isVisible) {
                                addImpressionIf(currentProductName);
                            }
                        });
                    }
                }
            }
        }
    }

    if (analyticsJson.metadata.pageType == 'ORDERCONFIRMATION') {
        addTag(createPurchaseObject(analyticsJson));
    }

    // Contact, Distributor lookup
    if (analyticsJson.metadata['pageType'] == 'CONTENT') {

        if ((strEqualsIgnoreCase(analyticsJson.metadata['pageCategory1'], 'Contacts')
                || strEqualsIgnoreCase(analyticsJson.metadata['pageCategory1'], 'Contact Us'))
            && (strEqualsIgnoreCase(analyticsJson.metadata['pageCategory2'], 'Contacts')
                || strEqualsIgnoreCase(analyticsJson.metadata['pageCategory2'], 'Contact Us'))
            && (strEqualsIgnoreCase(analyticsJson.metadata['pageCategory3'], 'distributor-lookup')
                || strEqualsIgnoreCase(analyticsJson.metadata['pageCategory3'], 'belimo-contacts'))) {

            // example url: ?country=united-states&postcode=89436#contact

            function getUrlParam(name) {
                var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                    .exec(window.location.search);

                if (results === null) {
                    return null;
                }
                if (!results.length || results.length < 1 || results.length === 0) {
                    return '';
                }
                return results[1];
            }

            var plz = getUrlParam('postcode');
            var country = getUrlParam('country');

            var eventLabel = country ? country : '';
            if (country && plz) eventLabel += ' ';
            eventLabel += plz ? plz : '';

            var eventAction = 'Distributor Lookup';
            if (strEqualsIgnoreCase(analyticsJson.metadata['pageCategory3'], 'belimo-contacts')) {
                eventAction = 'Belimo Contacts';
            }


            if (plz || country) {
                var eventLabel = country ? country : '';
                if (country && plz) eventLabel += ' ';
                eventLabel += plz ? plz : '';
                addTag(createGaEvent(eventAction, eventLabel, 'Contact'), true);
            } else {
                $(document).ready(function () {
                    // On page load the select content is preselected and not part of the url.
                    eventLabel = $('.locations form#contact span.select2-selection__rendered[role="textbox"]').text();
                    addTag(createGaEvent(eventAction, eventLabel, 'Contact'), true);
                });
            }

            $('.locations .location a').click(function () {

                var eventLabel = $(this).attr('href');

                eventLabel = getStrWithoutPrefix(eventLabel, 'mailto:');
                eventLabel = getStrWithoutPrefix(eventLabel, 'tel:');

                addTag(createGaEvent('Belimo Contacts', eventLabel, 'Contact'), true);
            });
        }
    }

    if (analyticsJson.metadata['pageType'] === 'PRODUCT') {
        function addIf($elem, label) {
            if ($elem.hasClass('toggle-on')) return;
            addTag(createGaEvent('Modal Navigation', label, 'Product Detail Page'), true);
        }

        $('.tab-trigger.gtm-product-tab-detail').click(function () {
            addIf($(this), 'Details');
        });
        $('.tab-trigger.gtm-product-tab-configuration').click(function () {
            addIf($(this), 'Configuration');
        });
        $('.tab-trigger.gtm-product-tab-documents').click(function () {
            addIf($(this), 'Documents');
        });
    }

    if (strEqualsIgnoreCase(analyticsJson.metadata['pageType'], 'DOCUMENTSEARCH')) {
        // TODO: Check if 'Add to Download Folder Button' is always within pageType DocumentSearch
        $('button#addToCartButton').click(function () { // Button with id 'addToCart' is actually an 'add to download Folder Button'

            var fileName = $(this).closest('.search-result').find('.gtm-download').attr('href');
            fileName = extractFileName(fileName);

            addTag(createGaEvent(
                'DC - Add to Download Folder',
                fileName,
                'Downloads'), true);
        });
        document.addEventListener("DOMContentLoaded", function () {
            addFilterEventListeners();
        });
    }

    if (strEqualsIgnoreCase(analyticsJson.metadata['pageType'], 'CHECKOUT')) {
        addTag(createCheckoutStep(analyticsJson));
    }

    if (strEqualsIgnoreCase(analyticsJson.metadata['pageType'], 'CART')) {

        function helperSaveCart() {
            // give other click handlers/link handlers time to modify the dom
            // so we can successfully select the button inside the modal.
            setTimeout(function () {
                $('.modal .modal-actions button.gtm-save-project-list').click(function () {

                    addTag(createGaEvent('Add to Project List',
                        $('.modal #saveCartName').val(), 'Project List'), true);
                });

            }, 0);
        }

        function helperEmptyCart() {
            // give other click handlers/link handlers time to modify the dom
            // so we can successfully select the button inside the modal.
            setTimeout(function () {
                $('.modal .modal-actions button.gtm-empty-cart').click(function () {
                    addTag({
                        'event': 'EEC-removeFromCart',
                        'ecommerce': {
                            'currencyCode': analyticsJson.currencyCode,
                            'remove': {
                                'products': objectValues(analyticsJson.products)
                            }
                        }
                    });
                });

            }, 0);
        }

        // Add to project list modal via 'Save as Project List' Button/Link below the Checkout Button on the cart page.
        $('.cart-footer .cart-total a.saveCart').click(helperSaveCart);
        // Add to project list modal via 'Save as Project List' from action-panel.
        $('.cart-actions-panel .cart-footer a.saveCart').click(helperSaveCart);

        // Empty cart via 'Empty Cart' Button/Link on the cart page.
        $('.cart-actions-panel .cart-footer a.cancelCart').click(helperEmptyCart);

    }

    // On the cart page, using a link to open the relating document, one ore more download buttons appear
    $('.document .gtm-download-pdp').click(function () {

        var pdfLink = $(this).find('a').attr('href');
        var index = pdfLink.indexOf('.pdf');
        if (index == -1) return;

        pdfLink = pdfLink.substring(0, index + 4);
        pdfLink = extractFileName(pdfLink);

        addTag(createGaEvent('Direct Download', pdfLink, 'Downloads'), true);
    });

    // //////////////////////////////////////////////////////////////////////////
    // Download center and document downloads on PDP ///////////////

    function addDownloadMultipleEvent(actionName, fileName) {
        // This name is not available in the frontend.
        // It is set in the backend and never passed to the front end.
        // It is therefore hardcoded here.
        if (!fileName && fileName !== '') fileName = 'Belimo_Documents.zip';
        addTag(createGaEvent(actionName, fileName, 'Downloads'), true);
    }

    function addDeleteMultipleEvent(actionName) {
        addTag(createGaEvent(actionName, '', 'Downloads'), true);
    }

    $('.gtm-download-product-all, .gtm-download-product-selected').click(function () {
        var productName = $(this).parent().find('input#productCode').val();
        if (productName) {
            addTag(createGaEvent('Direct Download', productName + '.zip', 'Downloads'), true);
        }
    });

    $('.gtm-download-selected a').click(function () {
        addDownloadMultipleEvent('DC - Download Selected');
    });

    $('.gtm-sharebyemail-selected a').click(function () {
        addDownloadMultipleEvent('DC - Share Selected', '');
    });

    $('.gtm-delete-selected a').click(function () {
        addDeleteMultipleEvent('DC - Delete Selected');
    });

    $('.gtm-delete-all a').click(function () {
        addDeleteMultipleEvent('DC - Empty Complete Folder');
    });

    $('.gtm-download-all a').click(function () {
        addDownloadMultipleEvent('DC - Download Complete Folder');
    });
    $('.gtm-download-filtered button').click(function () {
        addDownloadMultipleEvent('DC - Download Filtered');
    });

    // //////////////////////////////////////////////////////////////////////////

    function productClick(code) {
        if (!code) return;
        var product = analyticsJson.impressions[code] || getProduct(analyticsJson.products, code);
        if (!product) return;

        addTag({
            'event': 'EEC-productClick',
            'ecommerce': {
                'currencyCode': analyticsJson.currencyCode,
                'click': {
                    'actionField': {
                        // 'list' : objectValues(analyticsJson.impressions)
                    },
                    'products': [product]
                }
            }
        });
    }

    $('.gtm-product-click').click(function () {
        productClick($(this).data('code'));
    });

    function helperAddCartModal(code) {
        // give other click handlers/link handlers time to modify the dom
        // so we can successfully select the button inside the modal.
        setTimeout(function () {
            $('.modal .modal-content a.gtm-product-click').click(function () {
                productClick(code);
            });

        }, 0);
    }

    // Add to cart modal via 'AddToCart' Button on CLP or Search.
    $('.form.add-to-cart-form.product-cart .add-to-cart').click(function () {
        helperAddCartModal($(this).data('code'));
    });

    // Add to cart modal via 'AddToCart' Button on CLP or Search.
    $('.gtm-pdp-form-add-to-cart .add-to-cart').click(function () {
        helperAddCartModal($(this).data('code'));
    });

    function addQuantityToProductData($addForm, productDataArr) {
        var quantity = $addForm.find('.column-quantity input').val();
        for (var i = 0; i < productDataArr.length; i++) {
            productDataArr[i].quantity = quantity;
        }
    }

    function addTagEEC(products) {
        addTag({
            'event': 'EEC-addToCart',
            'ecommerce': {
                'currencyCode': analyticsJson.currencyCode,
                'add': {
                    'products': products
                }
            }
        });
    }

    $('.gtm-pdp-form-add-to-cart form').submit(function () {

        var products = objectValues(analyticsJson.products);
        addQuantityToProductData($(this), products);
        addTagEEC(products);
    });

    $('.gtm-add-to-cart form').submit(function () {
        var code = $(this).find('input[name=productCodePost]').val();
        var products = [analyticsJson.impressions[code]];
        addQuantityToProductData($(this), products);
        addTagEEC(products);
    });

    $('.gtm-pdp-add-to-cart form').submit(function () {
        var code = $(this).find('input[name=productCodePost]').val();
        var products = objectValues(analyticsJson.products);
        addQuantityToProductData($(this), products);
        addTagEEC(products);
    });

    // Hint: declared as global variable, so it can be called from elsewhere (e.g. hybris 'belimosapproductconfigb2baddon').
    window.pdpConfigurableProductGtmAddToCart = function () {
        var products = objectValues(analyticsJson.products);
        var quantity = $('.column-quantity input[id="quantityInputValue"]').val();
        for (var i = 0; i < products.length; i++) {
            products[i].quantity = quantity;
        }
        addTagEEC(products);
    };

    // Configurable PDP Add To Cart Button
    $('.gtm-pdp-add-to-cart').click(function () {
        pdpConfigurableProductGtmAddToCart();
    });

    $('.gtm-update-cart').click(function () {

        var $form = $(this).closest('form');

        var initialQuantity = Number($form.find('input[name="initialQuantity"]').val());
        var newQuantity = Number($form.find('.column-quantity input').val());
        if (isNaN(initialQuantity) || isNaN(newQuantity)) return;
        if (initialQuantity === newQuantity) return;

        var productIndex = getProductIndexInsideCart($form);
        if (productIndex < 0) return;

        // Find the product data which is to updated.
        var allCartProducts = objectValues(analyticsJson.products);
        if (productIndex >= allCartProducts.length) return;
        var product = allCartProducts[productIndex];

        // Calculate the difference in quantity and send an event with the appropriate tag.
        product.quantity = Math.abs(initialQuantity - newQuantity);

        initialQuantity > newQuantity
            ? addTag({
                'event': 'EEC-removeFromCart',
                'ecommerce': {
                    'currencyCode': analyticsJson.currencyCode,
                    'remove': {
                        'products': [product]
                    }
                }
            })
            : addTagEEC([product]);
    });

    $('.gtm-remove-form-cart').click(function () {

        var $form = $(this).closest('form');

        var productIndex = getProductIndexInsideCart($form);
        if (productIndex < 0) return;

        // Find the product data which is to updated.
        var allCartProducts = objectValues(analyticsJson.products);
        if (productIndex >= allCartProducts.length) return;
        var product = allCartProducts[productIndex];

        addTag({
            'event': 'EEC-removeFromCart',
            'ecommerce': {
                'currencyCode': analyticsJson.currencyCode,
                'remove': {
                    'products': [product]
                }
            }
        });
    });


    // // Shop, Clicking "Checkout" button and going to "Payment Type":
    // // TODO: What about redirection to login page and checkout??
    // $('.gtm-checkout').click(function(){
    // 	addTag(createCheckoutStep(analyticsJson, 1), true);
    // });

    // Shop, Checkoput->Payment Next Button
    $('.gmt-checkout-payment-type-next').click(
        function () {

            // In case no checked radio button is found, just
            // don't add the 'option' property.
            var paymentOption = $(
                '#selectPaymentTypeForm input[name="paymentType"]:checked')
                .attr('id');
            var actionField = {
                'step': 2
            };
            if (paymentOption) {
                actionField.option = paymentOption;
            }

            addTag({
                'event': 'EEC-checkoutOption',
                'ecommerce': {
                    'checkout_option': {
                        'actionField': actionField
                    }
                }
            });
        }
    );

    // Add to Calendar
    $('a[target="_blank"][href*=".ics/"]').click(function () {

        addTag(createGaEvent('Add to Calendar',
            $(this).closest('.event').find('a').first().text()), true);
    });

    // direct pdf downloads
    $('[href$=".pdf"]').click(function () {

        var url = $(this).attr('href');
        if (!isBelimoUrl(url)) return;

        var fileName = extractFileName($(this).attr('href'));
        addTag(createGaEvent('Direct Download', fileName, 'Downloads'), true);
    });

    $('a').click(function () {
        var url = $(this).attr('href');
        if (!url) return;

        if (isBelimoUrl(url)) return;
        addTag(createGaEvent(window.location.pathname, decodeURI(url), 'External Links'), true);

    });

    $('a[data-target="facebook"], a[data-target="linkedin"], a[data-target="twitter"], a[data-target="email"]').click(function () {

        var action = $(this).attr('href');
        var sI = action.indexOf('http', 1);
        if (sI > -1) {
            action = action.substring(sI, action.length);
        }

        addTag(createGaEvent($(this).attr('title'), unescape(action), 'Social Share'), true);
    });

    $('body footer a[href^="tel:"], .footer a[href^="mailto:"]').click(function () {

        var eventLabel = $(this).attr('href');

        eventLabel = getStrWithoutPrefix(eventLabel, 'mailto:');
        eventLabel = getStrWithoutPrefix(eventLabel, 'tel:');
        addTag(createGaEvent('Belimo Contacts', eventLabel, 'Contact'), true);
    });
}



