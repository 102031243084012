$('.cart-actions').each(function(){
	var actions = $(this);
		var options = actions.find('.cart-actions-options');
		var toggle = actions.find('.toggle-options');
		var text = toggle.html();
		var title = toggle.attr('title');
		var toggleText = toggle.data('toggle-text');
		var toggleTitle = toggle.data('toggle-title');

	toggle.on('click', function(ev) {
		ev.preventDefault();

		if(options.hasClass('toggle-on')) {
			options.removeClass('toggle-on');
			toggle.html(text);
			toggle.attr('title', title);
		} else {
			options.addClass('toggle-on');
			toggle.html(toggleText);
			toggle.attr('title', toggleTitle);
		}

		$(document).trigger('layout-changed');
	});
});

$('.save-and-close').click(function(e) {
	e.preventDefault();
	$(this).closest('form.save-and-close').submit();
});

$('.cart-share a').click(function(e) {
	var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
	var language = $(this).data('language');
	var requestUrl = requestUriPrefix + '/shop/'+language+"/cart/share-current-cart-ajax";
	
    $.get(requestUrl, function(result) {

        window.location.href = 'mailto:?subject=' + result.emailSubject + '&body=' + result.emailBody;
    });
	return false;
});

(function() {
	var ui = {
		$actionGroup: $('.cart-action-group.download-folder'),
		$errorSpan: null,
		$buttons: $('.cart-action-group.download-folder .classification_type'),
		$actionUrl: $('.cart-action-group.download-folder #action-url')
	};
	var inputData = {
		actionUrl: ui.$actionUrl.val()
	};

	function extractErrorText(data) {
		if(data &&
			data.multipleErrorMessages &&
			data.multipleErrorMessages.length &&
			data.multipleErrorMessages.length > 0) {

			for(var i = 0; i < data.multipleErrorMessages.length; ++i) {
				if(data.multipleErrorMessages[i].message) {
					return data.multipleErrorMessages[i].message;
				}
			}
		}
		return '';
	}

	ui.$buttons.click(function(){
		if(ui.$errorSpan) ui.$errorSpan.text('');
		ui.$errorSpan = $(this).prev('.error');

		var docType = $(this).attr('id');
		if(!inputData.actionUrl || !docType) return;

		$.post(inputData.actionUrl, {
			documentType: docType
		})
		.done(function(data) {
			var errorText = extractErrorText(data);
			if(errorText) ui.$errorSpan.text(errorText);
			if(data.entries) Belimo.Downloadfolder.update(data.entries);
		})
		.fail(function(jqXHR) {
			if(jqXHR && jqXHR.responseText) {
				console.error(jqXHR.responseText);
			}
		});
	});
})();
