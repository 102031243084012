$('.cart-actions').each(function(){
	var saveCart = $(this).find('.saveCart');
		var cancelCart = $(this).find('.cancelCart');
		var saveCartModal = $(this).find('.saveCartModal');
		var emptyCartModal = $(this).find('.emptyCartModal');
		var closeCart = $(this).find('.close-cart');

	var saveText; var saveAsText; var cancelText; var emptyText;
	function setButtonTexts() {
		var saveCartForm = $('.saveCartModal #saveCartForm');
		saveText = saveCartForm.attr('data-text-save');
		saveAsText = saveCartForm.attr('data-text-save-as');
		cancelText = saveCartForm.attr('data-text-cancel');
		emptyText = saveCartForm.attr('data-text-empty');

		if(!saveText) saveText = 'Save';
		if(!saveAsText) saveAsText = 'Save as Project List';
		if(!cancelText) cancelText = 'Cancel';
		if(!emptyText) emptyText = 'Empty Cart';
	}
	setButtonTexts();


	saveCart.on('click', function(ev){
		ev.preventDefault();
		var buttons = [];

		buttons.push({
			label: saveText,
			type: 'button',
			classes: 'gtm-save-project-list',
			inputField: saveCartModal.find('#saveCartName'),
			callback: function() {
				// Submit the form in a 'setTimeout' call, so that click
				// handlers which might by attached to the save button have a chance to run.
				setTimeout(function(){
					saveCartModal.find('form').submit();
				});
			}
		});

		buttons.push({
			label: cancelText,
			type: 'reset',
			callback: Belimo.Modal.close
		});

		Belimo.Modal.open(saveCartModal, buttons);

		return false;
	});

	cancelCart.on('click', function(ev){
		ev.preventDefault();
		var buttons = [];

		buttons.push({
			label: emptyText,
			type: 'button',
			classes: 'gtm-empty-cart',
			callback: function() {
				// Navigate to the url in a 'setTimeout' call, so that other click
				// handlers which might by attached to the button have a chance to run.
				setTimeout(function(){
					location.href = emptyCartModal.data('cancelurl');
				}, 0);
			}
		});

		buttons.push({
			label: saveAsText,
			type: 'button',
			callback: function() {
				Belimo.Modal.close;
				saveCart.trigger('click');
			}
		});

		buttons.push({
			label: cancelText,
			type: 'reset',
			callback: Belimo.Modal.close
		});

		Belimo.Modal.open(emptyCartModal, buttons);

		return false;
	});

	closeCart.on('click', function(e) {
		e.preventDefault();
		$(this).closest('form').submit();
	});
});
