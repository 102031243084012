function minHeight(list, content) {

	var $panelContent = list.find('.list-filter > .panel > .panel-content');
	var height = 0;
	if ($panelContent.length > 0) {
		height = $panelContent.height();
	}

	content.css('min-height', height + 'px');
}

$('.list').each(function(){
	var list = $(this);
	var hiddenInput = list.find('[name^="filter"]');
	var content = list.find('.list-content');
	var formSetter = list.find('a[data-form-name]');
	var listFilter = list.find('.list-filter-form-items');
	var minHeightHandler = function() {
		minHeight(list, content);
	};
	if(hiddenInput.attr('value') === 'open') {
		list.addClass('with-filter');
	}

	list.on('transitionend webkitTransitionEnd oTransitionEnd otransitionend', minHeightHandler);
	$(document).on('layout-changed', minHeightHandler);
	$(document).on('load', minHeight(list, content));

	list.find('.list-filter > .panel').on('panel-on', function() {
		list.addClass('with-filter');
		hiddenInput.attr('value', 'open');
	}).on('panel-off', function() {

		list.removeClass('with-filter');
		hiddenInput.attr('value', 'closed');
	});

	formSetter.on('click', function(ev) {
		ev.preventDefault();

		var setter = $(this);

		var form = $('form[name="' + setter.data('form') + '"]');

		form.find('[name="' + setter.data('form-name') + '"]').val([setter.data('form-value')]);

		form.submit();
	});

	list.find('.list-filter button[type=reset]').on('click', function(ev) {
		ev.preventDefault();

		var href = location.href;

		list.find('.list-filter input, .list-filter select, .list-filter textarea').each(function(){
			if(this.name) {
				var start = href.indexOf(this.name);

				if(start > -1) {
					var newRef = href.substring(0, start);

					var end = href.indexOf('&', start);
					if(end > -1) {
						newRef += href.substring(end + 1);
					}

					href = newRef;
				}
			}
		});

		if(href.lastIndexOf('?') === href.length - 1) {
			href = href.substr(0, href.length - 1);
		}

		location.href = href;
	});

});
