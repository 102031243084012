$('.documents').each(function(){
    var documents = $(this);
        var form = documents.find('.documents-form form');
        var counterVM = {
            documentsCounter: ko.observable(0),
        };

    documents.find('.documents-list .document').each(function(){
        var document = $(this);
            var versions = document.find('.document-versions');
            var trigger = document.find('.versions-trigger.js-trigger');

        trigger.on('click', function(ev){
            ev.preventDefault();

            trigger.toggleClass('toggle-on');
            versions.toggleClass('toggle-on');
        });
    });

	documents.find('.documents-actions a[data-form-name]').on('click', function(ev){
        ev.preventDefault();

        var setter = $(this);
        form.find('[name="' + setter.data('form-name') + '"]').val([setter.data('form-value')]);

        if (setter.hasClass('selectedDocuments')) {
            if (documents.find('input:checkbox:checked').length > 0) {
                form.submit();
            }
        } else {
            form.submit();
        }

    });

	documents.find('input:checkbox').on('change', function(ev){
        counterVM.documentsCounter(documents.find('input:checkbox:checked').length);

        if (documents.find('input:checkbox:checked').length == 0) {
            documents.find('.selectedDocuments').addClass('inactive');
        } else {
            documents.find('.selectedDocuments').removeClass('inactive');
        }
    });

    ko.applyBindings(counterVM, this);
});
