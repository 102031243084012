
// This functionality has been added to prevent multiple clicks on the place order button
// https://jira.belimo.ch/browse/GWS-865

(function() {
  var $placeOrderButton = $('#placeOrderForm1 #placeOrder');
  var $placeOrderForm = $placeOrderButton.closest('#placeOrderForm1');

  $placeOrderForm.submit(function() {
    $placeOrderButton.prop('disabled', true);
    return true;
  });

})();
