(function(){

    if($('.cart-name-current').length > 0) {
        init();
    }

    function init() {

        var ui = {
            $inputField: $('.cart-name-current-input'),
            $title: $('.cart-name-current-title'),
            $breadCrumbsTitle: $('.breadcrumbs ~ h1'),
            $errorDialog: $('.info-msgs-container').first(),
            $cartShare: $('.cart-footer .cart-actions .cart-share'),
            genericErrorMessage: $('.cart-name-current').attr('data-generic-error')
        };
        var state = {
            name: '',
            title: '',
            userAnonymous: $('.cart-name-current').attr('data-user-anonymous') === 'true',
            langIsoCode: $('.cart-name-current').attr('data-language')
        };

        function extractErrorMessages(data, fallBackErrorMsg) {
            if(data && data.error === 'true') {
                if(data.errorMessages && data.errorMessages.length && data.errorMessages.length > 0) {
                    return data.errorMessages.map(function(item) {
                        return item.errorMessage;
                    });
                }
                return [fallBackErrorMsg];
            }
            return null;
        }

        function setInitialState() {
            state.name = ui.$inputField.val();
            state.title = ui.$title.text().trim();
        }
        function setName(name) {
            if(name) {
                state.name = name.trim();
            }
        }

        function draw() {
            ui.$title.text(state.title);
            ui.$inputField.val(state.name);
            if(state.name) {
                ui.$breadCrumbsTitle.text(state.name);
                if(ui.$cartShare.length > 0) {
                    ui.$cartShare.show();
                }
            }
            Belimo.Cart.rename(state.name);
        }

        function postNewName(name, langIsoCode, $srcElem) {

            if($srcElem.parent().is('form')) {
                $srcElem.parent().submit();
                return;
            }

            var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
            var requestUrl = requestUriPrefix + '/shop/' + langIsoCode +'/cart/edit-name-ajax';

            $.post(
              requestUrl, {
                    name: name,
                })
                .done(function(data) {
                    var errorMessages = extractErrorMessages(data, ui.genericErrorMessage);
                    errorMessages
                        ? ui.$errorDialog.trigger('show-info-messages', [errorMessages, true])
                        : setName(name);
                    draw();
                })
                .fail(function (data) {
                    console.error((data.responseText ? data.responseText : (requestUrl + ' failed')));
                    ui.$errorDialog.trigger('show-info-messages', [[ui.genericErrorMessage], true]);
                    draw();
                });
        }

        function nameValidToPost(name) {
            return name && name.length > 0 && name !== state.name;
        }

        ui.$inputField.on('keyup', function (e) {

            if(e.keyCode === 13) {
                nameValidToPost(ui.$inputField.val())
                    ? postNewName(ui.$inputField.val(), state.langIsoCode, $(this))
                    : draw();
                return false;
            }
            if(e.key === 'Escape') {
                draw();
            }
        });

        ui.$inputField.on('blur', function() {
            nameValidToPost(ui.$inputField.val())
                ? postNewName(ui.$inputField.val(), state.langIsoCode, $(this))
                : draw();
        });

        setInitialState();
        draw();
    }
})();
