$('.form-input-file').each(function() {
	var container = $(this);
		var button = container.find('.form-control-select.js-trigger');
		var input = container.find('input');

	input.on('change', function(ev){
		var that = $(this);
			var val = that.val();
			var text = button.data('text') || button.text();
			var altText = button.data('alt-label');

		if(val) {
			container.find('.form-label-selected-file').text(val.split('\\').pop());
			button.text(altText);
		} else {
			container.find('.form-label-selected-file').text('');
			button.text(text);
		}

		button.data('text', text);
	});
});
