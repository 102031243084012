(function(){

    var interfaceObj = null;

    $(window).on('load', function() {
        var $sourceElem = $('.info-msgs-container');
        $sourceElem.on('show-info-messages', function(event, infoMessages, isError) {
            if(!interfaceObj) {
                interfaceObj = init();
            }
            interfaceObj.setSourceElem($sourceElem, isError);
            interfaceObj.setInfoMessages(infoMessages);
            interfaceObj.showModal();
        });
        $sourceElem.trigger('info-messages-loaded', []);
    });

    function init() {

        var state = {
            $sourceElem: null,
            infoMessages: null,
            isError: false,
            setSourceElem: function($sourceElem, isError) {
              this.$sourceElem = $sourceElem;
              this.isError = isError;
            },
            setInfoMessages: function(infoMessages) {
                this.infoMessages = infoMessages;
            }
        };

        var ui = {
            elems: {
                $infoMsgsContent: $('.info-msgs-content'),
                $infoMsgs: $('.info-msgs'),
                $actionClose: $('.info-msgs-ctrls button'),
                $overlay: $('.overlay'),
                $iconError: $('.info-msgs-content .info-icon-error'),
                $iconInfo: $('.info-msgs-content .info-icon-info'),
            },
            applyData: function(state) {

                this.elems.$iconError.css('display', state.isError ? 'block' : 'none');
                this.elems.$iconInfo.css('display', state.isError ? 'none' : 'block');
                this.elems.$infoMsgs.empty();
                for(var i = 0; i < state.infoMessages.length; i++) {
                    this.elems.$infoMsgs.append('<li>' + state.infoMessages[i] + '</li>');
                }
            },
            adjustStyling: function() {
                var $modalButtonContainer = $('.modal-actions');
                var $modalButton = $modalButtonContainer.find('button');

                var width = this.elems.$infoMsgsContent.find('svg').outerWidth() +
                    this.elems.$infoMsgsContent.find('.info-msgs-view').outerWidth();

                $modalButton.width(width);
                $modalButtonContainer.css('display', 'flex');
                $modalButtonContainer.css('justify-content', 'center');
            },
            show: function() {
                this.applyData(state);
                ui.elems.$overlay.css('pointer-events', 'none');

                var buttons = [];
                buttons.push({
                    html: this.elems.$actionClose.get(0).outerHTML,
                    callback: function () {
                        ui.elems.$overlay.css('pointer-events', 'auto');
                        Belimo.Modal.close();
                        state.$sourceElem.trigger('info-msgs-close', [state.isError]);
                    }
                });

                Belimo.Modal.open(this.elems.$infoMsgsContent, buttons);
                this.adjustStyling();
            },
            initHandler: function() {
                var _this = this;
                $(window).resize(function(){
                    _this.adjustStyling();
                });
            }
        };

        ui.initHandler();

        return {
            setSourceElem: state.setSourceElem.bind(state),
            setInfoMessages: state.setInfoMessages.bind(state),
            showModal: ui.show.bind(ui)
        };
    }
})();
