
$('.downloadcenter .category').click(function(ev) {
    ev.preventDefault();

    if(ev.target.tagName.toLowerCase() === 'a') {
        document.location.href = ev.target.href;
    }

    $('.downloadcenter .subcategories').width($(this).find('span').width() + 40);

    if ($(this).find('.subcategories').is(':visible') == true) {
    	$('.downloadcenter .subcategories').hide();
    } else {
    	$('.downloadcenter .subcategories').hide();
    	$(this).find('.subcategories').show();
    }
});

document.addEventListener('DOMContentLoaded', function(event) {

  var checkboxes = document.querySelectorAll('input[type=checkbox]');

  var $productDownloads = $('.product-downloads ~ .documents-footer');
  var $productDownloadWithSelectionButtons = $productDownloads.find('a.download-selected, a.share-by-email, a.add-selected');
  function setProductDownloadsButtonStates(disabled, $parentElem) {

      if($parentElem) {
          $productDownloads = $parentElem.find('.product-downloads ~ .documents-footer');
          $productDownloadWithSelectionButtons = $productDownloads.find('a.download-selected, a.share-by-email, a.add-selected');
      }

      disabled
        ? $productDownloadWithSelectionButtons.addClass('disabled')
        : $productDownloadWithSelectionButtons.removeClass('disabled');
  }

  var getSelectedAsList = function($parentElem) {
      var codes = [];
      if($parentElem) {
          $parentElem.find('input[name=selected-documents]:checkbox:checked').each(function() {
              codes.push($(this).val());
          });
      }
      else {
          $('input[name=selected-documents]:checkbox:checked').each(function() {
              codes.push($(this).val());
          });
      }
    return codes.join(',');
  };

  function showNoSelectionModalIf(codeList) {

      if(!codeList || codeList === '' || !codeList.length || codeList.length === 0) {

          var errorText = $('div[data-error-message-no-selection]').first().attr('data-error-message-no-selection');
          if(!errorText) errorText = 'Please select at least one document';

          var buttons = [];
          buttons.push({
              label: 'Cancel',
              type: 'submit',
              callback: Belimo.Modal.close
          });
          Belimo.Modal.open(errorText, buttons);
          return true;
      }
      return false;
  }

  var setActionLinks = function($parentElem) {

      var sList = $parentElem
          ? getSelectedAsList($parentElem.closest('.cart-entry'))
          : getSelectedAsList();


   if(!sList.length) {
      $('.data-list .link.select_related').addClass('disabled');
       setProductDownloadsButtonStates(true, $parentElem);

   } else {
      $('.data-list .link.select_related').removeClass('disabled');
       setProductDownloadsButtonStates(false, $parentElem);
   }
  };

  $('a.delete_selected').on('click', function(e) {

      var codes = getSelectedAsList();
      if(showNoSelectionModalIf(codes)) return;

      $('.cart-actions-panel').trigger('panel-off');
      var language = $(this).data('language');

      var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
      var requestUrl = requestUriPrefix + '/shop/' + language + '/downloadcenter/downloadfolder/delete-entries';

      e.preventDefault();
      $.ajax({
          data: {'codes': codes},
          success: function(data){
              location.href = 'downloadfolder';
          },
          type: 'POST',
          url: requestUrl
      });

      return false;
  });

  $('a.download-complete').on('click', function(e) {
    var language = $(this).data('language');
    $('.cart-actions-panel').trigger('panel-off');

    var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
    var requestUrl = requestUriPrefix + '/shop/' + language + '/downloadcenter/download/document?type=ALL';

    var form = $('form[name=downloadComplete]');
    $(form).attr('target', 'download-complete-iframe');
    $(form).attr('action', requestUrl);
    $(form).submit();

    return false;
  });

  $('a.download-selected').on('click', function(e) {

      e.preventDefault();

      var isCartPage = $('.page-cartPage').length > 0;

      var codeList = isCartPage
          ? getSelectedAsList($(this).closest('.cart-entry'))
          : getSelectedAsList();

      if(showNoSelectionModalIf(codeList)) return;

      $('.cart-actions-panel').trigger('panel-off');
      var language = $(this).data('language');

      var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
      var requestUrl = requestUriPrefix + '/shop/' + language + '/downloadcenter/download/document?type=SELECTED&codes=' + codeList;

      var form = isCartPage
          ? $(this).closest('.cart-entry').find('form[name=downloadSelected]')
          : $('form[name=downloadSelected]');

      var targetOrig = $(form).attr('target');
      $(form).attr('action', requestUrl);
      $(form).attr('target', '_self');
      $(form).submit();
      $(form).attr('target', targetOrig);
      return false;
  });

  $('a.share-by-email').on('click', function(e) {

      var isCartPage = $('.page-cartPage').length > 0;

      var code = $(this).data('code');
      if(code === 0 || code) code += '';
      var codeList;
      if(code) {
          codeList = code;
      }
      else {
          codeList = isCartPage
              ? getSelectedAsList($(this).closest('.cart-entry'))
              : getSelectedAsList();
      }

      if(showNoSelectionModalIf(codeList)) return;

      $('.cart-actions-panel').trigger('panel-off');
      var language = $(this).data('language');

      var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
      var requestUrl = requestUriPrefix + '/shop/' + language + '/downloadcenter/share/?codes='+codeList;

      $.get(requestUrl, function(result) {

          window.location.href = 'mailto:?subject=' + result.emailSubject + '&body=' + encodeURIComponent(result.emailBody);
      });
      return false;
  });

    $('a.add-selected').on('click', function (e) {

        var isCartPage = $('.page-cartPage').length > 0;

        var code = $(this).data('code');
        if (code === 0 || code) code += '';

        var codeList;
        if (code) {
            codeList = code;
        }
        else {
            codeList = isCartPage
                ? getSelectedAsList($(this).closest('.cart-entry'))
                : getSelectedAsList();
        }

        if (showNoSelectionModalIf(codeList)) return;

        var language = $(this).data('language');

        var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
        var requestUrl = requestUriPrefix + '/shop/' + language + '/downloadcenter/downloadfolder/add-selected';

        $.ajax({
            method: 'POST',
            url: requestUrl,
            data: {
                codes: codeList
            }
        }).done(function (data) {

            function displayResultIf(resultData) {
                if (!resultData) return;

                var messagesHtml = '';
                if (resultData.errorMessage) {
                    messagesHtml += '<p class="downloadcenter-add">' + errorData.errorMessage + '</p>';
                }
                else if (resultData.multipleErrorMessages && resultData.multipleErrorMessages.length) {
                    for (var i = 0; i < resultData.multipleErrorMessages.length; i++) {
                        messagesHtml += '<p class="downloadcenter-add">' + resultData.multipleErrorMessages[i].message + '</p>';
                    }
                }
                if (messagesHtml === '') return;

                var buttons = [];

                if(resultData.viewDownloadFolderUrl) {

                    buttons.push({
                        label: resultData.viewDownloadFolderButtonText ? resultData.viewDownloadFolderButtonText : 'View Download Folder',
                        type: 'button',
                        callback: () => {
                            window.location.assign(resultData.viewDownloadFolderUrl);
                        }
                    });
                }
                buttons.push({
                    label: resultData.closeButtonText ? resultData.closeButtonText : 'Close',
                    type: 'button',
                    callback: Belimo.Modal.close
                });
                Belimo.Modal.open(messagesHtml, buttons);
            }

            Belimo.Downloadfolder.update(data.entries || []);
            displayResultIf(data);

        }).fail(function (error) {
            console.error((error ? error : 'Unknown error'));
        });
        return false;
    });

  var toggleAll = function(check) {
    for (i = 0; i < checkboxes.length; ++i) {
      checkboxes[i].checked = check;
    }

    setActionLinks();
  };

  for (var i = 0; i < checkboxes.length; ++i) {
    checkboxes[i].addEventListener('change', function() {

        var $parentElem = $('.page-cartPage').length > 0
            ? $(this).closest('.cart-entry')
            : null;

        setActionLinks($parentElem);
      if (this.name === 'selectAll') {
        toggleAll(this.checked);
      }


    });
  }

  $('.deleteall').click(function(e) {
    e.preventDefault();
    $('.cart-actions-panel').trigger('panel-off');
    $.post( $(this).data('href'), function( data ) {
        location.href = 'downloadfolder';
    });
  });

  $('.deleteSingle').click(function(e) {
    e.preventDefault();
    $('.cart-actions-panel').trigger('panel-off');
    var url = $(this).data('href');
    $.ajax({
        data: {'entry': $(this).data('entryid')},
        success: function(data){
	        location.href = 'downloadfolder';
        },
      type: 'POST',
        url: url
    });
  });


    // Adjust layout of document links on desktop if the flex container wraps.
    function adjustDocumentLinksContainer() {
        $('.documents-link-container').each(function() {
            var $children = $(this).children();
            if($children.length > 0) {
                if($children.first().offset().top !== $children.last().offset().top) {
                    $(this).addClass('fix-flex-wrap');
                }
                else {
                    $(this).removeClass('fix-flex-wrap');
                }
            }
        });
    }
    if($('.documents-link-container').length > 0) {
        adjustDocumentLinksContainer();
        $(document).on('layout-changed', function(){
            adjustDocumentLinksContainer();
        });
    }

    // The actions panel only has content in last of three columns.
    // This is true for mobile/tablet as well (even though there are no three columns there).
    // Clicking outside of the actions panel should close the panel.
    // As the first two columns are always empty and transparent, this area should be considered as outside as well.
    // Therefore, this code snippet closes the action panel if clicking in one of the first two columns.
    // If clicking outside of either column, the overlay handler manages the closing of the actions panel.
    $('.cart-actions-panel .panel-content .cart-actions').click(function(e) {
        if($('.cart-actions-panel').hasClass('toggle-on') && $(e.target).hasClass('two-third')) {
            $('.cart-actions-panel').trigger('panel-off');
        }
    });

    $('.documents-actions a.goto-downloads').each(function(){
        var $srcLink = $(this);
        var downloadCenterUrl = $(this).attr('data-download-center');
        var downloadFolderUrl = $(this).attr('data-download-folder');
        function setLinkByAssets(assets) {
            (!assets || !assets.length)
                ? $srcLink.attr('href', downloadCenterUrl)
                : $srcLink.attr('href', downloadFolderUrl);
        }
        $(document).on('downloadfolder-update', function(ev, assets) {
            setLinkByAssets(assets);
        });
    });


    $(window).on('load', function(){
        setActionLinks();
    });

});
