$('.search-field').each(function(){
	var search = $(this);
	var input = search.find('input[type=text]');

	search.find('.search-field-clear.js-trigger').on('click', function(ev) {
		input.val([]);
		input.focus();
	});
});


$('.search-open-close button').click(function(e) {

	if($(this).hasClass('search-open')) {

		$('.search-show-toggle').addClass('open');
		$('.search-open-close button.search-open').hide();
		$('.search-open-close button.search-close').css('display', 'inline-block');
		$('.search-open-close button.search-close').show();

		$('.navigation .level-1.link-component').css('visibility', 'hidden');
		$('.navigation .level-1.link-component').hide();
		$('.list-level-1 .search-opened').css('display', 'block');
		$('.list-level-1 .search-opened').show();
		$('.list-level-1 .search-opened .search-field input').focus();
	}
	else {
		$('.search-show-toggle').removeClass('open');
		$('.search-open-close button.search-close').hide();
		$('.search-open-close button.search-open').css('display', 'inline-block');
		$('.search-open-close button.search-open').show();

		$('.navigation .level-1.link-component').css('visibility', 'visible');
		$('.navigation .level-1.link-component').show();
		$('.list-level-1 .search-opened').hide();
	}
});

