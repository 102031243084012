$('.form.add-to-downloadfolder-form').each(function(){
	var form = $(this).find('form');

	form.on('submit', function(ev){
		ev.preventDefault();

		var url = form.attr('action');
		var data = form.serializeArray();

		Belimo.Downloadfolder.submit(url, data, function() {
			form.find('button').attr('disabled', 'disabled');
		});
		return false;
	});
});

$('.add-to-downloadfolder-link').each(function(){
	$(this).on('click', function(ev){
		ev.preventDefault();

		var url = $(this).attr('href');
		var data = [{
				    name: 'documentCode',
				    value: $(this).data('csid')
				  }];
		Belimo.Downloadfolder.submit(url, data);
		$(this).html($(this).data('added'));
		$(this).addClass('orange');
		return false;
	});
});
