function createTerm(data) {
	if (data.term) {
        data.fullurl = encodeURIComponent(data.term.replace(/<(?:.|\n)*?>/gm, ''));
    } else {
		data.fullurl = '';
	}
	var autosuggest = ko.mapping.fromJS(data);

	return autosuggest;
}

function hasDiscontinuedProducts(suggestions) {
	if(!suggestions) return false;
	return suggestions.filter((s) => s.type && s.type === 'discontinued product').length > 0;
}


Belimo.Search.addView('suggestions', function(rootView) {
	var view = this;

	view.root = rootView;
	view.element = view.root.element.find('.search-result-group-products');
	view.config = view.element.data();
	view.suggestions = ko.observableArray([]);
	view.searching = ko.observable(false);
  	view.searchTerm = ko.observable(view.root[Belimo.Search.SEARCH_VIEW].searchTerm());

	const $dcProductContainer = view.element.find('.discontinued-products');

	const $searchContent = view.root.element.find('.search-content');
	const $searchFieldInput = view.root.element.find('.search-field input');
	let $searchResultsLinks = view.root.element.find('.search-result-group-products a');
	const $searchAutosuggestContent = $('.search-autosuggest .search-content');
	let productData = null;
	let keyDown = false;


	let testCount = 0;

	$searchFieldInput.on('keydown', function(e) {
		if (e.keyCode == 40) { // down
			keyDown = true;
			e.preventDefault();
			e.stopPropagation();
			$searchResultsLinks = view.root.element.find('.search-result-group-products a');
			$searchResultsLinks.first().get(0).focus();
			return false;
		}
		if (e.keyCode == 38) { // up
			keyDown = true;
			e.preventDefault();
			e.stopPropagation();
			$searchResultsLinks = view.root.element.find('.search-result-group-products a');
			$searchResultsLinks.last().get(0).focus();
			return false;
		}		
		else if(e.keyCode == 27) { // escape
			$searchContent.hide();
			$searchAutosuggestContent.hide();
			$dcProductContainer.hide();
		}
	});

	$searchFieldInput.on('mousedown', function(e) {
		$(this).focus();
	});

	$searchFieldInput.on('blur', function(ev) {
		if(keyDown) {
			keyDown = false;
			return;
		}
		$searchContent.hide();
		$searchAutosuggestContent.hide();
		$dcProductContainer.hide();
	});


	ko.computed(function() {
		if(view.element.length > 0) {
			var val = view.root[Belimo.Search.SEARCH_VIEW].searchTerm();

			if(!val || val.length < view.config.minChars) {
				view.suggestions.removeAll();
				$dcProductContainer.hide();
				productData = null;
			}
			else {
				view.searching(true);
				$.getJSON(view.config.url.replace(/\$searchTerm/, encodeURIComponent(val)), function(resultData) {
				
					productData = resultData;

					view.searching(false);
					view.searchTerm(encodeURI(val));
					view.suggestions.removeAll();
					for(let i = 0; i < productData.suggestions.length; i++) {
						view.suggestions.push(createTerm(productData.suggestions[i]));
					}
					view.element.find('a').on('keydown', function(e) {
						if (e.keyCode == 40) { // arrow down
							keyDown = true;
							e.preventDefault();
							
							function findNext($elem) {
								let nextElem = $elem.next();
								if(nextElem.hasClass('discontinued-products')) {
									nextElem = nextElem.find('a').first();
								}
								if(nextElem.length == 0) {
									$searchResultsLinks.first().get(0).focus();
								}
								return nextElem;
							}
							let nextElem = findNext($(this));
							while(nextElem.is(':hidden')) {
								nextElem = findNext(nextElem);
							}
							nextElem.focus();
						}
						else if (e.keyCode == 38) { // arrow up
							keyDown = true;
							e.preventDefault();

							function findPrev($elem) {
								let prevElem = $elem.prev();
								if(prevElem.hasClass('discontinued-products-title')) {
									prevElem = prevElem.closest('.search-result-group').find('>a').last();
								}
								if(prevElem.length == 0) {
									$searchResultsLinks.last().get(0).focus();
								}
								return prevElem;
							}
							let prevElem = findPrev($(this));
							while(prevElem.is(':hidden')) {
								prevElem = findPrev(prevElem);
							}
							prevElem.focus();
						}
						else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode == 27 || e.keyCode == 8) {
							$searchFieldInput.focus();
						}

				  	});

					if($searchFieldInput.is(':focus')) {

						productData && hasDiscontinuedProducts(productData.suggestions)
							? $dcProductContainer.show()
							: $dcProductContainer.hide();
						$searchContent.show();
					}


					$searchFieldInput.off('focus').on('focus', function() {
						productData && hasDiscontinuedProducts(productData.suggestions)
							? $dcProductContainer.show()
							: $dcProductContainer.hide();
						$searchContent.show();
					});

					// Used Mousedown because click is triggered after blur
					$searchResultsLinks = view.root.element.find('.search-result-group-products a');

					function onChooseSuggestion($resultLink) {
						const inputText = $resultLink.text().trim();
						if ($resultLink.hasClass('autocomplete')) {
							$resultLink.attr('href', '#');
							$resultLink.parents('form').find('.search-field input[type=text]').val(`"${inputText}"`);
							$resultLink.parents('.form').find('.search-field input[type=text]').val(`"${inputText}"`);
							$resultLink.trigger('blur');
						}
						else {
							$searchFieldInput.val(inputText);
							window.location.href = $resultLink.attr('href');
						}
					}

					$searchResultsLinks.on('mousedown', function() {
						onChooseSuggestion($(this));
					});
					$searchResultsLinks.on('keydown', function(e) {
						if(e.keyCode == 13) {
							onChooseSuggestion($(this));
						}
					});

					$searchResultsLinks.off('blur').on('blur', function(ev) {
						if(keyDown) {
							keyDown = false;
							return;
						}
						$searchContent.hide();
						$searchAutosuggestContent.hide();
						$dcProductContainer.hide();
					});

				});
			}
		}
	}).extend({deferred: true});
});
