$(document).ready(function () {
    const config = $('#addressVerificationConfig'),
        verificationEnabled = config.data('address-verification-enabled'),
        verificationCurrentStoreEnabled = config.data('address-verification-current-store-enabled'),
        key = config.data('address-verification-key'),
        findUrl = config.data('address-suggestion-find-url'),
        retrieveUrl = config.data('address-suggestion-retrieve-url'),
        addressForm = $('.account-section').find('#belimoAddressForm'),
        addressLine1 = addressForm.find('#address\\.line1');
    const countryIso = addressForm.find('#address\\.country').find(":selected").val();
    if (!countryIso) {
        // disable address line1 input if country is not selected
        addressForm.find('#address\\.line1').prop('disabled', true);
        addressForm.find('#address\\.country').on('change', function (e) {
            addressForm.find('#address\\.line1').prop('disabled', false);
        });
    }

    defaultAutocomplete();

    function defaultAutocomplete() {
        if (verificationEnabled && verificationCurrentStoreEnabled) {
            addressLine1.autocomplete({
                source: function (request, response) {
                    $.ajax({
                        url: findUrl,
                        method: 'POST',
                        data: {
                            Key: key,
                            Text: request.term,
                            IsMiddleware: false,
                            Countries: addressForm.find(":selected").val(),
                        },
                        success: function (data) {
                            let suggestionList = [];
                            // Test for an error
                            if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
                                // Show the error message
                                console.warn(data.Items[0].Description);
                            } else {
                                // Check if there were any items found
                                if (data.Items.length == 0)
                                    console.info("No address suggestion for input: " + request.term);
                                else {
                                    $.each(data.Items, function (index, value) {
                                        suggestionList.push({
                                            id: this.Id,
                                            type: this.Type,
                                            label: this.Text + ', ' + this.Description,
                                            value: this.Text
                                        })
                                    });
                                }
                            }
                            response(suggestionList);
                        }
                    });
                }
                ,
                minLength: 3,
                select: function (event, ui) {
                    addressLine1.data('type', ui.item.type);
                    if (ui.item.type === 'Address') {
                        retrieve(ui.item.id);
                    } else {
                        drillDown(ui.item.id);
                    }
                },
                close: function (event, ui) {
                    if (addressLine1.data('type') !== 'Address') {
                        addressLine1.autocomplete('search', '');
                    }
                },
                classes: {
                    "ui-autocomplete": "address-suggestions"
                }
            });
        }
    }

    function retrieve(id) {
        $.ajax({
            url: retrieveUrl,
            method: 'POST',
            data: {
                Key: key,
                Id: id,
            },
            success: function (data) {
                let result = [];
                // Test for an error
                if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
                    // Show the error message
                    console.warn('Loqate Retrieve API error: ' + data.Items[0].Description);
                } else {
                    // Check if there were any items found
                    if (data.Items.length == 0)
                        console.warn('Loqate Retrieve API error for id: ' + id);
                    else {
                        $.each(data.Items, function (index, value) {
                            let address = this.Line1 + ' ' + this.Line2;
                            addressForm.find('#address\\.line1').val(address.trim());
                            addressForm.find('#address\\.townCity').val(this.City);
                            addressForm.find('#address\\.postcode').val(this.PostalCode);

                            $('select[data-arv-ajax-url]').select2().val(this.CountryIso2 + '-' + this.ProvinceCode).trigger('change');
                            // remove any existing input field error suggestion labels, if any
                            addressForm.find('.form-input-text.required.error').each(function (){
                                $(this).removeClass('error');
                            });

                        });
                    }
                }
            }
        });
        defaultAutocomplete();
    }

    function drillDown(id) {
        addressLine1.autocomplete({
            source: function (request, response) {
                $.ajax({
                    url: findUrl,
                    method: 'POST',
                    data: {
                        Key: key,
                        Container: id,
                        IsMiddleware: false,
                        Countries: addressForm.find(":selected").val(),
                    },
                    success: function (data) {
                        let suggestionList = [];
                        // Test for an error
                        if (data.Items.length == 1 && typeof (data.Items[0].Error) != "undefined") {
                            // Show the error message
                            console.warn(data.Items[0].Description);
                        } else {
                            // Check if there were any items found
                            if (data.Items.length == 0)
                                console.info("No address suggestion for container id: " + id);
                            else {
                                $.each(data.Items, function (index, value) {
                                    let textString = this.Text.toLowerCase();
                                    let searchTerm = request.term.toLowerCase();
                                    // adding local filtering logic as Loqate doesn't filter results for drill-down case
                                    if(textString.includes(searchTerm)) {
                                        suggestionList.push({
                                            id: this.Id,
                                            type: this.Type,
                                            label: this.Text + ', ' + this.Description,
                                            value: this.Text
                                        })
                                    }
                                });
                            }
                        }
                        response(suggestionList);
                    }
                });
            }
            ,
            minLength: 0,
            select: function (event, ui) {
                addressLine1.data('type', ui.item.type);
                if (ui.item.type === 'Address') {
                    retrieve(ui.item.id);
                } else {
                    drillDown(ui.item.id);
                }
            },
            close: function (event, ui) {
                if (addressLine1.data('type') !== 'Address') {
                    addressLine1.autocomplete('search', '');
                }
                defaultAutocomplete();
            },
            classes: {
                "ui-autocomplete": "address-suggestions"
            }
        });
    }


});