$('.intlanguage .button').click(function() {

	if($('.panel-burger').is(':visible')) {
		$('.panel-burger').addClass('toggle-on')
		$('.panel-burger .panel-content .navigation .list-level-1 .tab-contents > .js-trigger').first().click()
	}
	else {
		$('.choose-location .panel-trigger').click();
	}

	$('.intlanguage').hide();
	window.setCookie('forceInternational', true);
});

$('.intlanguage .close').click(function() {
	$('.intlanguage').hide();
	window.setCookie('forceInternational', true);
});

if (Cookies.get('forceInternational') == undefined) {
	$('.intlanguage').show();
}



