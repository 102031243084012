
window.ppl = {
    interfaceObj: null,
    getInterfaceObj: function() {
        if(!this.interfaceObj) {
            this.interfaceObj = init();
            this.interfaceObj.initView();
        }
        return this.interfaceObj;
    }
};

function init() {

    // /////////////////////////////
    // Prototypes
    // /////////////////////////////

    function Project(id, name, active) {
        this.id = id;
        this.name = name;
        this.checked = false;
        this.active = active ? true : false;
    }

    Project.prototype.toggleChecked = function() {
        this.checked = !this.checked;
    };
    Project.prototype.setChecked = function(isChecked) {
        this.checked = isChecked;
    };

    function stateChanged() {
        ui.draw(state);
    }

    function addProduct() {
        state.dataModel.action_addProductToProjects(
            state.dataModel,
            state.displayModel,
            ui.elems.$popupWrapper,
            function() {
                return ui.elems.$popupErrorMessage.attr(ui.consts.dataAttrGenericAddProductErrorMsg);
            });
    }
    function addProducts() {
        state.dataModel.action_addProductsToProjects(
            state.dataModel,
            state.displayModel,
            ui.elems.$popupWrapper,
            function() {
                return ui.elems.$popupErrorMessage.attr(ui.consts.dataAttrGenericAddProductErrorMsg);
            });
    }
    function extractErrorMessage(resultObj) {
        if(!resultObj) return '';
        var errorMsg = resultObj.genericErrorMessage;
        if(resultObj.errorMsgs && resultObj.errorMsgs.length && resultObj.errorMsgs.length > 0) {
            errorMsg = resultObj.errorMsgs[resultObj.errorMsgs.length - 1];
        }
        else if(resultObj.errorMsg) {
            errorMsg = resultObj.errorMsg;
        }
        if(!errorMsg) return '';
        return errorMsg;
    }

    var state = {
        dataModel: {
            type: null, // 'std' | 'sizingAndSelection'
            product: null, // for std mode
            products: [], // for sizingAndSelection type, array of products
            newProjectListName: null,
            projectList: [],
            errorRetrievingData: '',
            errorAddProduct: '',
            languageIsoCode: '',
            requestUriPrefix: window && window.countryCode ? window.countryCode : '',
            activeText: '',
            showCfrmModalFn: null, // this is for sizing and selection only.
            setTypeStd: function() {
                this.type = 'std';
            },
            setTypeSizingAndSelection: function() {
                this.type = 'sizingAndSelection';
            },
            isTypeStd: function() {
                return this.type === 'std';
            },
            isTypeSizingAndSelection: function() {
                return this.type === 'sizingAndSelection';
            },
            getProjectListDisplayName: function(name, active) {
              if(!this.activeText || !active) return name;
              return '(' + this.activeText + ') ' + name;
            },
            reset: function() {
                this.product = null;
                this.products = [];
                this.newProjectListName = null;
                this.projectList = [];
            },
            setProduct: function(product) {
                this.product = product;
            },
            setProjectList: function(projectList) {
                this.projectList = projectList;
                stateChanged();
            },
            setProducts: function(products) {
                this.products = products;
            },
            deselectAllProjects: function(callStateChanged) {
                var changed = false;
                for (var i = 0; i < this.projectList.length; i++) {
                    if (this.projectList[i].checked) changed = true;
                    this.projectList[i].checked = false;
                }
                if (changed && callStateChanged) {
                    stateChanged();
                }
            },
            action_retrieveProjectList: function(displayModel, getGenericErrorMessageFn) {
                var _this = this;
                displayModel.setRetrievingProjects(true);

                $.ajax({
                    url: state.dataModel.getAbsUrl('project-lists'),
                    dataType: 'json',
                    method: 'GET'
                }).done(function(jsonData) {

                    displayModel.setRetrievingProjects(false);
                    if(jsonData && jsonData.errors && jsonData.errors.length > 0) {
                        _this.setErrorRetrievingData(jsonData.errors[0].error);
                        _this.setProjectList([]);
                    }
                    else {
                        var projectList = [];
                        for (var i = 0; i < jsonData.length; i++) {
                            projectList.push(new Project(jsonData[i].code, jsonData[i].name, jsonData[i].opened));
                        }

                        _this.setErrorRetrievingData('');
                        _this.setProjectList(projectList);
                    }
                }).fail(function(xhr) {
                    console.error(xhr.responseText);
                    _this.setProjectList([]);
                    displayModel.setRetrievingProjects(false);
                    if (getGenericErrorMessageFn) {
                        _this.setErrorRetrievingData(getGenericErrorMessageFn());
                    }
                });
            },

            action_addToProjects: function(eventName, dataModel, displayModel, $wrapperMain, getGenericErrorMessageFn) {
                var doneFn = function(resultObj) {
                    displayModel.setAddingProduct(false);

                    if(resultObj.success) {
                        dataModel.setErrorAddProduct('');
                        dataModel.reset();
                        $wrapperMain.trigger('popup-close');
                    }
                    else {
                        dataModel.setErrorAddProduct(extractErrorMessage(resultObj));
                    }
                };

                var params = {
                    selectedProjectNames: this.selectedProjects(),
                    activeCartSelected: this.isActiveCartSelected(),
                    newProjectName: state.dataModel.newProjectListName,
                    product: state.dataModel.product,
                    products: state.dataModel.products,
                    startCb: function() { displayModel.setAddingProduct(true); },
                    doneCb: doneFn
                };

                var resultObj = {
                    success: false,
                    errorMsg: null,
                    errorMsgs: null,
                    genericErrorMessage: getGenericErrorMessageFn()
                };

                ui.$sourceElem.trigger(eventName, [params, resultObj]);
            },

            action_addProductToProjects: function(dataModel, displayModel, $wrapperMain, getGenericErrorMessageFn) {
                this.action_addToProjects(
                    'add-product', dataModel, displayModel, $wrapperMain, getGenericErrorMessageFn);
            },

            action_addProductsToProjects: function(dataModel, displayModel, $wrapperMain, getGenericErrorMessageFn) {
                this.action_addToProjects(
                    'add-products', dataModel, displayModel, $wrapperMain, getGenericErrorMessageFn);
            },

            setNewProjectListName: function(productListName) {
                if (this.newProjectListName == productListName) return;
                this.newProjectListName = productListName;
                this.deselectAllProjects(false);
                stateChanged();
            },
            toggleProjectChecked: function(projectId) {

                for(var i = 0; i < this.projectList.length; i++) {
                    if(this.projectList[i].id == projectId) {
                        this.projectList[i].toggleChecked();
                        this.newProjectListName = null;
                        stateChanged();
                        break;
                    }
                }

            },
            canRunMainAction: function() {

                var _this = this;

                function hasCheckedProjectList() {
                    for (var i = 0; i < _this.projectList.length; i++) {
                        if (_this.projectList[i].checked) return true;
                    }
                    return false;
                }

                if(this.isTypeStd() || this.isTypeSizingAndSelection()) {
                    if(this.newProjectListName) return true;
                    if(hasCheckedProjectList()) return true;
                }
                return false;
            },
            selectedProjects: function() {
                var selectedProjects = [];
                for(var i = 0; i < this.projectList.length; i++) {
                    if(this.projectList[i].checked) {
                        selectedProjects.push(this.projectList[i].name);
                    }
                }
                return selectedProjects;
            },
            isActiveCartSelected: function() {
                for(var i = 0; i < this.projectList.length; i++) {
                    if(this.projectList[i].checked && this.projectList[i].active) {
                        return true;
                    }
                }
                return false;
            },
            setErrorRetrievingData: function(msg) {
                this.errorRetrievingData = msg;
                stateChanged();
            },
            setErrorAddProduct: function(msg) {
                this.errorAddProduct = msg;
                stateChanged();
            },
            clearErrorMessages: function(callStateChanged) {
                this.errorRetrievingData = '';
                this.errorAddProduct = '';
                if(callStateChanged) stateChanged();
            },
            setLanguageIsoCode: function(languageIsoCode) {
                this.languageIsoCode = languageIsoCode;
            },
            setActiveText: function(activeText) {
                this.activeText = activeText;
            },
            getBaseUrl: function() {
                return this.requestUriPrefix + '/shop/' + (this.languageIsoCode ? this.languageIsoCode + '/' : '');
            },
            getAbsUrl: function(urlPart) {
                return this.getBaseUrl() + urlPart;
            }
        },
        displayModel: {
            projectListShowAll: false,
            projectListMaxItemDisplay: 3,
            retrievingProjects: false,
            addingProduct: false,
            restoringCart: false,

            toggleAccordion: function() {
                this.projectListShowAll = !this.projectListShowAll;
                stateChanged();
            },
            setRetrievingProjects: function(retrievingProjects) {
                this.retrievingProjects = retrievingProjects;
                stateChanged();
            },
            setAddingProduct: function(addingProduct) {
                this.addingProduct = addingProduct;
                stateChanged();
            },
            canRunMainAction: function() {
                return !this.addingProduct &&
                    !this.retrievingProjects &&
                    !this.restoringCart;
            }
        }
    };

    var ui = {
        consts: {
            classNameProjectItem: 'ppl-project-item',
            classNameProjectItemDefault: 'ppl-project-item-default',
            dataAttrNameProjectItemId: 'data-project-item-id',
            dataAttrDynamicText: 'data-ppl-id-text',
            dataAttrGenericGetProjectsErrorMsg: 'data-ppl-id-error-get-projects',
            dataAttrGenericAddProductErrorMsg: 'data-ppl-id-error-add-product',
            ataAttrGenericAddProductsErrorMsg: 'data-ppl-id-error-add-products'
        },
        elems: {
            $popupWrapper: $('.ppl-id-main'),

            $popup: $('.ppl'),
            $popupActionClose: $('.ppl-id-action-close'),
            $projectItemsMinContainer: $('.ppl-items-min-container'),
            $projectItemsAllContainer: $('.ppl-items-all-container'),
            $projectListItemDefault: $('.ppl-project-item-default'),
            $projectListAccordionIcon: $('.ppl-accordion-icon'),
            $projectListAccordionAction: $('.ppl-accordion-action'),
            $projectListAccordion: $('.ppl-accordion'),
            $projectNameInputValue: $('.ppl-id-new-project-list-name'),
            $projectListAdd: $('.ppl-id-action-add'),

            $popupAllProjects: $('.ppl-id-all-projects'),
            $popupProjectsLoading: $('.ppl-id-projects-loading'),
            $popupNoExistingProjects: $('.ppl-id-no-existing-projects'),
            $popupCtrlsSpinner: $('.ppl-id-main-ctrls-spinner'),

            $popupErrorMessage: $('.ppl-id-error-message'),
            $sourceElem: null,
        },
        setSourceElem: function($sourceElem) {
            this.$sourceElem = $sourceElem;
        },
        setPopupWrapperStd: function() {
            this.elems.$popupWrapper = $('.ppl-id-main');
        },
        setPopupWrapperSizeAndSelection: function() {
            this.elems.$popupWrapper = $('.ppl-id-size-and-selection');
        },
        $createProjectElement: function(state, projectId, projectName, checked, active) {

            var $projectListItem = this.elems.$projectListItemDefault.clone(true, true);
            $projectListItem.removeClass(this.consts.classNameProjectItemDefault);
            $projectListItem.addClass(this.consts.classNameProjectItem);
            $projectListItem.attr(this.consts.dataAttrNameProjectItemId, projectId);
            $projectListItem.css('visibility', 'visible');

            var $checkbox = $projectListItem.find('.ppl-id-action-select');

            $checkbox.attr('name', projectId);
            $checkbox.attr('value', projectId);
            $checkbox.attr(this.consts.dataAttrNameProjectItemId, projectId);
            $checkbox.prop('checked', checked);

            var $label = $projectListItem.find('label');
            $label.text(state.dataModel.getProjectListDisplayName(projectName, active));
            $label.attr('for', projectId);

            $projectListItem.click(function() {
                state.dataModel.toggleProjectChecked(projectId);
            });

            return $projectListItem;
        },
        removeProjectItems: function() {
            $('.' + this.consts.classNameProjectItem).remove();
        },
        attachProjectItemsMin: function(state) {
            for (var i = 0; i < state.dataModel.projectList.length; i++) {

                if (i >= state.displayModel.projectListMaxItemDisplay) return;

                var projectItem = state.dataModel.projectList[i];
                var $projectItem = this.$createProjectElement(
                    state, projectItem.id, projectItem.name, projectItem.checked, projectItem.active);
                this.elems.$projectItemsMinContainer.append($projectItem);
            }
        },
        attachProjectItemsAll: function(state) {
            for (var i = state.displayModel.projectListMaxItemDisplay; i < state.dataModel.projectList.length; i++) {
                var projectItem = state.dataModel.projectList[i];
                var $projectItem = this.$createProjectElement(
                    state, projectItem.id, projectItem.name, projectItem.checked);
                this.elems.$projectItemsAllContainer.append($projectItem);
            }
        },

        initHandlers: function(elems, consts, state) {
            var _this = this;

            elems.$projectListAccordionAction.click(function() {
                state.displayModel.toggleAccordion();
            });
            elems.$projectNameInputValue.on('input', function() {
                state.dataModel.setNewProjectListName($(this).val());
            });

            elems.$projectNameInputValue.keyup(function(e) {
                if(e.keyCode === 13 && state.dataModel.canRunMainAction() && state.displayModel.canRunMainAction()) {
                    if(state.dataModel.isTypeStd()) {
                        addProduct();
                    }
                    else if(state.dataModel.isTypeSizingAndSelection()) {
                        addProducts();
                    }
                }
            });

            elems.$projectListAdd.click(function() {

                if(state.dataModel.isTypeStd()) {
                    addProduct();
                }
                else if(state.dataModel.isTypeSizingAndSelection()) {
                    addProducts();
                }
            });

            elems.$popupActionClose.click(function() {
                elems.$popupWrapper.trigger('popup-close');
            });

            elems.$popup.on('popup-update', function(e) {
                _this.draw(state);
                e.stopPropagation();
            });
        },

        openPopup: function() {
            var _this = this;
            this.elems.$popupWrapper.trigger('popup-open', [function() {
                _this.elems.$projectNameInputValue.val('');
                state.dataModel.action_retrieveProjectList(state.displayModel, function() {
                    return _this.elems.$popupErrorMessage.attr(_this.consts.dataAttrGenericGetProjectsErrorMsg);
                });

            }, function() {

                if (_this.elems.$popupWrapper.css('display') != 'none' &&
                    _this.elems.$popupWrapper.css('visibility') != 'hidden') {

                    // Account for dynamic count of project list items
                    return -(state.displayModel.projectListMaxItemDisplay * 30 + 20);
                }
                return 0;
            }]);
        },

        openAddProduct: function($sourceElem, product) {

            if(!product) return;

            this.setSourceElem($sourceElem);
            state.dataModel.setTypeStd();
            this.setPopupWrapperStd();
            state.dataModel.setProduct(product);

            // If the popup is not available, leave this function.
            if(this.elems.$popupWrapper.length == 0) return;
            state.dataModel.clearErrorMessages(false);

            this.openPopup();
        },

        openAddProducts: function($sourceElem, products) {

            if(!products) return;

            this.setSourceElem($sourceElem);
            state.dataModel.setTypeSizingAndSelection();
            this.setPopupWrapperSizeAndSelection();
            state.dataModel.setProducts(products);

            // If the popup is not available, leave this function.
            if(this.elems.$popupWrapper.length == 0) return;
            state.dataModel.clearErrorMessages(false);

            this.openPopup();
        },

        draw: function(state) {

            this.applyDataModel(state);
            this.removeProjectItems();
            this.attachProjectItemsMin(state);
            this.attachProjectItemsAll(state);

            if (state.displayModel.projectListMaxItemDisplay < state.dataModel.projectList.length) {

                if (state.displayModel.projectListShowAll) {
                    this.elems.$projectListAccordionIcon.addClass('ppl-rotate');
                    this.elems.$projectItemsAllContainer.show();
                } else {
                    this.elems.$projectItemsAllContainer.hide();
                    this.elems.$projectListAccordionIcon.removeClass('ppl-rotate');
                }
                this.elems.$projectListAccordion.show();
            } else {
                this.elems.$projectListAccordion.hide();
            }

            if (state.displayModel.retrievingProjects) {
                this.elems.$popupAllProjects.hide();
                this.elems.$popupProjectsLoading.show();
                this.elems.$popupNoExistingProjects.hide();
            } else if (state.dataModel.projectList.length == 0) {
                this.elems.$popupAllProjects.hide();
                this.elems.$popupProjectsLoading.hide();
                this.elems.$popupNoExistingProjects.show();
            } else {
                this.elems.$popupAllProjects.show();
                this.elems.$popupProjectsLoading.hide();
                this.elems.$popupNoExistingProjects.hide();
            }

            (state.displayModel.addingProduct)
                ? this.elems.$popupCtrlsSpinner.show()
                : this.elems.$popupCtrlsSpinner.hide();

            this.elems.$projectListAdd.prop('disabled',
                !state.dataModel.canRunMainAction() || !state.displayModel.canRunMainAction());

            this.elems.$popupErrorMessage.text('');
            if(state.dataModel.errorAddProduct) {
                this.elems.$popupErrorMessage.text(state.dataModel.errorAddProduct);
            }
            else if(state.dataModel.errorRetrievingData) {
                this.elems.$popupErrorMessage.text(state.dataModel.errorRetrievingData);
            }

            this.elems.$popup.show();
        },
        applyDataModel: function(state) {
            this.elems.$projectNameInputValue.val(state.dataModel.newProjectListName);
        },
        setInitialState: function(state) {
            state.dataModel.setLanguageIsoCode(this.elems.$popup.attr('data-language'));
            state.dataModel.setActiveText(this.elems.$popup.attr('data-elem-active-text'));
        }
    };

    return {
        initView: function() {
            ui.setInitialState(state);
            ui.initHandlers(ui.elems, ui.consts, state);
        },
        openAddProduct: function($sourceElem, product) {
          ui.openAddProduct($sourceElem, product);
        },
        openAddProducts: function($sourceElem, products) {
            ui.openAddProducts($sourceElem, products);
        },
        getLangIsoCode: function() {
            return ui.elems.$popup.attr('data-language');
        }
    };
}
