$('.form-select select').each(function(){
	var that = $(this);

	that.select2({
		selectOnClose: true,
		minimumResultsForSearch: that.data('min-for-search') || 10
	});

	that.on('select2:opening', function( event ) {
	    setTimeout(function () { $('.select2-search__field').focus(); }, 100);
	});

	that.closest('form').on('reset', function() {
		window.setTimeout(function() {
			that.trigger('change');
		}, 100);
	});

	$(document).on('select2:opening select2:open', function(e) {
		if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			setTimeout(function(e) {
				document.activeElement.blur();
			}, 1);
		}
	});
});


