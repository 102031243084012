export const mockResponse = (currentPage = 1) => {
  const searchResultsArray = []

  for (let i = 0; i < 200; i++) {
    const results = searchResultItem(i)
    searchResultsArray.push(...results)
  }

  const pages = pagesResult(searchResultsArray.length / 25, currentPage, searchResultsArray.length)

  const searchResults = searchResultsArray.slice((currentPage - 1) * 25, currentPage * 25)

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ ...res, pages, searchResults })
    }, 1000)
  })
}

const searchResultItem = (num) => [
  {
    title: `Software Engineer ${num}`,
    locations: ['Germany', 'USA'],
    jobType: 'Permanent',
  },
]

const pagesResult = (max, current, total) => ({
  max,
  current,
  total,
})

const res = {
  jobFiltersSections: [
    {
      title: 'Categories',
      filters: [
        {
          name: 'Administration',
          count: 1,
        },
        {
          name: 'Engineering',
          count: 2,
        },
      ],
    },
    {
      title: 'Job Type',
      filters: [
        {
          name: 'Permanent',
          count: 1,
        },
        {
          name: 'Internship',
          count: 2,
        },
      ],
    },
    {
      title: 'Job Flexibility',
      filters: [
        {
          name: 'On site',
          count: 1,
        },
        {
          name: 'Remote',
          count: 2,
        },
      ],
    },
  ],
}
