
$('.rb-radio').click(function(e){
    if(e.target.tagName != 'INPUT') {
        $(this).closest('.rb-radio').find('Input').trigger('click');
    }
});

$('.rb-action-select').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).trigger('radio-button-click', [$(this).attr('name'), $(this).val()]);
    return false;
});
