
function getPropValueAsNumber(computedStyles, propName) {
  const val = Number.parseFloat(computedStyles.getPropertyValue(propName).replace('px', ''));
  if(isNaN(val)) return 0.0;
  return val;
}

function getLeft(computedStyles) {

  const pl = getPropValueAsNumber(computedStyles, 'padding-left');
  const ml = 0; // getPropValueAsNumber(computedStyles, 'margin-left');
  return pl + ml;
}
function getRight(computedStyles) {

  const pr = getPropValueAsNumber(computedStyles, 'padding-right');
  const mr = 0; // getPropValueAsNumber(computedStyles, 'margin-right');
  return pr + mr;
}

export function getCorrection(wrapperElem) {

  let left = 0;
  let right = 0;
  if(wrapperElem) {
    const column = wrapperElem.closest('.column');
    if(column) {
      left = getLeft(window.getComputedStyle(column));
      right = getRight(window.getComputedStyle(column));
    }
    const gridContainer = wrapperElem.closest('.grid.container');
    if(gridContainer) {
      left += getLeft(window.getComputedStyle(gridContainer));
      right += getRight(window.getComputedStyle(gridContainer));
    }
  }
  return { left, right };
}

