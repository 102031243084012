// These libraries are made available for all modules by the expose-loader in the webpack configuration.
// This way, inline scripts using those libraries, are working as well. They are also available via the window variable.
// It sill is necessary to add it here, otherwise webpack will not include it in the bundle.
import 'jquery';
import 'js-cookie';
import 'nouislider';


// jQuery based libraries. By just importing them (side effects), they extend the jQuery object.
import 'jquery-touchswipe/jquery.touchSwipe'
import 'daterangepicker'; // depends on moment.js as well
import 'select2';
import 'iframe-resizer';
require.context('../node_modules/select2/dist/js/i18n/', true, /\.js$/); // add all in folder


// Vendor libraries which are provided directly by webpack-loaders/plugins.
// E.g. providePlugin, exports-loader, imports-loader, etc.
// These scripts/libraries do not need to be added again in this file.

// moment.js, outdatedbrowser, stickyfilljs, knockout, knockout.mapping


// Libraries which are most likely not needed anymore
// jQuery.dotdotdot, plyr, waypoints
