$('.cart .cart-update-form form').each(function(){
	var form = $(this);
		var quantity = form.find('.column-quantity .form-input-number input');

	var origQuantity = parseInt(quantity.val(), 10);
	if(isNaN(origQuantity)) {
		origQuantity = 1;
	}

	quantity.on('change', function() {

		var currentQuantity = parseInt($(this).val(), 10);
		if(isNaN(currentQuantity)) {
			currentQuantity = origQuantity;
		}

		if(currentQuantity >= 0 && currentQuantity !== origQuantity) {
			form.submit();
		}
	});

	quantity.on('keydown', function(e) {
		if(e.keyCode === 13) {
			return false;
		}
	});

	quantity.on('keyup', function(e) {
		if(e.keyCode === 13) {
			quantity.change();
		}
	});


	form.find('.cart-action-remove.js-trigger').on('click', function() {
		quantity.val(0).change();
	});
});
