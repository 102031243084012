import { createSearchParams } from '../utils/createSearchParams'
import { getSearchParametersFromURL } from '../utils/getSearchParametersFromUrl'
import { setWindowHistoryForSearch } from '../utils/setWindowHistoryForSearch'
import { mockResponse } from './mock/mockResponse'
import { renderFilters, renderCountriesDropdown } from './render/renderFilters'
import { renderPagination } from './render/renderPagination'
import { renderSearchResults } from './render/renderSearchResults'

//object to store search parameters
let searchParams = {}

let filtersOpened = false

const jobApplication = document.querySelector('.joblist')
const jobDetailsBaseUrl = jobApplication ? jobApplication.getAttribute('data-url') : ''
const searchFieldResetButton = document.querySelector('.joblistform__item-icon--close')
const jobForm = document.querySelector('.joblistform')
const searchField = jobForm?.querySelector('#joblistform__search-input')
const countryField = jobForm?.querySelector('#joblistform__country-input')
const filtersToggle = document.querySelector('.joblist-filters__toggle')
const filtersToggleIcon = filtersToggle ? filtersToggle.querySelector('.joblist-filters__toggle-icon') : ''
const filtersList = document.querySelector('.joblist-filters__list')
if (filtersList) {
  filtersList.style.display = 'none' 
}

export const jobList = () => {
  //taking the search parameters from the url on page load
  const searchParamsFromUrl = getSearchParametersFromURL()
  searchParams = { ...searchParamsFromUrl }

  if (jobApplication) {
    fetchJobs()
  }

  //resetting the search input field
  if (searchFieldResetButton) {
    searchFieldResetButton.addEventListener('click', handleResetSearchField)
  }

  if (jobForm) {
    jobForm.addEventListener('submit', handleSearchFormSubmit)
    updateFormFields()
  }

  //listener for the browser's back and forward buttons
  window.addEventListener('popstate', handlePopSate)
}

const fetchJobs = async (skipHistory) => {
  //using the searchParams object to create the search string

  const searchParamString = createSearchParams(searchParams)

  //if using the browser back and forward button, dont change the browser history
  if (!skipHistory) {
    /**
     * this function updates the browser's address bar with the current search parameters
     * it also writes the url into the browser's history, which allows to use the browser's back and forward buttons
     */
    setWindowHistoryForSearch(searchParams)
  }

  const urlPrefix =
    window.location.hostname === 'localhost' && (window.location.port === '8080' || window.location.port === '8081')
      ? '/belimo-local-mysql'
      : ''
  const request = await fetch(`${urlPrefix}/.rest/successfactors/v1/job/listing?${searchParamString}`)
  const result = await request.json()
  // const result = await mockResponse(searchParams.page)

  if (result) {
    //filters are coming from the api - TODO: clarify with BE if filters should be sent on all requests, or if it is enough on initial request
    renderFilters(result?.jobFiltersSections, handleFilterClick, searchParams.filters)
    renderCountriesDropdown(result?.jobFiltersSections, handleCountryClick, searchParams.filters)
    renderSearchResults(result?.searchResults, jobDetailsBaseUrl)
    renderPagination(result?.pages, handlePagination, handlePaginationControlButtons)
  }
}

//handler for browser back and forward buttons
const handlePopSate = () => {
  const searchParamsFromURL = getSearchParametersFromURL()
  const currentPage = parseInt(searchParamsFromURL.page)
  searchParams = { ...searchParamsFromURL, page: currentPage || 1 }
  updateFormFields()
  fetchJobs(true)
}

const handleSearchFormSubmit = (event) => {
  event.preventDefault()
  const searchFieldValue = searchField?.value
  const countryFieldValue = countryField?.value

  //updating the search params, it is used in the fetchJobs function
  searchParams.search = searchFieldValue
  searchParams.country = countryFieldValue
  searchParams.page = 1
  fetchJobs()
}

const handleFiltersToggle = () => {
  if (filtersOpened) {
    filtersList.style.display = 'none';
    filtersToggleIcon.style.transform = 'rotate(0)'
  } else {
    filtersList.style.display = 'block';
    filtersToggleIcon.style.transform = 'rotate(180deg)'
  }
  filtersOpened = !filtersOpened
}

filtersToggle?.addEventListener('click', handleFiltersToggle)

const handleFilterClick = (event) => {
  //taking the already set filters and creating a set to avoid duplicates
  const filters = new Set(searchParams.filters?.length ? [...searchParams.filters] : [])

  //adding and removing of filters + updating the searchParams with the new list
  if (filters.has(`${event.target.getAttribute('data-filterSection')}:${event.target.name}`)) {
    filters.delete(`${event.target.getAttribute('data-filterSection')}:${event.target.name}`)
  } else {
    filters.add(`${event.target.getAttribute('data-filterSection')}:${event.target.name}`)
  }
  searchParams.filters = [...filters]
  searchParams.page = 1

  fetchJobs()
}

const handleCountryClick = (event) => {
  //taking the already set filters and creating a set to avoid duplicates
  const filters = new Set(searchParams.filters?.length ? [...searchParams.filters] : [])
  
  if (event.target.value === 0) {
    return
  }

  //adding and removing of filters + updating the searchParams with the new list
  if (filters.has(`country:${event.target.value}`)) {
    filters.delete(`country:${event.target.value}`)
  } else {
    filters.add(`country:${event.target.value}`)
  }
  searchParams.filters = [...filters]
  searchParams.page = 1

  fetchJobs()
}

const updateFormFields = () => {
  if (searchField) {
    searchField.value = searchParams.search || ''
  }
  if (countryField) {
    countryField.value = searchParams.country || ''
  }
}

const handleResetSearchField = () => {
  const searchFieldResetButton = document.querySelector('#joblistform__search-input')
  if (searchFieldResetButton) {
    //resetting the search input and adding the focus to the field
    searchFieldResetButton.value = ''
    searchParams.search = ''
    searchFieldResetButton.focus()
    fetchJobs()
  }
}

const handlePagination = (event) => {
  const page = parseInt(event.target.dataset.page)
  searchParams.page = page
  fetchJobs()
}

const handlePaginationControlButtons = (page) => {
  searchParams.page = page
  fetchJobs()
}

jobList()
