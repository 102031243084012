/**
 * Cart Handling Class
 *
 */
Belimo.Downloadfolder = new function () {

    /**
     * Update Cart
     *
     */
    this.update = function (documents) {
        $(document).trigger('downloadfolder-update', [documents]);
    };

    this.submit = function (url, data, callback) {
      $.ajax({
            method: 'POST',
            url: url,
            dataType: 'json',
            data: data,
            success: function (response) {
              if(response.errorMessage && response.errorMessage.length) {
                Belimo.Modal.open(response.errorMessage, []);
              } else {
                Belimo.Downloadfolder.update(response.entries || []);
                if(callback) {
                  callback();
                }
              }
            }
        });
    };
};
