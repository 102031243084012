/**
 * Created by KRAE059 on 13.12.2016.
 */
'use strict';

$('.modal-trigger.delete-cart').each(



    function()
    {

        Belimo.SavedCartDeleteModal = new function()
        {






            this.getModalHTMLText = function()
            {
              return $('#modal-delete-text').html();
            };

            this.getModalDeleteConfirmationElement = function()
            {
                return $('.delete-modal-confirmation');
            };



            this.showDeleteModal = function(target)
            {
                var content = '';
                var $target = target;
                var url = $target.attr('href');
                var btnDeleteUrl = $target.data('delete-url');
                var btnDeleteText = $target.data('delete-txt');
                var btnCancelText = $target.data('cancel-txt');
                var btnDeleteNextUrl = $target.data('delete-nexturl');
                var modalText = Belimo.SavedCartDeleteModal.getModalHTMLText();
                var buttons = [];

                buttons.push({
                    label: btnDeleteText,
                    type: 'button',
                    callback: function() {
                        $.ajax({
                            url: btnDeleteUrl,
                            type: 'POST',
                            success: function(result) {
                            	location.href = btnDeleteNextUrl;
                            }
                        });
                    }
                });

                buttons.push({
                    label: btnCancelText,
                    type: 'reset',
                    callback: Belimo.Modal.close
                });


                Belimo.Modal.open(modalText, buttons);
                return content;
            };
        };


        $(this).click(function(event)
        {
            event.preventDefault();
            Belimo.SavedCartDeleteModal.showDeleteModal($(this));

        });
    }
);
