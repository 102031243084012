(function() {

    function toggleDisplay($collapsible) {
        if($collapsible.length != 1) return;

        var $collapsibleContent = $collapsible.find('.accessories-collapsible-content');
        var $collapsibleButton = $collapsible.find('.accessories-collapsible-header-button');
        var $collapsibleTitle = $collapsible.find('.accessories-collapsible-header-title');

        if($collapsibleContent.css('display') == 'block') {
            $collapsibleContent.css('display', 'none');
            $collapsibleButton.removeClass('accessories-collapsible-header-button-active');
            $collapsibleTitle.removeClass('accessories-collapsible-header-title-active');
        }
        else {
            $collapsibleContent.css('display', 'block');
            $collapsibleButton.addClass('accessories-collapsible-header-button-active');
            $collapsibleTitle.addClass('accessories-collapsible-header-title-active');
        }
    }

    function collapseAll(){
        $('.accessories-collapsible-content').each(function(){
            $(this).css('display', 'none');

            var $collapsible = $(this).closest('.accessories-collapsible');
            if($collapsible.length !== 1) return;

            var $collapsibleTitle = $collapsible.find('.accessories-collapsible-header-title');
            $collapsibleTitle.removeClass('accessories-collapsible-header-title-active');

            var $collapsibleButton = $collapsible.find('.accessories-collapsible-header-button');
            $collapsibleButton.removeClass('accessories-collapsible-header-button-active');
        });
    }

    function init(){
        var buttons = document.getElementsByClassName('accessories-collapsible-header');
        for(var i = 0; i < buttons.length; ++i) {
            buttons[i].addEventListener('click', function (e) {

                const $collapsible = $(e.target).closest('.accessories-collapsible');
                toggleDisplay($collapsible);
            });
        }
        return buttons.length > 0;
    }

    function openIfOnlyOneCollapsible() {
        const collapsibles = document.getElementsByClassName('accessories-collapsible');
        if(collapsibles.length === 1) {
            toggleDisplay($(collapsibles[0]));
        }
    }

    var initDone = false;
    $('.accessories-action-click').click(function(){
        if(!initDone) init();
        collapseAll();
        openIfOnlyOneCollapsible();
    });
    initDone = init();
})();
