Belimo.Search = new function () {
	var views = {};

	this.addView = function (name, view) {
		views[name] = view;
	};
	this.getView = function (name) {
		return views[name];
	};
	this.getViews = function () {
		return views;
	};
};
const fetchJson = async (url) => {
	
	const response = await fetch(url);
	if(response.status < 200 || response.status >= 300) {
		throw new Error(`Unexpected response code '${response.status}' from request to '${url}'`);
	} 

	const contentType = response.headers.get('content-type');	
	if(contentType !== null && !contentType.toLowerCase().startsWith('application/json')) {
		throw new Error(`Unexpected content-type '${contentType}' from request to '${url}'`);
	}

	const responseJson = await response.json();
	return responseJson;
}
Belimo.fetchJson = async (url) => {
	try {
		return await fetchJson(url);
	}
	catch (error) {
		console.error(`Error fetching '${url}', ${error}`);
		return null;
	}
}
Belimo.fetchJsonAndSetAsText = async (url, cssSelectorGroup, defaultTextValue, textPrefix, textSuffix) => {

	if (!cssSelectorGroup || !url) return;
	const resultJson = await Belimo.fetchJson(url);
	
	if(!defaultTextValue) domElemTextContent = '';
	const resultJsonAsText = resultJson !== null
		? JSON.stringify(resultJson)
		: defaultTextValue;

	if (!textPrefix) textPrefix = '';
	if (!textSuffix) textSuffix = '';
	const domElemTextContent = `${textPrefix}${resultJsonAsText}${textSuffix}`;
	const domElems = document.querySelectorAll(cssSelectorGroup);
	for (const domElem of domElems) {
		domElem.textContent = domElemTextContent;
	}
}
