
function selectTab(target, doNotCallLayoutChanged) {

	var that = $(target);
	var tab = that.closest('.tab');
	var mLabels = tab.find('> .tab-labels .tab-trigger.js-trigger');
	var dLabels = tab.find('> .tab-contents > .tab-trigger.js-trigger');
	var contents = tab.find('> .tab-contents > .tab-content');
	var index = mLabels.index(that) > -1 ? mLabels.index(that) : dLabels.index(that);
	var isOff = !that.hasClass('toggle-on');

	if (isOff) {

		// only on mobile: scrolls down to the tab position. Removed because of BEL-8260
		/* if(that.parent().hasClass('tab-contents')) {
			var height = that.prevAll('.tab-trigger').map(function(){
				return $(this).outerHeight();
			}).get().reduce(function(a, b) {
				return a + b;
			}, tab.offset().top);

			$(document).scrollTop(height);
		}*/

		tab.find('> .tab-labels .toggle-on, > .tab-contents > .toggle-on').removeClass('toggle-on');
		mLabels.eq(index).addClass('toggle-on');
		dLabels.eq(index).addClass('toggle-on');
		contents.eq(index).addClass('toggle-on');

		if (mLabels.hasClass('acs-action-open')) {
			mLabels.trigger('acs-show');
		}

		contents.eq(index).trigger('tab-opened');

	} else {
		if (tab.data('collapse') === 'allow' || that.data('collapse') === 'allow') {
			tab.find('> .tab-labels .toggle-on, > .tab-contents > .toggle-on').removeClass('toggle-on');
		}
	}

	if (!doNotCallLayoutChanged) {
		$(document).trigger('layout-changed');
	}
}


$('.tab .tab-trigger.js-trigger').on('click', function (ev) {
	if($(ev.currentTarget).find('a').length === 0) {
		ev.preventDefault();
		selectTab(ev.currentTarget, false);
	}
});

function headerHeight() {

	let h = 0;
	const headerElems = document.getElementsByTagName('header');
	let headerElemHeight = 0;
	if(headerElems.length) {
		const headerElemStyles = getComputedStyle(headerElems[0]);
		if(headerElemStyles.getPropertyValue('position') === 'sticky') {
			h = headerElems[0].clientHeight;
		}
	}
	return h;
}

function elemOffset(el) {
	const rect = el.getBoundingClientRect();
	scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

function scrollToElem(elem, delay) {
	
	if(!elem) return;

	setTimeout(() => {
		window.scrollTo({
			left: 0, top:
			elemOffset(elem).top - headerHeight(),
			behavior: 'smooth'
		});
	}, delay);
}

function locationHashChanged(event) {

	if(window.location.hash != undefined && window.location.hash.toLowerCase().indexOf("msdynttrid") === -1 ) {

		const mediaQuery = window.matchMedia('(min-width: 1020px)');
		const isMobile = !mediaQuery.matches;

		const decodedHash = decodeURIComponent(window.location.hash);
		const $decodedHash = isMobile ? $(`div[id='${decodedHash.slice(1)}']`) : $(decodedHash);

		if($decodedHash.length > 0 && $decodedHash.hasClass('tab-trigger')) {

			const $elem = $decodedHash.parent().parent().next('.tab-contents').find(decodedHash);

			$elem.trigger('click');
			$(decodedHash).trigger('click');
			scrollToElem($decodedHash.get(0), 100);
			
		}
		else {
			onLocationHashClick(decodedHash, event);
		}
	}
}

function onLocationHashClick(decodedHash, event) {

	
	if (!decodedHash) return;

	const mediaQuery = window.matchMedia('(min-width: 1020px)');
  	const isMobile = !mediaQuery.matches;

	const $decodedHash = isMobile ? $(`div[id='${decodedHash.slice(1)}']`) : $(decodedHash);

	const $tab = $decodedHash.closest('.tab-content').prev('.tab-trigger');
	if ($tab && $tab.length > 0) {

		if (!$tab.hasClass('toggle-on')) {
			selectTab($tab.get(0), true);
		}
		const element = document.getElementById(decodedHash.slice(1));
		scrollToElem(element, 100);
	}
}

locationHashChanged();
window.addEventListener('hashchange', locationHashChanged);
