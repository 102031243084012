const jobForm = document.getElementById('jobdetails-application-form')
const button = jobForm?.querySelector('button')
const fileInputFields = jobForm?.querySelectorAll('input[type="file"]')
const fileInputWrapper = document.getElementById('jobdetails-application-file-upload')
const maxFileSizeString = fileInputWrapper?.getAttribute('max-file-size')
const maxTotalFilesSizeString = fileInputWrapper?.getAttribute('max-total-files-size')
const maxFileSize = maxFileSizeString ? parseInt(maxFileSizeString.replace('MB', '')) * 1024 * 1024 : 10485760
const maxTotalFilesSize = maxTotalFilesSize ? parseInt(maxTotalFilesSize.replace('MB', '')) * 1024 * 1024 : 41943040

let currentFileSizeTotal = 0

let uploadId = null

const jobApplication = async () => {
  if (jobForm) {
    jobForm.addEventListener('submit', handleSubmit)
  }

  if (fileInputFields?.length) {
    fileInputFields.forEach((fileInputField) => {
      fileInputField.addEventListener('change', handleUpload)
    })
  }
}

const handleQuestionMapping = (payload, item) => {
  if (!!['question-answer', 'question-locale', 'question-order'].find(x => x === item.name)) {
    if (!payload[item.name]) {
      payload[item.name] = []
    }
    payload[item.name].push(item.value ?? '')
    return true
  }
  return false
}

//possible solution, but feel free to change it :)
const handleSubmit = async (event) => {
  event.preventDefault()
  const formElements = [...jobForm.elements]

  let valid = true;
  let payload = {}

  formElements.forEach((item) => {
    if (!item.value && item) {
      if (item.required) {
        item.classList.add('error')
        valid = false
      } else {
        handleQuestionMapping(payload, item)
      }
    } else {
      if (!!item.name && item.name !== 'upload-file-title' && item.name !== 'upload-cv' && item.name !== 'upload-cover-letter' && item.name !== 'upload-other') {
        if (!handleQuestionMapping(payload, item)) {
          payload[item.name] = item.value
        }
      }
    }
  })

  if (valid) {
    payload['upload-id'] = uploadId

    const urlPrefix =
    window.location.hostname === 'localhost' && (window.location.port === '8080' || window.location.port === '8081')
      ? '/belimo-local-mysql'
      : ''

    const request = await fetch(`${urlPrefix}/.rest/successfactors/v1/job-details/jobApplicationForm`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'content-type': 'application/json'
      }
    })
    const result = await request.json()

    if (!result.error) {
      const successMessage = document.querySelector('#jobdetails-application-success-message')

      jobForm.style.display = 'none'
      successMessage.style.display = 'block'
    } else {
      const errorMessage = document.querySelector('#jobdetails-application-error-message')

      errorMessage.style.display = 'block'
    }
  }
}

const handleDelete = async (fileName, fileType, fileSize, divId) => {
  const urlPrefix = window.location.hostname === 'localhost' && (window.location.port === '8080' || window.location.port === '8081')
  ? '/belimo-local-mysql'
  : ''

  const request = await fetch(`${urlPrefix}/.rest/successfactors/v1/job-details/document-remove?uploadId=${uploadId}&documentType=${fileType}&fileName=${encodeURIComponent(fileName)}`)
  const result = await request.json()
  console.log("result", result)
  if (!result.error) {
    currentFileSizeTotal -= fileSize
    document.querySelector(`#${divId}`).remove()

    if (fileType === 'resume') {
      const resumeInput = document.querySelector('#upload-cv')
      resumeInput.value = null
    }
  }
}

/**
 *
 * This is an example for file uplaod from:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
const handleUpload = async (event) => {
  const inputField = event.target
  let previewContainer = null
  const uploadError = inputField.closest('div').querySelector('#upload-file-error')
  const sizeError = inputField.closest('div').querySelector('#size-file-error')
  const noFileError = inputField.closest('div').querySelector('#no-file-error')
  const totalSizeError = inputField.closest('div').querySelector('#total-size-error')

  if (uploadError) {
    uploadError.style.display = 'none'
  }

  if (sizeError) {
    sizeError.style.display = 'none'
  }

  if (noFileError) {
    noFileError.style.display = 'none'
  }

  if (totalSizeError) {
    totalSizeError.style.display = 'none'
  }

  const fieldName = inputField.name
  let fieldType = ''

  switch (fieldName) {
    case 'upload-cv': fieldType = 'resume'; previewContainer = document.querySelector('#upload-cv-title'); break;
    case 'upload-cover-letter': fieldType = 'coverLetter'; previewContainer = document.querySelector('#upload-cover-letter-title'); break;
    case 'upload-other': fieldType = 'attachment1'; previewContainer = document.querySelector('#upload-other-title'); break;
  }

  if (fieldName === 'upload-cv' && previewContainer.firstChild) {
    uploadError.style.display = 'block'
    return
  }

  if (fieldName === 'upload-cover-letter' && previewContainer.firstChild) {
    uploadError.style.display = 'block'
    return
  }

  const curFiles = inputField.files

  if (curFiles.length === 0) {
    noFileError.style.display = 'block'
  } else {
    const file = curFiles[0]
    const formData = new FormData();
    formData.append(fieldName, file)
    formData.append('type', fieldType)

    if (file.size > maxFileSize) {
      sizeError.style.display = 'block'
      return
    }

    if (currentFileSizeTotal + file.size > maxTotalFilesSize) {
      totalSizeError.style.display = 'block'
      return
    }

    if (uploadId) {
      formData.append('uploadId', uploadId)
    }

    const urlPrefix = window.location.hostname === 'localhost' && (window.location.port === '8080' || window.location.port === '8081')
    ? '/belimo-local-mysql'
    : ''
    fetch(`${urlPrefix}/.rest/successfactors/v1/job-details/document-preupload`, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    }).then(async (response) => {
      const res = await response.json()
      if (!res.error) {
        if (!uploadId) {
          uploadId = res.uploadId
        }
        currentFileSizeTotal += file.size
        const li = document.createElement('li')
        li.id = `id-${Date.now()}`
        const div = document.createElement('div')
        div.style = 'display: flex; justify-content: space-between; word-break: break-word;'
        div.innerHTML = `<span>${file.name}</span>`
        const spanClose = document.createElement('span')
        spanClose.innerHTML = 'x'
        spanClose.style.color = 'rgb(255,102,0)'
        spanClose.addEventListener('click', () => handleDelete(file.name, fieldType, file.size, li.id))
        spanClose.style.cursor = 'pointer'
        div.appendChild(spanClose)
        li.appendChild(div)
        previewContainer.append(li)
      }
    })
  }
}

jobApplication()

function validFileType(file) {
  return fileTypes.includes(file.type)
}

function returnFileSize(number) {
  if (number < 1024) {
    return `${number} bytes`
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} KB`
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} MB`
  }
}

// button.addEventListener('click', (e) => {
//   e.preventDefault()
//   const para = document.createElement('p')
//   para.append('Image uploaded!')
//   preview.replaceChildren(para)
// })

const mock = {
  filterSection: [
    {
      title: 'Categories',
      filters: [
        {
          name: 'Administration',
          count: 1,
        },
        {
          name: 'Business Development',
          count: 2,
        },
        {
          name: 'Customer Support',
          count: 3,
        },
        {
          name: 'Engineering',
          count: 4,
        },
        {
          name: 'Finance/Controlling',
          count: 5,
        },
        {
          name: 'Human Resources',
          count: 6,
        },
        {
          name: 'IT',
          count: 7,
        },
        {
          name: 'Logistics',
          count: 8,
        },
        {
          name: 'Marketing/Communication',
          count: 9,
        },
        {
          name: 'Planning/Distribution',
          count: 10,
        },
        {
          name: 'Production/Manufacturing',
          count: 11,
        },
        {
          name: 'Product Management',
          count: 12,
        },
        {
          name: 'Project Management',
          count: 13,
        },
        {
          name: 'Purchasing',
          count: 14,
        },
        {
          name: 'Quality',
          count: 15,
        },
        {
          name: 'Sales',
          count: 16,
        },
        {
          name: 'Technical/Field Support',
          count: 17,
        },
        {
          name: 'Training',
          count: 18,
        },
        {
          name: 'Other / Misc.',
          count: 19,
        },
      ],
    },
    {
      title: 'Job Type',
      filters: [
        {
          name: 'Permanent',
          count: 1,
        },
        {
          name: 'Short-term',
          count: 2,
        },
        {
          name: 'Internship',
          count: 3,
        },
        {
          name: 'Apprenticeship',
          count: 4,
        },
      ],
    },
    {
      title: 'Job Flexibility',
      filters: [
        {
          name: 'On site',
          count: 1,
        },
        {
          name: 'Remote',
          count: 2,
        },
        {
          name: 'Hybrid',
          count: 3,
        },
      ],
    },
    {
      title: 'Country',
      filters: [
        {
          name: 'Switzerland',
          count: 1,
        },
        {
          name: 'United States',
          count: 2,
        },
        {
          name: 'China',
          count: 3,
        },
        {
          name: 'Germany',
          count: 4,
        },
        {
          name: 'Australia',
          count: 5,
        },
        {
          name: 'Austria',
          count: 6,
        },
        {
          name: 'Belgium',
          count: 7,
        },
        {
          name: 'Brazil',
          count: 8,
        },
        {
          name: 'Canada',
          count: 9,
        },
        {
          name: 'Finland',
          count: 10,
        },
        {
          name: 'France',
          count: 11,
        },
        {
          name: 'India',
          count: 12,
        },
        {
          name: 'Indonesia',
          count: 13,
        },
        {
          name: 'Japan',
          count: 14,
        },
        {
          name: 'Malaysia',
          count: 15,
        },
        {
          name: 'Netherlands',
          count: 16,
        },
        {
          name: 'Norway',
          count: 17,
        },
        {
          name: 'Philippines',
          count: 18,
        },
        {
          name: 'Poland',
          count: 19,
        },
        {
          name: 'Spain',
          count: 20,
        },
        {
          name: 'Sweden',
          count: 21,
        },
        {
          name: 'Taiwan',
          count: 22,
        },
        {
          name: 'Turkey',
          count: 23,
        },
        {
          name: 'United Arab Emirates',
          count: 24,
        },
        {
          name: 'United Kingdom',
          count: 25,
        },
        {
          name: 'Vietnam',
          count: 26,
        },
      ],
    },
  ],
  searchResults: [
    {
      title: 'Software Engineer 1',
      locations: ['Germany', 'United States'],
      jobType: 'Permanent',
    },
    {
      title: 'Software Engineer 2',
      locations: ['Germany', 'United States'],
      jobType: 'Permanent',
    },
  ],
  pages: {
    max: 2,
    current: 1,
  },
}
