export const getSearchParametersFromURL = (url = window.location.search) => {
  const searchParams = new URLSearchParams(url)
  const paramObject = {}

  for (let param of searchParams) {
    const paramKey = param[0]
    let paramValue = param[1]

    if (!allowedParams(paramKey)) {
      continue
    }

    if (paramKey === 'filters') {
      paramValue = paramValue.split(',')
    }

    paramObject[paramKey] = paramValue
  }

  return paramObject
}

const allowedParams = (paramKey) => {
  const paramArray = ['search', 'country', 'filters', 'page']
  return paramArray.includes(paramKey)
}
