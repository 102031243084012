$('.panel-burger').each(function(){
	var panel = $(this);

	panel.find('.panel-burger-trigger.js-trigger').on('click', function(ev){
		ev.preventDefault();

		var that = $(this);
			var isOn = panel.hasClass('toggle-on');

		$('.panel-burger').removeClass('toggle-on');
		$('.panel-search').removeClass('toggle-on');
		$('.panel-cart').removeClass('toggle-on');
		$('.panel-download').removeClass('toggle-on');
		$('.panel-burger .collapsable').removeClass('toggle-on');

		if(isOn == false) {
			panel.addClass('toggle-on');

			if ($('body').hasClass('with-burger-overlay') == false) {
				$('body').addClass('with-burger-overlay with-meta');
				$('body.with-burger-overlay .overlay').one('click', function(){
					$('.panel-burger').removeClass('toggle-on');
					$('.panel-cart').removeClass('toggle-on');
					$('.panel-download').removeClass('toggle-on');
					$('.panel-search').removeClass('toggle-on');
					panel.find('.tab-contents .tab-label').removeClass('toggle-on');
					panel.find('.tab-contents .tab-content').removeClass('toggle-on');
					$('body').removeClass('with-burger-overlay with-meta');
				});
			}
		} else {
			panel.removeClass('toggle-on');
			panel.find('.tab-contents .tab-label').removeClass('toggle-on');
			panel.find('.tab-contents .tab-content').removeClass('toggle-on');
			$('body').removeClass('with-burger-overlay with-meta');
			$(document).trigger('overlay-off');
		}

		$(document).trigger('layout-changed');
	});

});

$('.panel').each(function(){
	var panel = $(this);

	var hiddenInput = panel.find('[name^="filter"]');

	panel.on('panel-on', function(){
		panel.addClass('toggle-on');
		hiddenInput.attr('value', 'open');

		if(panel.data('overlay') !== false) {
			$(document).one('overlay-off', function(){
				panel.trigger('panel-off');
			}).trigger('overlay-on', [panel.data('body-class')]);
		}
	}).on('panel-off', function() {
		panel.removeClass('toggle-on');
		hiddenInput.attr('value', 'closed');

		if(panel.data('overlay') !== false) {
			$(document).trigger('overlay-off');
		}
	});
	if ($.contains($('.panel-burger').get(0), panel.get(0))) {
		// Burger Navigation Panels

		panel.find('> .panel-trigger.js-trigger').on('click', function(ev){
			ev.preventDefault();

			var that = $(this);
				var isOn = panel.hasClass('toggle-on');
				var isSecondLevel = panel.hasClass('sublevel');

				// Ticket BEL-12265, fix falsely opening of menu (changed selection of subnodes)
				// Leaving the old code at this time because I'm not sure about possible side effects.
	 			// subNodes = $(this).siblings('.panel-content').find('.grid.navigation-row .navigation-list:not(.toggle-on) .js-trigger').children();
				var subNodes = $(this).siblings('.panel-content').find('.grid.navigation-row .navigation-list').find('.collapsable-trigger');

			if(isOn == false) {
				panel.addClass('toggle-on');
				// panel.find('.defaultOpen').find('.panel-trigger.js-trigger').trigger('click');
			} else {
				panel.removeClass('toggle-on');
			}

			if(subNodes.length === 1) {
				$(subNodes).trigger('click');
			}

			$(document).trigger('layout-changed');

		});

	} else {
		// Desktop Navigation & Usual Panels

		panel.find('.panel-trigger.js-trigger').on('click', function(ev){
			ev.preventDefault();

			var that = $(this);
				var isOn = that.parent('.panel').hasClass('toggle-on');
	 			var subNodes = $(this).siblings('.panel-content').find('.grid.navigation-row .navigation-list:not(.toggle-on) .js-trigger').children();

			autoClosePanelsExcept(ev.currentTarget, 'default-open-desktop');

			if(isOn == false) {
				panel.trigger('panel-on');
				$(this).parent('.panel').find('.defaultOpen').find('.panel-trigger.js-trigger').trigger('click');
			}

			if(subNodes.length === 1) {

				var $subNodeParentParent = $(subNodes).parent().parent();
				if(!$subNodeParentParent.hasClass('mgnl-navigation') ||
					!$subNodeParentParent.hasClass('navigation-list') || 
					!$subNodeParentParent.hasClass('with-icon')) {

					$(subNodes).trigger('click');
				}
			}

			$(document).trigger('layout-changed');

		});
	}

	if (panel.hasClass('default-open-desktop')) {
		if (window.innerWidth > 1019) {
			panel.find('.panel-trigger.js-trigger').trigger('click');
		}
	}

});

function autoClosePanelsExcept(clickedElement, panelClassName) {

	// Closes all open panels except those panels which
	// have 'panelClassName' in their class list (but are
	// not themself or do not themself contain the source of the
	// triggered event).

	if(!clickedElement) return;

	function elem1isOrContainsElem2(elem1, elem2) {

		if(!elem1 || !elem2) return false;
		if(elem1 === elem2) return true;
		return $(elem1).has(elem2).length > 0;
	}

	$('.panel.toggle-on').each(function() {
		var $openPanel = $(this);
		if(!panelClassName ||
			$openPanel.hasClass(panelClassName) === false ||
			elem1isOrContainsElem2(this, clickedElement)) {

			$openPanel.trigger('panel-off');
		}
	});
}


