/**
 * Cart Handling Class
 *
 */
Belimo.Cart = new function () {
    /**
     * Add Product to Cart
     *
     */
    this.add = function (product) {
        $(document).trigger('cart-add', [product]);
    };

    /**
     * Remove Product from Cart
     *
     */
    this.remove = function () {
        $(document).trigger('cart-remove', [product]);
    };

    /**
     * Update Cart
     *
     */
    this.update = function (products) {
        $(document).trigger('cart-update', [products]);
    };

    /**
     * Empty Cart
     *
     */
    this.empty = function (selector) {
        $(document).trigger('cart-empty');
    };

    /**
     * Rename the cart
     *
     */
    this.rename = function(name) {
        $(document).trigger('cart-rename', name);
    };
    /**
     * Update Cart by doing a rest call to hybris first.
     *
     */
    this.updateFromRemote = function(langIsoCode) {

        var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
        var requestUrl = requestUriPrefix + '/shop/' + langIsoCode + '/cart/miniCart/1';

        var _this = this;
        $.ajax({
            url: requestUrl,
            dataType: 'json',
            method: 'GET'
        }).done(function(response) {
            if(!response) return;
            if(response.products) _this.update(response.products);
            else if (response.errors && response.errors.length > 0) {
                console.error(response.errors[0]);
            }
        })
        .fail(function(xhr) {
            console.error('Failed to update mini cart.', xhr.responseText);
        });
    };

    this.submit = function (url, data) {
        $.ajax({
            method: 'POST',
            url: url,
            dataType: 'json',
            data: data,
            success: function (response) {
                var layer = $(response.addToCartLayer);
                    var checkout = layer.find('.btn-primary');
                    var newconfigfromscratch = layer.find('.btn-third');
                    var cancel = layer.find('.btn-default');
                    var buttons = [];

                Belimo.Cart.update(response.cartData.products || []);

                buttons.push({
                    label: checkout.text(),
                    type: 'button',
                    callback: function () {
                        location.href = checkout.attr('href');
                    }
                });

                if (response.cartData.configurableProduct) {

                    buttons.push({
                        label: newconfigfromscratch.text(),
                        type: 'button',
                        callback: function () {
                            location.href = newconfigfromscratch.attr('href');
                        }
                    });
                }

                buttons.push({
                    label: cancel.text(),
                    type: 'reset',
                    callback: Belimo.Modal.close
                });

                Belimo.Modal.open(layer.find('.add-to-cart-item'), buttons);
            }
        });
    };
};
