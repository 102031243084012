import { translatedField } from "../util"

export const renderFilters = (jobFiltersSections, handleClick, selectedFilters) => {
  const filterContainer = document.querySelector('.joblist-filters__list')
  if (filterContainer) {
    const orderedSections = jobFiltersSections.sort((a,b) => a.sortingValue - b.sortingValue)
    //for each category
    const containerTemplate = orderedSections.map((filterSection) => {
      if (filterSection.type !== 'country') {
        //for each filter inside the category
        const filters = filterSection.filters.sort((a,b) => a.sortingValue - b.sortingValue).map((filter) => {
          //selectedFilters -> searchParams.filters
          const isChecked = selectedFilters?.includes(`${filterSection.type}:${filter.code}`)

          const filerName = translatedField(filter.name)

          //template
          return `<div class="joblist-filters__list-filter">
                      <input type="checkbox" data-filterSection="${filterSection.type}" name="${filter.code}" ${isChecked ? 'checked' : ''} />
                      <label for="${filter.code}">${filerName} (${filter.count})</label>
                      </div>`
        })
        return `<div class="joblist-filters__list-filterbox">${filters.join('')}</div>`
      }
    })
    filterContainer.innerHTML = containerTemplate.join('')

    const checkboxes = filterContainer.querySelectorAll('.joblist-filters__list-filter input')
    if (checkboxes.length) {
      checkboxes.forEach((checkbox) => checkbox.addEventListener('click', handleClick))
    }
  }
}

export const renderCountriesDropdown = (jobFiltersSections, handleClick, selectedFilters) => {
  const countryField = document.querySelector('#joblistform__country-input')
  const countries = jobFiltersSections.find(section => section.type === 'country')
  const countriesOptions = countries?.filters.sort((a, b) => b.count - a.count).map((filter) => {
    const filterName = translatedField(filter.name)
    //selectedFilters -> searchParams.filters
    const isChecked = selectedFilters?.includes(`country:${filter.code}`) ?? false
    return `<li><input type="checkbox" value="${filter.code}" ${ isChecked ? "checked" : "" } />${filterName} (${filter.count})</li>`
  })
  countryField.innerHTML = countriesOptions.join('')

  const checkboxes = countryField.querySelectorAll('li')
  if (checkboxes.length) {
    checkboxes.forEach((checkbox) => checkbox.addEventListener('click', handleClick))
  }
}
