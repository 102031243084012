(function() {

    var $lineItemNotes = $('.cart-line-item-note');
    if($lineItemNotes.length > 0) {
        init();
    }

    function init() {

        var $errorDialog = $('.info-msgs-container').first();

        function extractErrorMessages(data, fallBackErrorMsg) {
            if(data && data.error === 'true') {
                if(data.errorMessages && data.errorMessages.length && data.errorMessages.length > 0) {
                    return data.errorMessages.map(function(item) {
                        return item.errorMessage;
                    });
                }
                return [fallBackErrorMsg];
            }
            return null;
        }

        function LineItemNote($lineItem) {

            this.entryId = $lineItem.attr('data-entry-number');
            this.errorMsg = $lineItem.attr('data-error-message');
            this.language = $lineItem.attr('data-language');
            this.$inputField = $lineItem.find('input');
            this.text = this.$inputField.val();

            var $lineItemRef = $('.line-item-note.cart-item-detail-column-row[data-id-entry="' + this.entryId + '"]');
            this.$lineItemTextRef = $lineItemRef.find('.cidcr-text');
            this.$lineItemTitleRef = $lineItemRef.find('.cidcr-title');

            this.lineItemTitleRefCurrent = this.$lineItemTitleRef.text();
            if(this.lineItemTitleRefCurrent) {
                this.lineItemTitleRefCurrent = this.lineItemTitleRefCurrent.trim();
            }
            this.$lineItemTitleRef.css('display', 'block');
            this.$parentTab = $lineItem.closest('.tab-content');
        }
        LineItemNote.prototype.draw = function() {
            this.$inputField.val(this.text);
            this.$lineItemTextRef.text(this.text);
            this.text
                ? this.$lineItemTitleRef.text(this.lineItemTitleRefCurrent)
                : this.$lineItemTitleRef.text('');
        };
        LineItemNote.prototype.validToPost = function(text) {
            return text !== this.text;
        };
        LineItemNote.prototype.post = function() {
            var _this = this;

            var requestUriPrefix = window && window.countryCode ? window.countryCode : '';
            var requestUrl = requestUriPrefix + '/shop/' + _this.language + '/cart/update-line-item-note';

            $.post(
              requestUrl, {
                    entry: _this.entryId,
                    lineItemNote: _this.$inputField.val()
                })
                .done(function(data) {
                    var errorMessages = extractErrorMessages(data, _this.errorMsg);
                    errorMessages
                        ? $errorDialog.trigger('show-info-messages', [errorMessages, true])
                        : _this.text = _this.$inputField.val();
                    _this.draw();
                })
                .fail(function(xhr) {
                    console.error(xhr.status, _this.errorMsg);
                    $errorDialog.trigger('show-info-messages', [[_this.errorMsg], true]);
                    _this.draw();
                });
        };
        LineItemNote.prototype.initHandlers = function() {
            var _this = this;
            this.$inputField.on('keyup', function(e) {
                if(e.keyCode === 13) {
                    _this.validToPost(_this.$inputField.val())
                        ? _this.post(_this.$inputField.val(), _this.entryId)
                        : _this.draw();
                }
                if(e.key === 'Escape') {
                    _this.draw();
                }
            });
            this.$inputField.on('blur', function() {
                _this.validToPost(_this.$inputField.val())
                    ? _this.post()
                    : _this.draw();
            });
            this.$parentTab.on('tab-opened', function() {
                _this.$inputField.focus();
            });
        };

        var lineItemNotes = [];
        $lineItemNotes.each(function() {
            var lineItemNote = new LineItemNote($(this));
            lineItemNote.initHandlers();
            lineItemNote.draw();
            lineItemNotes.push(lineItemNote);
        });
    }

})();
