Belimo.Search.ROOT_VIEW = 'root';

Belimo.Search.addView(Belimo.Search.ROOT_VIEW, function(element) {
	var searchView = this;

	searchView.element = element;

	var views = Belimo.Search.getViews();

	searchView[Belimo.Search.SEARCH_VIEW] = new views[Belimo.Search.SEARCH_VIEW](searchView);

	for(var i in views) {
		if(views.hasOwnProperty(i) && i !== Belimo.Search.ROOT_VIEW && i !== Belimo.Search.SEARCH_VIEW) {
			searchView[i] = new views[i](searchView);
		}
	}
});

$(function(){
	$('.search-widget').each(function() {
		var search = $(this);
			var view = Belimo.Search.getView(Belimo.Search.ROOT_VIEW);

		ko.applyBindings(new view(search), this);
	});
});

$(function(){
	$('.search-autosuggest').each(function() {
		var search = $(this);
			var view = Belimo.Search.getView(Belimo.Search.ROOT_VIEW);

		ko.applyBindings(new view(search), this);
	});
});

$('.panel-search').click(function () {
    $('.below-overlay .panel-cart .panel-trigger span').html($('.scrollfix .panel-cart .panel-trigger span').html());
    $('.panel-burger').removeClass('toggle-on');
	$('.panel-cart').removeClass('toggle-on');
	$('.panel-burger .collapsable').removeClass('toggle-on');

	if ($('body').hasClass('with-burger-overlay') == false) {
		$('body.with-overlay .overlay').one('click', function(){
			$('.panel-burger').removeClass('toggle-on');
			$('.panel-cart').removeClass('toggle-on');
			$('.panel-download').removeClass('toggle-on');
			$('.panel-search').removeClass('toggle-on');
			$('body').removeClass('with-burger-overlay with-meta');
		});
	}
});


$('.panel-cart').click(function () {
    $('.panel-burger').removeClass('toggle-on');
	$('.panel-search').removeClass('toggle-on');
	$('.panel-burger .collapsable').removeClass('toggle-on');

	if ($('body').hasClass('with-burger-overlay') == false) {
		$('body.with-overlay .overlay').one('click', function() {
			$('.panel-burger').removeClass('toggle-on');
			$('.panel-cart').removeClass('toggle-on');
			$('.panel-download').removeClass('toggle-on');
			$('.panel-search').removeClass('toggle-on');
			$('body').removeClass('with-burger-overlay with-meta');
		});
	}
});

$('.panel-download').click(function () {

	if($('.download-empty').length > 0) {
        document.location.href = $('.download-actions-import a').attr('href');
        $('.panel-download').find('.panel-content').remove();
    	$('body').removeClass('with-overlay');
    }

    $('.panel-burger').removeClass('toggle-on');
	$('.panel-search').removeClass('toggle-on');
	$('.panel-burger .collapsable').removeClass('toggle-on');

	if ($('body').hasClass('with-burger-overlay') == false) {
		$('body.with-overlay .overlay').one('click', function(){
			$('.panel-burger').removeClass('toggle-on');
			$('.panel-cart').removeClass('toggle-on');
			$('.panel-download').removeClass('toggle-on');
			$('.panel-search').removeClass('toggle-on');
			$('body').removeClass('with-burger-overlay with-meta');
		});
	}
});
