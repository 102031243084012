'use strict';
function initCountrySelector() {
    $('#countrySelector select').each(function () {

        var stateSelectorString = '#stateRegionSelector select';
        var regions = [];

        $(this).change(function (event) {
            var $target = $(event.target);
            var value = $target.val(); // countryIso
            var stateSelect = getStateSelect();

            var regionBaseURL = ACC.config.encodedContextPath+'/common/regions-for-countries/';
            $.getJSON(regionBaseURL, function( data ) {
                if (data[value] != undefined) {
                    updateRegionsStates(value);
                    $('#stateRegionSelector').show();
                    $(stateSelect).prop('disabled', false);
                } else {
                    $('#stateRegionSelector').hide();
                    $(stateSelect).prop('disabled', true);
                    $(stateSelect).select2().select('');
                }
            });

            // ignore-suggestions-country button display
            const ignoreSuggestionsUrl = ACC.config.encodedContextPath + '/common/ignore-suggestions-country-ajax';
            $.ajax({
                url: ignoreSuggestionsUrl,
                method: 'GET',
                data: {
                    countryIso: value
                }
            }).done(function(ignoreSuggestions) {
                if ( "enabled" === ignoreSuggestions ) {
                    // show ignore button
                    $('.address-edit-ignore').show();
                } else {
                    $('.address-edit-ignore').hide();
                }
            }).fail(function(xhr) {
                console.error(xhr.responseText);
            });

        });

        var getStateSelect = function () {
            return $(stateSelectorString);
        };


        var setStateRegions = function (d) {
            var stateRegion = getStateSelect();
            $(stateRegion).find('option').not(':first').remove();
            $(stateRegion).select2({data: d});
            setTimeout(function () {
                $(stateRegion).select2().select2({data: d});
            }, 1500);
        };

        var updateRegionsStates = function (isoCode) {
            var regionBaseURL = ACC.config.encodedContextPath+'/common/regions-for-country/';
            $.get(regionBaseURL, 'isocode=' + isoCode, successfunction);
        };

        var successfunction = function (data) {
            prepareAsSelect2Array(data);
            setStateRegions(regions);
        };

        var prepareAsSelect2Array = function (data) {
            regions = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i]['isocode'] && data[i]['name']) {
                    var isoCode = data[i]['isocode'];
                    var name = data[i]['name'];
                    regions.push({id: isoCode, text: name});
                }
            }
        };
    });
}
initCountrySelector();
export { initCountrySelector };




