import { initCountrySelector } from 'script/belimo.hybris.address.countrystate';
import { selectInit } from 'script/belimo.hybris.selectInit';

(function() {

  const $addressAddEditPage = $('.address-add-edit');
  const $popupWrapper = $addressAddEditPage.find('#popup-id-address-edit-no-verification');

  if($addressAddEditPage.length > 0 && $popupWrapper.length > 0) {
    init();
  }

  function init() {

    const $popupWrapper = $addressAddEditPage.find('#popup-id-address-edit-no-verification');
    const $addressEditOpenCtrls = $addressAddEditPage.find('.address-edit-open');
    const $addressEditWrapper = $popupWrapper.find('.address-edit-wrapper');

    $popupWrapper.get(0).addEventListener('click', function(e) {
      e.preventDefault();
      if(e.target.classList.contains('address-edit-cancel')) {
        ui.closePopup();
      }
      else if(e.target.classList.contains('address-edit-submit')) {
        ui.setContent(true);
        return false;
      }
    });

    const state = {
      addressId: null,
      requestUrl: null,
      nextStepRequestUrl: null,
      init: function(addressId, requestUrl, nextStepRequestUrl) {
        this.addressId = addressId;
        this.requestUrl = requestUrl;
        this.nextStepRequestUrl = nextStepRequestUrl;
      }
    };

    const ui = {
      elems: {
        $submitButton: null,
        $cancelButton: null
      },
      closePopup: function() {
        $popupWrapper.trigger('popup-close');
      },
      setContent: function(setByPostMethod) {
        $.ajax({
          url: state.requestUrl,
          dataType: 'html',
          method: setByPostMethod ? 'POST' : 'GET',
          data: setByPostMethod ? $popupWrapper.find('form').serialize() : undefined
        }).done(function(html) {

          if(html.includes('edit-address-ajax')) {
            $addressEditWrapper.html(html);
            const $stateSelect = $popupWrapper.find('#address\\.country');
            if($stateSelect.length > 0) $stateSelect.get(0).style.setProperty('height', '2.5em');

            const $regionSelect = $popupWrapper.find('#address\\.region');
            if($regionSelect.length > 0) $regionSelect.get(0).style.setProperty('height', '2.5em');

            let selectedCountry = undefined;
            const $countrySelector = $popupWrapper.find('#countrySelector');
            if($countrySelector.length > 0) {
              selectedCountry = $countrySelector.get(0).getAttribute('data-country-iso-code');
            }

            initCountrySelector();
            selectInit(selectedCountry);

            $popupWrapper.trigger('popup-open', []);
          }
          else{
            $popupWrapper.trigger('popup-close');
            window.location.href = state.nextStepRequestUrl;
          }
        }).fail(function(xhr) {
            console.error(xhr.responseText);
        });
      }
    };

    $addressEditOpenCtrls.each(function() {
      // eslint-disable-next-line no-invalid-this
      const $addressEditOpenCtrl = $(this);
      $addressEditOpenCtrl.on('click', function () {
        state.init(
          $addressEditOpenCtrl.get(0).getAttribute('data-address-id'),
          $addressEditOpenCtrl.get(0).getAttribute('data-request-url'),
          $addressEditOpenCtrl.get(0).getAttribute('data-next-step-request-url'));
        ui.setContent(false);
      });
    });
  }
})();
