var modal = $('.modal');

/**
 * Update Modal Window Content
 *
 */
function updateContent(content, update) {
	if(content || content === '') {
		modal.find('.modal-content').html(content);
	} else {
		if(update !== true) {
			modal.find('.modal-content').empty();
		}
	}
}

/**
 * Update Modal Window Buttons
 *
 */
function updateButtons(buttons, update) {
	var actions = modal.find('.modal-actions');

	if(buttons && buttons.length > 0) {
		actions.empty();

		for(var b = 0; b < buttons.length; b++) {
			createButton(buttons[b], actions);
		}
	} else {
		if(update !== true || (buttons && buttons.length == 0)){
			actions.empty();

			createButton({
				label: 'OK',
				type: 'button',
				callback: Belimo.Modal.close
			}, actions);
		}
	}
}

/**
 * Create Modal Window Button
 *
 */
function createButton(config, actions) {
	var button;

	if(config.html) {
		button = $(config.html);
	} else {
		button = $('<button/>');
		button.attr('type', config.type);
		button.html(config.label);
		if(config.classes) button.addClass(config.classes);
	}

	// config.inputfield needs to be a jquery object!
	if(config.inputField && config.inputField instanceof jQuery) {

		button.prop('disabled', config.inputField.val() == false);
		config.inputField.keyup(function() {
			button.prop('disabled', config.inputField.val() == false);
		});
	}

	button.on('click', function () {
		if(config.callback) config.callback();
	});

	actions.append(button);
}


/**
 * Handle global open/update/close events
 *
 */
$(document).on('modal-on', function(ev, content, buttons) {
	$(document).trigger('overlay-on', ['', true]);

	updateContent(content);
	updateButtons(buttons);

	modal.addClass('toggle-on');
}).on('modal-off', function(ev) {
	$(document).trigger('overlay-off');
	modal.removeClass('toggle-on');

	updateContent();
	updateButtons();

}).on('modal-update', function(ev, content, buttons) {
	updateContent(content, true);
	updateButtons(buttons, true);

});
